import React from "react";
import styled from "styled-components/macro";
import { cmToFeet } from "../../../util/cmToFeet";
import { InfoStyles } from "../RosterPage/InfoStyles";
import { lbToKg } from "../../../util/lbToKg";
import { SkillLevel } from "../../../api/roster";

interface OwnProps {
  id: number;
  height: number;
  weight: number;
  age: number;
  seasonDrafted: number;
  offensiveStars?: number;
  defensiveStars?: number;
  intangibleStars?: number;
  totalStars?: number;
  // Extra Stats for Skills Calc
  insideShooting?: SkillLevel;
  midRangeShooting?: SkillLevel;
  longRangeShooting?: SkillLevel;
  finishing?: SkillLevel;
  driving?: SkillLevel;
  handling?: SkillLevel;
  passing?: SkillLevel;
  stealing?: SkillLevel;
  blocking?: SkillLevel;
  outsideDefense?: SkillLevel;
  insideDefense?: SkillLevel;
  rebounding?: SkillLevel;
  mobility?: SkillLevel;
  offensiveIntellect?: SkillLevel;
  defensiveIntellect?: SkillLevel;
  physique?: SkillLevel;
  units: number;
}

const BasicInfo = (props: OwnProps) => {
  const offesniveSkills =
    (props.insideShooting || 0) +
    (props.longRangeShooting || 0) +
    (props.finishing || 0) +
    (props.passing || 0) +
    (props.driving || 0) +
    (props.handling || 0) +
    (props.midRangeShooting || 0);

  const defesiveSkills =
    (props.blocking || 0) +
    (props.insideDefense || 0) +
    (props.outsideDefense || 0) +
    (props.stealing || 0);

  const intangibleSkills =
    (props.rebounding || 0) +
    (props.mobility || 0) +
    (props.offensiveIntellect || 0) +
    (props.defensiveIntellect || 0) +
    (props.physique || 0);

  const totalSkills = intangibleSkills + offesniveSkills + defesiveSkills;

  return (
    <Container>
      <div className="player-stats-info">
        <p className="label">Age</p>
        <p className="player-stats">{props.age}</p>
      </div>
      <div className="player-stats-info">
        <p className="label">Drafted</p>
        <p className="player-stats">Season {props.seasonDrafted}</p>
      </div>
      <div className="player-stats-info">
        <p className="label">Height</p>
        <p className="player-stats">
          {props.units === 2 ? `${cmToFeet(props.height)}` : `${props.height} cm`}
        </p>
      </div>
      <div className="player-stats-info">
        <p className="label">Weight</p>
        <p className="player-stats">
          {props.units === 2 ? `${props.weight} LB` : `${lbToKg(props.weight).toFixed(1)} Kg`}
        </p>
      </div>
      <div className="player-stats-info">
        <p className="label">Offensive stars</p>
        <p className="player-stats">
          {props.offensiveStars ? `${props.offensiveStars} (${offesniveSkills})` : "-"}
        </p>
      </div>
      <div className="player-stats-info">
        <p className="label">Deffensive stars</p>
        <p className="player-stats">
          {props.defensiveStars ? `${props.defensiveStars} (${defesiveSkills})` : "-"}
        </p>
      </div>
      <div className="player-stats-info">
        <p className="label">Intangible stars</p>
        <p className="player-stats">
          {props.intangibleStars ? `${props.intangibleStars} (${intangibleSkills})` : "-"}
        </p>
      </div>
      <div className="player-stats-info">
        <p className="label">Total stars</p>
        <p className="player-stats">
          {props.totalStars ? `${props.totalStars} (${totalSkills})` : "-"}
        </p>
      </div>
    </Container>
  );
};

export default BasicInfo;

const Container = styled.div`
  ${InfoStyles}
`;

import React from "react";
import page from "./page";
import styled from "styled-components";
// import { useResponsive } from "../../util/useResponsive";

const CheckoutFailed = () => {
  //   const { isMobile } = useResponsive();
  return (
    <Container>
      <h2>There was an issue processing your payment!</h2>
      <h2>
        If you believe this was in error, reach out to Apex#1998 on Discord with any questions or
        problems.
      </h2>
      <h2>Thank you for playing and supporting CourtHoops!</h2>
    </Container>
  );
};

export default page("checkout-failed")(CheckoutFailed);

const Container = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: ${(props) => props.theme.darkGrayBackground};
  color: ${(props) => props.theme.grayFont};
  font-family: LemonMilklight;
  h2,
  h3 {
    padding: 20px;
  }
`;

import React from "react";
import { ListItemText } from "@material-ui/core";
import styled from "styled-components";
import { theme } from "../..";

export const ListItemTextMessenger: React.FC<
  React.ComponentProps<typeof ListItemText> & { selected?: boolean }
> = ({ children, ...other }) => {
  return (
    <StyledListItemTextMessenger
      classes={{ primary: "primary", secondary: "secondary" }}
      {...other}
    >
      {children}
    </StyledListItemTextMessenger>
  );
};

const StyledListItemTextMessenger = styled(ListItemText)<{ selected?: boolean }>`
  & .primary {
    font-size: 12px;
    font-family: "LemonMilk";
    letter-spacing: 1.2px;
    text-align: center;
    color: ${(props) => (props.selected ? `${theme.blackFont}` : `${theme.whiteFont}`)};
  }

  & .secondary {
    font-size: 10px;
    font-family: "LemonMilkLight";
    letter-spacing: 1.2px;
    text-align: center;
    color: ${(props) => `${theme.grayFont}`};
  }
`;

export default ListItemTextMessenger;

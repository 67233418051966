// useMatchMedia Hook
import { useEffect, useState } from "react";
// It is a helper function to "useResponsive"

export const useMatchMedia = (mediaQuery: string) => {
  // Initialize state (true or false)
  const [matches, setMatches] = useState(window.matchMedia(mediaQuery).matches);

  // Do this work anytime component renders
  useEffect(() => {
    // Do this when breakpoint status changed
    const updateMatches = (event: MediaQueryListEvent) => {
      // Update matches variable to new status
      setMatches(event.matches);
    };

    const mediaQueryList = window.matchMedia(mediaQuery);

    // Initialize matches value on every render
    setMatches(mediaQueryList.matches);

    // addListener will call updateMatches only when media query status changed
    mediaQueryList.addListener(updateMatches);

    // When component is removed from DOM, you want to also remove the listener
    // "Tear-down"
    return () => mediaQueryList.removeListener(updateMatches);
  }, [mediaQuery]);

  return matches;
};

import React from "react";
import styled from "styled-components/macro";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import { theme } from "../../..";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: "flex",
      minHeight: "100vh",
      width: "100%",
      "&:hover .MuiCircularProgress-colorPrimary": {
        color: `${theme.lightGrayBackground}`,
      },
    },
  })
);

const Loading = () => {
  const classes = useStyles();

  return (
    <Container className={classes.root}>
      <CircularProgress />
    </Container>
  );
};

export default Loading;

const Container = styled.div`
  display: flex;
  background-color: ${(props) => props.theme.mediumGrayBackground};
  flex: 1;
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
`;

import { api } from "../axios.config";

export const submitLineup = (lineup: Lineup, id: number) => {
  return api.request<void>({
    method: "post",
    headers: { "Content-Type": "application/json" },
    url: `/Game/${id}/tactics`,
    data: lineup,
  });
};

export type DefensiveRole = string;
export type IntangibleRole = string;
export type OffensiveRole = string;
export type Offense = string[];
export type Defense = string[];

interface PlayerInfo {
  id: number;
  offensiveRole: OffensiveRole;
  defensiveRole: DefensiveRole;
  intangibleRole: IntangibleRole;
}

export interface Lineup {
  offense: string;
  defense: string;
  PG: PlayerInfo;
  SG: PlayerInfo;
  SF: PlayerInfo;
  PF: PlayerInfo;
  C: PlayerInfo;
  bPG: PlayerInfo;
  bSG: PlayerInfo;
  bSF: PlayerInfo;
  bPF: PlayerInfo;
  bC: PlayerInfo;
}

import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";

export const baseStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "120px",
      padding: 0,
    },
    nested: {
      paddingLeft: theme.spacing(4),
    },
  })
);

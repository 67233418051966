import React from "react";

interface FailProps {
  onClose: () => void;
  button: {};
}

export const Fail = (props: FailProps) => {
  return (
    <>
      <h2 id="spring-modal-title">Oops, something went wrong</h2>
      <p id="spring-modal-description">
        Check that you have enough CourtHoops Coins, or try again later
      </p>

      <button type="button" onClick={props.onClose} style={props.button}>
        Close
      </button>
    </>
  );
};

export const BidFail = (props: FailProps) => {
  return (
    <>
      <h2 id="spring-modal-title">Oops, something went wrong</h2>
      <p id="spring-modal-description">not enough balance/bid not high enough</p>
      <p id="spring-modal-description">or </p>
      <p id="spring-modal-description">winning auciton puts roster size over 15</p>

      <button type="button" onClick={props.onClose} style={props.button}>
        Close
      </button>
    </>
  );
};

export const ListFail = (props: FailProps) => {
  return (
    <>
      <h2 id="spring-modal-title">Oops, something went wrong</h2>
      <p id="spring-modal-description">Must have at least 10 unlisted players on your roster.</p>

      <button type="button" onClick={props.onClose} style={props.button}>
        Close
      </button>
    </>
  );
};

export const FireFail = (props: FailProps) => {
  return (
    <>
      <h2 id="spring-modal-title">Oops, something went wrong</h2>
      <p id="spring-modal-description">Must have at least 10 unlisted players on your roster.</p>

      <button type="button" onClick={props.onClose} style={props.button}>
        Close
      </button>
    </>
  );
};

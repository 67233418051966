import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SettingsPost } from "../../api/settings";
import { MyKnownError } from "../myKnownError";
import { defaultState } from "../util/defaultState";

const setSettingsSlice = createSlice({
  name: "setSettings",
  initialState: defaultState<SettingsPost[]>(),
  reducers: {
    setSettingsLoading: (state) => {
      state.fetchStatus = "pending";
      return state;
    },
    setSettingsSuccess: (state) => {
      state.fetchStatus = "success";
      return state;
    },
    setSettingsFailed: (state, action: PayloadAction<MyKnownError>) => {
      state.fetchStatus = "failed";
      state.error = action.payload;
      return state;
    },
    setSettingsReset: (state) => {
      state = defaultState();
      return state;
    },
  },
});

export const {
  setSettingsLoading,
  setSettingsSuccess,
  setSettingsFailed,
  setSettingsReset,
} = setSettingsSlice.actions;
export default setSettingsSlice.reducer;

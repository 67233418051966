import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { navBarHeight } from "../lib/styles/navBarHeight";
import ToolsButton from "./buttons/ToolsButton";
import UserButton from "./buttons/UserButton";
import TeamButton from "./buttons/TeamButton";
import CHLogoOne from "../lib/images/CHLogoOne.png";
import { LogInResponse } from "../../api/logIn";
import { connect } from "react-redux";
import { RootState } from "../../redux/store";
import Bar from "../lib/energyPage/Bar";
import { theme } from "../../";
import smallLogo from "../lib/images/smallLogo.png";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import { Link } from "react-router-dom";
import PremiumButton from "./buttons/PremiumButton";
import NavigateButton from "./buttons/NavigateButton";

interface StateProps {
  userInfo?: LogInResponse;
}

const iconStyle = {
  color: "#FFDC11",
  width: "15px",
};

const chIconStyle = {
  width: "13px",
};

const container = {
  display: "flex",
  flexDirection: "row" as "row",
  alignItems: "center" as "center",
  justifyContent: "center" as "center",
  maxHeight: "17px",
};

const containerTwo = {
  display: "flex",
  flexDirection: "row" as "row",
  alignItems: "center" as "center",
  justifyContent: "center" as "center",
  maxHeight: "17px",
  marginLeft: "12px",
};

const textSize = {
  fontSize: "12px",
  //   color: "#9a9da2",
  fontFamily: "LemonMilk" as "LemonMilk",
};

const premiumText = {
  fontSize: "12px",
  color: "#FFDC11",
  fontFamily: "LemonMilk",
};

const containerOne = {
  display: "flex",
  flexDirection: "column" as "column",
  alignItems: "center" as "center",
  justifyContent: "center" as "center",
  overflow: "hidden",
};

function shorthandCoins(amount: number): string {
  // Get the amount as a string and get the length.
  const amountString = `${amount}`;
  const len = amountString.length;

  if (len === 4) {
    // If between 1000 and 10000.
    const afterDecimal = amountString.slice(1, 3).replace(/0+$/, "");
    const suffix = afterDecimal ? `.${afterDecimal}` : "";
    return `${amountString[0]}${suffix}K`;
  } else if (len === 5) {
    // If between 10000 and 100000.
    const suffix = amountString[2] === "0" ? "" : `.${amountString[2]}`;
    return `${amountString.slice(0, 2)}${suffix}K`;
  } else if (len === 6) {
    // If between 100000 and 1000000.
    const suffix = amountString[3] === "0" ? "" : `.${amountString[3]}`;
    return `${amountString.slice(0, 3)}${suffix}K`;
  } else if (len === 7) {
    // If between 1000000 and 10000000.
    const afterDecimal = amountString.slice(1, 3).replace(/0+$/, "");
    const suffix = afterDecimal ? `.${afterDecimal}` : "";
    return `${amountString[0]}${suffix}M`;
  } else if (len === 8) {
    // If between 10000000 and 100000000.
    const suffix = amountString[2] === "0" ? "" : `.${amountString[2]}`;
    return `${amountString.slice(0, 2)}${suffix}M`;
  } else {
    return amountString;
  }
}

function msToTime(duration: number): string {
  let seconds = Math.floor((duration / 1000) % 60).toString(),
    minutes = Math.floor((duration / (1000 * 60)) % 60).toString(),
    hours = Math.floor(duration / (1000 * 60 * 60)).toString();

  hours = parseInt(hours) < 10 ? "0" + hours : hours;
  minutes = parseInt(minutes) < 10 ? "0" + minutes : minutes;
  seconds = parseInt(seconds) < 10 ? "0" + seconds : seconds;

  return hours !== "00"
    ? hours + ":" + minutes + ":" + seconds
    : minutes !== "00" || seconds !== "00"
    ? minutes + ":" + seconds
    : "LIVE";
}

const DesktopNavBar = (props: StateProps) => {
  const [gameTime, setGameTime] = useState(5);

  useEffect(() => {
    setTimeout(() => {
      setGameTime(
        props.userInfo
          ? props.userInfo.nextGameTime
            ? props.userInfo.nextGameTime - Date.now()
            : 100000
          : 0
      );
    }, 1000);
  }, [gameTime, props.userInfo]);

  if (!props.userInfo) {
    return <></>;
  }

  return (
    <Container>
      <LogoContainer>
        <img style={{ margin: "0 30px", height: "110px" }} src={CHLogoOne} alt="logo"></img>
        <div style={containerOne}>
          <div style={container}>
            <div style={container}>
              <AttachMoneyIcon style={iconStyle}></AttachMoneyIcon>
              <p style={textSize}>{shorthandCoins(props.userInfo.balance)}&nbsp;</p>
            </div>
            <div style={containerTwo}>
              <p style={premiumText}>{shorthandCoins(props.userInfo.premiumCurrency)}&nbsp;</p>
              <img alt="" src={smallLogo} style={chIconStyle}></img>
            </div>
          </div>
          <a style={container} href={`/energy`}>
            <Bar
              height={"12px"}
              curr={props.userInfo.energy}
              max={props.userInfo.premiumStatus === 1 ? 1500 : 500}
              isOnNav={true}
              color={theme.orangeTheme}
              isOnDropdown={false}
              name={"Energy"}
            />
          </a>
        </div>
      </LogoContainer>
      <div className="mybox">
        <div></div>
        <div></div>
      </div>
      <TeamButton userId={props.userInfo.teamId}></TeamButton>
      <ToolsButton
        userId={props.userInfo.teamId}
        nextGame={props.userInfo.nextGameId}
      ></ToolsButton>
      <NavigateButton></NavigateButton>
      <PremiumButton />
      {props.userInfo.nextGameTime ? (
        <div className="next-game">
          {gameTime <= 0 ? (
            <Link
              to={`/game/${props.userInfo.nextGameId}/play-by-play`}
              style={{ textDecoration: "none" }}
            >
              <div className="live">Game Live! Watch Now!</div>
            </Link>
          ) : props.userInfo.setLineup ? (
            <div>
              <div className="timer">Next Game In: {msToTime(gameTime)}</div>
              <Link to={`/lineup/${props.userInfo.nextGameId}`} style={{ textDecoration: "none" }}>
                <div className="edit-lineup">Edit Your Lineup</div>
              </Link>
            </div>
          ) : (
            <div>
              <div className="timer">Next Game In: {msToTime(gameTime)}</div>
              <Link to={`/lineup/${props.userInfo.nextGameId}`} style={{ textDecoration: "none" }}>
                <div className="set-lineup">Set Your Lineup!</div>
              </Link>
            </div>
          )}
        </div>
      ) : (
        <div className="next-game">
          <div className="timer">Next Game Not Scheduled</div>
        </div>
      )}
      <UserButton
        id={props.userInfo.teamId}
        avatar={props.userInfo.avatar}
        mobile={false}
      ></UserButton>
    </Container>
  );
};

export default connect<StateProps, {}, {}, RootState>((state) => ({
  userInfo: state.logIn.data,
}))(DesktopNavBar);

const Container = styled.div`
  display: flex;
  align-items: center;
  min-height: ${navBarHeight};
  background: ${(props) => props.theme.lightGrayBackground};
  color: ${(props) => props.theme.whiteFont};
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 5;

  .mybox {
    background: linear-gradient(
      -130deg,
      ${(props) => props.theme.lightGrayBackground} 49.75%,
      ${(props) => props.theme.yellowFont} 48%,
      ${(props) => props.theme.yellowFont} calc(50% + 3px),
      ${(props) => props.theme.darkGrayBackground} calc(48% + 4px)
    );
    display: flex;
    min-width: ${navBarHeight};
    height: ${navBarHeight};
    justify-self: flex-end;

    div {
      padding: 1em 2%;
      color: white;
      flex: 1;
    }
  }

  .next-game {
    margin-left: auto;
    width: 200px;
    justify-content: center;
    text-align: center;
  }

  .timer {
    color: ${(props) => props.theme.grayFont};
    font-size: 13px;
  }

  .live {
    color: ${(props) => props.theme.yellowFont};
    font-family: LemonMilkLight;
    font-size: 14px;
  }

  .edit-lineup {
    color: ${(props) => props.theme.greenTheme};
    font-size: 13px;
  }

  .set-lineup {
    color: ${(props) => props.theme.redTheme};
    font-size: 13px;
  }

  .energy {
    height: ${navBarHeight};
    display: flex;
    justify-content: center;
    align-items: center;
    width: 150px;
    font-family: LemonMilk;
    font-size: 12px;
    line-height: 1.25;
    color: ${(props) => props.theme.grayFont};
    text-decoration: none;
  }

  #user {
    // margin-left: 250px;
    width: 300px;
  }

  #energy {
    margin-left: 30px;
  }
`;

const LogoContainer = styled.div`
  height: ${navBarHeight};
  background-color: ${(props) => props.theme.darkGrayBackground};
  display: flex;
  justify-content: center;
  align-items: center;
`;

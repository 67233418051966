// Function used in the Gauntlet for the losers bracket
// It is a helper funtion to modify the data
// Given a loser's bracket, it will re-order the games
// The modified data will be given to the "toTree" function

export function orderGames<T>(originalArr: T[], i: number = 0, finalArr: T[] = []): T[] {
  if (originalArr.length === 0) {
    return finalArr;
  }

  const itemsToGrab = Math.pow(2, Math.floor(i / 2));
  const partToKeep = originalArr.slice(0, originalArr.length - itemsToGrab);
  const partToTake = originalArr.slice(originalArr.length - itemsToGrab, originalArr.length);
  return orderGames(partToKeep, i + 1, [...finalArr, ...partToTake]);
}

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { Team, fetchTeam } from "../../api/team";
import { FetchStatus } from "../fetchStatus";
import { AsyncThunkError } from "../asyncThunkError";
import { MyKnownError } from "../myKnownError";

interface State {
  fetchStatus: FetchStatus;
  response: Team | null;
  error: MyKnownError | null;
}

export const fetchTeamAction = createAsyncThunk<
  Team,
  { id: number },
  AsyncThunkError<MyKnownError>
>("Team", async (props, thunkApi) => {
  try {
    const response = await fetchTeam(props.id);
    return response.data;
  } catch (err) {
    if (err?.response?.status === 400) {
      return thunkApi.rejectWithValue("400");
    } else {
      throw err;
    }
  }
});

export default createSlice({
  name: "team",
  initialState: {
    fetchStatus: "notStarted",
    response: null,
    error: null,
  } as State,
  reducers: {
    reset: (state) => {
      state.fetchStatus = "notStarted";
      state.response = null;
      state.error = null;

      return state;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTeamAction.fulfilled, (state, action) => {
        state.fetchStatus = "success";
        state.response = action.payload;
        state.error = null;
        return state;
      })
      // make update while waiting
      .addCase(fetchTeamAction.pending, (state) => {
        state.fetchStatus = "pending";
        return state;
      })
      // make update when failed
      .addCase(fetchTeamAction.rejected, (state, action) => {
        if (action.payload) {
          // Since we passed in `MyKnownError` to `rejectType` in `updateUser`, the type information will be available here.
          state.fetchStatus = "failed";
          state.error = action.payload;
        } else {
          state.fetchStatus = "failed";
          state.error = null;
        }
        return state;
      });
  },
});

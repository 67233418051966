import React from "react";
import styled from "styled-components/macro";
import { SkillLevel } from "../../../api/roster";

interface OwnProps {
  skill: SkillLevel;
}

const SkillStars = (props: OwnProps) => {
  return (
    <Container s={props.skill}>
      <div className="white-background"></div>
      <div className="color-background"></div>
      <img src={"/images/starOne Small 2.png"} alt="star1" />
      <img src={"/images/starTwo Small.png"} alt="star2" />
      <img src={"/images/starThree Small.png"} alt="star3" />
      <img src={"/images/starFour Small.png"} alt="star4" />
      <img src={"/images/starFive Small.png"} alt="star5" />
    </Container>
  );
};

export default SkillStars;

const Container = styled.div<{ s: number }>`
  position: relative;
  max-height: 16px;
  width: 85px;
  background-color: ${(props) => props.theme.lightGrayBackground};
  margin-bottom: 2px !important;

  .white-background {
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    background-color: ${(props) => props.theme.lightGrayBackground};
    z-index: 2;

    width: ${(props) =>
      props.s < 10
        ? "100%"
        : props.s >= 10 && props.s < 20
        ? "80%"
        : props.s >= 20 && props.s < 30
        ? "60%"
        : props.s >= 30 && props.s < 40
        ? "40%"
        : props.s >= 40 && props.s < 50
        ? "20%"
        : "0"};
  }

  .color-background {
    height: 100%;
    width: 100%;
    position: absolute;
    margin-right: 10px;
    background: linear-gradient(
      90deg,
      rgba(236, 0, 0, 1) 0%,
      rgba(251, 255, 0, 1) 50%,
      rgba(0, 212, 255, 1) 100%
    );
    z-index: 1;
  }

  img {
    min-height: 16px;
    position: relative;
    width: 17px;
    z-index: 4;
  }
`;

// import React from "react";
// import styled from "styled-components/macro";
// import { SkillLevel } from "../../../api/roster";
// import oneFull from "../../lib/images/stars/11.png";
// import oneEmpty from "../../lib/images/stars/12.png";
// import twoFull from "../../lib/images/stars/21.png";
// import twoEmpty from "../../lib/images/stars/22.png";
// import threeFull from "../../lib/images/stars/31.png";
// import threeEmpty from "../../lib/images/stars/32.png";
// import fourFull from "../../lib/images/stars/41.png";
// import fourEmpty from "../../lib/images/stars/42.png";
// import fiveFull from "../../lib/images/stars/51.png";
// import fiveEmpty from "../../lib/images/stars/52.png";

// interface OwnProps {
//   skill: SkillLevel;
// }

// const SkillStars = (props: OwnProps) => {
//   return (
//     <Container>
//       <Img src={props.skill >= 10 ? oneFull : oneEmpty} alt="star" />
//       <Img src={props.skill >= 20 ? twoFull : twoEmpty} alt="star" />
//       <Img src={props.skill >= 30 ? threeFull : threeEmpty} alt="star" />
//       <Img src={props.skill >= 40 ? fourFull : fourEmpty} alt="star" />
//       <Img src={props.skill === 50 ? fiveFull : fiveEmpty} alt="star" />
//     </Container>
//   );
// };

// export default SkillStars;

// const Container = styled.div`
//   display: flex;
//   flex-direction: row;
//   height: 17px;
//   width: 85px;
// `;

// const Img = styled.img`
//   height: 17px;
//   width: 17px;
// `;

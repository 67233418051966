import React, { useEffect, useState } from "react";
import List from "@material-ui/core/List";
import { NavLink } from "react-router-dom";
import CustomListItemText from "../CustomListItemText";
import CustomListItem from "../CustomListItem";
import { baseStyles } from "../baseStyles";
import { Page } from "../../../components/pages/page";
import { connect } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/store";
import page from "../../../redux/reducer/page";
import { ClickAwayListener, Collapse } from "@material-ui/core";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import { theme } from "../../..";

interface OwnProps {
  leagueName?: number;
  userId?: number;
}

interface StateProps {
  page: Page;
}

interface DispatchProps {
  changePage: (p: Page) => void;
}

const NavigateButton = (props: StateProps & DispatchProps & OwnProps) => {
  const classes = baseStyles();
  const [openTools, setOpenTools] = useState(false);
  const { page } = props;

  const handleClickTools = (v: boolean) => {
    setOpenTools(v);
  };

  useEffect(() => {}, [page]);

  const handleClick = () => {
    handleClickTools(false);
  };

  const handleClickAway = () => {
    handleClickTools(false);
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <List component="div" className={classes.root}>
        <CustomListItem
          button
          selected={
            props.page === "gauntlet" ||
            props.page === "discord" ||
            props.page === "leagues" ||
            props.page === "manual" ||
            props.page === "leaderboards" ||
            props.page === "transfers"
          }
          onClick={() => handleClickTools(openTools ? false : true)}
        >
          <CustomListItemText
            primary="Pages"
            selected={
              props.page === "gauntlet" ||
              props.page === "discord" ||
              props.page === "leagues" ||
              props.page === "manual" ||
              props.page === "leaderboards" ||
              props.page === "transfers"
            }
          />
          {openTools ? <ExpandLess /> : <ExpandMore />}
        </CustomListItem>
        <Collapse
          in={openTools}
          timeout="auto"
          unmountOnExit
          style={{
            borderBottom: `6px solid ${theme.orangeTheme}`,
            width: "120px",
            position: "absolute",
          }}
        >
          <List disablePadding>
            <CustomListItem
              // @ts-ignore
              component={NavLink}
              selected={props.page === "discord"}
              exact
              to={{ pathname: "https://discord.com/invite/WX92bvR" }}
              target="_blank"
              onClick={handleClick}
              button
              iscollapsed={true}
              style={{ height: "30px" }}
            >
              <CustomListItemText selected={props.page === "discord"} primary="Discord" />
            </CustomListItem>
            <CustomListItem
              // @ts-ignore
              component={NavLink}
              selected={props.page === "gauntlet"}
              exact
              to={`/gauntlet/16`}
              onClick={handleClick}
              button
              iscollapsed={true}
              style={{ height: "30px" }}
            >
              <CustomListItemText selected={props.page === "gauntlet"} primary="Gauntlet" />
            </CustomListItem>

            <CustomListItem
              // @ts-ignore https://github.com/mui-org/material-ui/issues/17645
              component={NavLink}
              selected={props.page === "leagues"}
              exact
              to={`/leagues`}
              onClick={handleClick}
              button
              iscollapsed={true}
              style={{ height: "30px" }}
            >
              <CustomListItemText selected={props.page === "leagues"} primary="Leagues" />
            </CustomListItem>

            <CustomListItem
              // @ts-ignore https://github.com/mui-org/material-ui/issues/17645
              component={NavLink}
              selected={props.page === "leaderboards"}
              exact
              to={`/leaderboards`}
              onClick={handleClick}
              button
              iscollapsed={true}
              style={{ height: "30px" }}
            >
              <CustomListItemText selected={props.page === "leaderboards"} primary="Leaderboard" />
            </CustomListItem>

            <CustomListItem
              // @ts-ignore
              component={NavLink}
              selected={props.page === "manual"}
              exact
              to={`/manual`}
              onClick={handleClick}
              button
              iscollapsed={true}
              style={{ height: "30px" }}
            >
              <CustomListItemText selected={props.page === "manual"} primary="Manual" />
            </CustomListItem>

            <CustomListItem
              // @ts-ignore
              component={NavLink}
              selected={props.page === "transfers"}
              exact
              to={`/transfers`}
              onClick={handleClick}
              button
              iscollapsed={true}
              style={{ height: "30px" }}
            >
              <CustomListItemText selected={props.page === "transfers"} primary="Transfers" />
            </CustomListItem>
          </List>
        </Collapse>
      </List>
    </ClickAwayListener>
  );
};

export default connect<StateProps, DispatchProps, {}, RootState>(
  (state) => ({
    page: state.page,
  }),
  (dispatch: AppDispatch) => ({
    changePage: (p: Page) => dispatch(page.actions.changePage(p)),
  })
)(NavigateButton);

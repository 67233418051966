interface WebSocketEventHandler {
  event: string;
  callback: Function;
}

export default class WebSocketClient {
  ws: WebSocket;
  eventHandlers: WebSocketEventHandler[] = [];

  constructor(
    type: string,
    data: Object = {},
    url: string = process.env.NODE_ENV === "production"
      ? "wss://api.courthoops.com"
      : "ws://localhost:3500"
  ) {
    this.ws = new WebSocket(url);

    this.ws.onopen = () => {
      this.ws.send(JSON.stringify({ event: "connect", data: { type, data } }));
      this.eventHandlers.filter((e) => e.event === "open").forEach((e) => e.callback());
    };

    this.ws.onmessage = (evt) => {
      const message = JSON.parse(evt.data);
      this.eventHandlers.filter((e) => e.event === "message").forEach((e) => e.callback(message));
    };

    this.ws.onclose = () => {
      this.eventHandlers.filter((e) => e.event === "close").forEach((e) => e.callback());
    };
  }

  on(event: string, callback: Function) {
    this.eventHandlers.push({ event, callback });
  }
}

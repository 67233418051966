import React, { ReactElement } from "react";
import styled from "styled-components/macro";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import { breakpoint } from "../../util/breakpoint";
import { theme } from "../..";

interface OwnProps {
  title: string | ReactElement;
  width: string;
  button: boolean;
  buttonText?: string;
  className?: string;
  other?: any;
  color?: string;
}

const Tile: React.FC<OwnProps> = (props) => {
  return (
    <Container width={props.width} className={props.className}>
      <Header
        button={props.button}
        other={props.other}
        color={props.color ? props.color : theme.yellowFont}
      >
        {props.title}
        {props.button && (
          <ButtonContainer>
            {props.buttonText}
            <ArrowRightAltIcon />
          </ButtonContainer>
        )}
        {props.other}
      </Header>
      <ChildrenContainer className="children">{props.children}</ChildrenContainer>
    </Container>
  );
};

export default Tile;

const Container = styled.div<{ width: string }>`
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.loginBackground};
  width: ${(props) => props.width};
  padding-bottom: 5px;
  border-radius: 5px;
  border: solid 0.5px ${(props) => props.theme.tileHeaderBorder};
  // height: -moz-fit-content;
  // height: -webkit-fit-content;
  // height: -fit-content;
  height: auto;
  margin-bottom: 20px;
`;

const Header = styled.div<{ button: boolean; other: boolean; color: string }>`
  height: 50px;
  background-color: ${(props) => props.theme.tileHeaderBackground};
  border-bottom: 0.5px solid ${(props) => props.theme.tileHeaderBorder};
  border-left: 5px solid ${(props) => props.color};
  border-radius: 5px 5px 0 0;
  font-family: LemonMilk;
  font-size: 15px;
  line-height: 1.67;
  letter-spacing: 1.17px;
  color: ${(props) => props.theme.whiteFont};
  padding-left: 20px;

  display: flex;
  align-items: center;
  justify-content: ${(props) => (props.button || props.other ? "space-between" : "none")};
`;
const ButtonContainer = styled.div`
  width: 170px;
  height: 55px
  border-radius: 3px;
  border: solid 0.5px ${(props) => props.theme.inputBorder};
  background-color: ${(props) => props.theme.lightGrayBackground};
  margin-right: 20px;

  display: flex;
  align-items: center;
  justify-content: center;

  font-family: LemonMilk;
  font-size: 12px;

  line-height: 2.08;
  letter-spacing: 0.94px;

  color: ${(props) => props.theme.grayFont};
`;

const ChildrenContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-evenly;
  background-color: ${(props) => props.theme.loginBackground};
  padding-top: 4px;

  @media ${breakpoint.mobileTablet} {
    padding-top: 10px;
  }
`;

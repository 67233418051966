import React, { useEffect } from "react";
import styled from "styled-components/macro";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import { connect } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/store";
import { fireModal } from "../../../redux/reducer/modal";
import { FetchStatus } from "../../../redux/fetchStatus";
import { FireFail } from "../PlayerPage/NameChangeFail";
import { buttonStyles, useStyles, Fade } from "../energyPage/modalExports";
import firePlayer, { firePlayerAction } from "../../../redux/reducer/firePlayer";
import { yupResolver } from "@hookform/resolvers";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { refreshPage } from "../../../util/refreshPage";
import {
  IndividualPlayerWithExtraAttributes,
  RosterPlayerWithExtraAttributes,
} from "../../../api/roster";
import { conversion } from "../../../util/avatarConversion";
import { breakpoint } from "../../../util/breakpoint";

interface OwnProps {
  id: number;
  name: string;
  player: IndividualPlayerWithExtraAttributes | RosterPlayerWithExtraAttributes;
}

interface StateProps {
  modalOpen: boolean;
  openPlayerId: number;
  fetchStatus: FetchStatus;
}

interface DispatchProps {
  open: () => void;
  close: () => void;
  submit: (id: number, amount: number) => void;
  reset: () => void;
}

const schema = yup.object().shape({
  amount: yup
    .string()
    .required()
    .matches(/^[0-9]*$/)
    .min(4)
    .max(9)
    .trim(),
});

type Page = "main" | "fail";

function FireModal(props: OwnProps & StateProps & DispatchProps) {
  const classes = useStyles();
  const [page, setPage] = React.useState("main" as Page);
  const { fetchStatus, id, close } = props;

  const handleClose = () => {
    props.close();
  };

  useEffect(() => {
    if (fetchStatus === "success") {
      close();
      refreshPage();
    }

    if (fetchStatus === "failed") {
      setPage("fail");
    }
  }, [fetchStatus, id, close]);

  const handleErrorOnClose = () => {
    props.close();
    props.reset();
    setTimeout(() => setPage("main"), 600);
  };

  const pageToDisplay = {
    main: (
      <Main
        submit={(amount) => props.submit(props.id, amount)}
        onNo={() => props.close()}
        id={props.id}
        name={props.name}
        player={props.player}
      ></Main>
    ),
    fail: <FireFail onClose={() => handleErrorOnClose()} button={buttonStyles}></FireFail>,
  }[page];

  return (
    <div>
      <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        className={classes.modal}
        open={props.modalOpen && props.openPlayerId === props.player.playerId}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={props.modalOpen && props.openPlayerId === props.player.playerId}>
          <div className={classes.paper}>{pageToDisplay}</div>
        </Fade>
      </Modal>
    </div>
  );
}

interface MainProps {
  submit: (name: number) => void;
  onNo: () => void;
  id: number;
  name: string;
  player: IndividualPlayerWithExtraAttributes | RosterPlayerWithExtraAttributes;
}

export interface NameForm {
  amount: number;
}

const Main = (props: MainProps) => {
  const { handleSubmit } = useForm<NameForm>({
    resolver: yupResolver(schema),
  });
  const [disabled, setDisabled] = React.useState(false);
  const internationalNumberFormat = new Intl.NumberFormat("en-US");
  const firingFee = internationalNumberFormat.format(
    Math.ceil(props.player.salary * 2.5 * props.player.duration)
  );

  const handleButtonClick = () => {
    setDisabled(true);
    props.submit(0);
  };

  return (
    <Container>
      <h2 className="spring-modal-title">Firing {props.name}?</h2>
      <div className="photo-name">
        <div>{conversion(props.player)}</div>
      </div>
      <form
        className="vertical"
        onSubmit={handleSubmit((f: { amount: string }) => {
          props.submit(0);
        })}
      >
        <div className="horizontal">
          <button onClick={handleButtonClick} type="submit" className="red" disabled={disabled}>
            Fire Now (Cannot be Undone)
          </button>
        </div>
        <div style={{ color: "red", marginTop: "5px" }}>
          <div>*A {firingFee} Buyout Fee will be deducted from your balance</div>
        </div>
      </form>
    </Container>
  );
};

export default connect<StateProps, DispatchProps, OwnProps, RootState>(
  (state) => ({
    modalOpen: state.fireModal.isOpen,
    openPlayerId: state.fireModal.playerId,
    fetchStatus: state.firePlayer.fetchStatus,
  }),
  (dispatch: AppDispatch) => ({
    reset: () => dispatch(firePlayer.actions.reset()),
    open: () => {
      dispatch(fireModal.actions.open());
    },
    close: () => {
      dispatch(fireModal.actions.close());
      dispatch(fireModal.actions.setPlayerId(0));
    },
    submit: (id) => dispatch(firePlayerAction({ id: id })),
  })
)(FireModal);

const Container = styled.div`
  .vertical {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }

  .auction-details {
    font-family: LemonMilklight;
    width: 100%;
    height: 70px;
    font-size: 12px;
    color: ${(props) => props.theme.whiteFont};
    letter-spacing: 0.8px;
    text-align: center;
    margin-right: 60px;
    margin-top: auto;
    margin-bottom: auto;
  }

  .spring-modal-title{
      text-align: center;
  }

  .yellow {
    background-color: green;
    font-family: LemonMilk;
    font-size: 12px;
    border-radius: 6px;
    letter-spacing: 1.8px;
    width: 300px;
    height: 50px;
    color: ${(props) => props.theme.whiteFont};
    border-width: 0px;
    cursor: pointer;
  }

  .red {
    background-color: red;
    font-family: LemonMilk;
    font-size: 12px;
    border-radius: 6px;
    letter-spacing: 1.8px;
    width: 300px;
    height: 50px;
    color: ${(props) => props.theme.whiteFont};
    border-width: 0px;
    cursor: pointer;
  }

  .photo-name {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    margin: 20px 0 0px 0;

    @media ${breakpoint.mobileTablet} {
      margin: 0 0 10px 0;
    }
  }

  .player-photo-container {
    height: 220px;
    width: 220px;
    background-color: ${(props) => props.theme.mediumGrayBackground};
    border: solid 0.5px ${(props) => props.theme.tileHeaderBorder};
    margin: 10px 0 20px 0;
    position: relative;
  }

  .player-image {
    position:absolute;
    top: 0px;
    left: 0px;
    width="220" 
    height="220"
  }

  .playerInput {
    flex: 1;
    height: 60px;
  }

  .warnings {
    margin-top: 25px;
    font-size: 10px;
  }

  .horizontal {
    padding-left: auto;
    margin-top: 15px;
    position: relative;
    align-items: right;
    justify-content: right;
    float: right;
  }

  input {
    border: solid 0.5px ${(props) => props.theme.inputBorder};
    background-color: ${(props) => props.theme.lightGrayBackground};
    font-size: 12px;
    font-family: fa-light;
    margin-top: 10px;
    border-radius: 6px;
    width: 290px;
    height: 50px;
    padding: 0 0 0 10px;
    display: flex;
    color: ${(props) => props.theme.grayFont};

    ::placeholder {
      font-family: LemonMilk;
      font-size: 12px;
    }

    :focus {
      //delete blue border when clicked
      outline: none;
    }
  }
`;

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TrainingForm } from "../../api/submitTraining";
import { MyKnownError } from "../myKnownError";
import { defaultState } from "../util/defaultState";

const submitTrainingSlice = createSlice({
  name: "submitTraining",
  initialState: defaultState<TrainingForm>(),
  reducers: {
    submitTrainingLoading: (state) => {
      state.fetchStatus = "pending";
      return state;
    },
    submitTrainingSuccess: (state) => {
      state.fetchStatus = "success";
      return state;
    },
    submitTrainingFailed: (state, action: PayloadAction<MyKnownError>) => {
      state.fetchStatus = "failed";
      state.error = action.payload;
      return state;
    },
    submitTrainingReset: (state) => {
      state = defaultState();
      return state;
    },
  },
});

export const {
  submitTrainingLoading,
  submitTrainingSuccess,
  submitTrainingFailed,
  submitTrainingReset,
} = submitTrainingSlice.actions;
export default submitTrainingSlice.reducer;

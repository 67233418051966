import React from "react";
import styled from "styled-components/macro";
import Select from "@material-ui/core/Select";
import { makeStyles, createStyles, Input } from "@material-ui/core";
import { theme } from "../..";

const usePaperStyles = makeStyles(() =>
  createStyles({
    paper: {
      margin: "4px 0 0 -1px",
      backgroundColor: `${theme.lightGrayBackground}`,
      border: `0.5px solid ${theme.inputBorder}`,
      "& .MuiMenuItem-root": {
        backgroundColor: `${theme.dropdownBackground}`,
        borderRadius: "3px",
        border: `0.5px solid ${theme.inputBorder}`,
        color: `${theme.whiteFont}`,
        //could change depending on the dropdown
        height: "45px",
        margin: "10px 20px 0 25px",
        fontFamily: "LemonMilk",
        fontSize: "12px",
        lineHeight: "1.25",
        letterSpacing: "0.94px",

        '&[data-focus="true"]': {
          //could change if button theme is orange
          backgroundColor: `${theme.orangeTheme}`,
        },
        // Selected
        '&[aria-selected="true"]': {
          //could change if button theme is orange
          backgroundColor: `${theme.orangeTheme}`,
        },
        "& .MuiAutocomplete-listbox": {
          //could change if button theme is orange
          backgroundColor: `${theme.orangeTheme}`,
        },
        "&:hover": {
          //could change if button theme is orange
          backgroundColor: `${theme.orangeTheme}`,
        },
      },
    },
    input: {
      "& .MuiSelect-root": {
        height: "55px",
      },
    },
  })
);

// https://github.com/react-hook-form/react-hook-form/issues/2151
type HandleChange = (
  event: React.ChangeEvent<{
    name?: string | undefined;
    value: unknown;
  }>,
  child: React.ReactNode
) => void;

const DropDownOrange: React.FC<
  React.ComponentProps<typeof Select> & { handleChange?: HandleChange }
> = ({ handleChange, ...other }) => {
  const classes = usePaperStyles();
  return (
    <Select
      {...other}
      input={
        <Input
          disableUnderline
          classes={{
            root: classes.input,
          }}
        />
      }
      MenuProps={{
        classes: { paper: classes.paper },
        variant: "menu",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "left",
        },
        getContentAnchorEl: null,
        //setting variant to menu makes it appear below the element
      }}
    />
  );
};

const DropDown = styled(DropDownOrange)`
  height: 55px;

  border-radius: 3px;
  border: solid 0.5px ${(props) => props.theme.inputBorder};
  background-color: ${(props) => props.theme.lightGrayBackground};

  .training-stats-2 {
    padding-left: 0px;
    flex-direction: column;
    padding-right: 0px;
    margin-right: auto !important;
    margin-left: 0px !important;
    justify-content: flex-start;
    align-items: flex-start;
    line-height: 1.3;
  }
  
  div {
    font-family: LemonMilk;
    font-size: 12px;
    line-height: 1.25;
    color: ${(props) => props.theme.grayFont};
    display: flex;
    align-items: center;
    width: calc(100% - 25px - 24px);
    padding-left: 25px;
    &:focus {
        background-color: ${(props) => props.theme.lightGrayBackground};
        //could change depending on the dropdown
        max-height: 35px;
    },
  }

  svg {
    color: ${(props) => props.theme.grayFont};
  }

  p {
    white-space: pre-line;
}
`;

export default DropDown;

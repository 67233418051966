import { createSlice } from "@reduxjs/toolkit";

export default createSlice({
  name: "sideMenuBar",
  initialState: false,
  reducers: {
    moveMenuBar: (state) => {
      return !state;
    },
    closeMenuBar: () => {
      return false;
    },
  },
});

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { Announcement } from "./announcement";
import { URL } from "./checkout";
import { Contract, ContractOptions } from "./contracts";
import { Economy } from "./economy";
import { CutOrBulkSubmit, Energy, ExtraTeamScrimmageSubmit, GymAfterHoursSubmit } from "./energy";
import { Game } from "./game";
import { Conversation, MessageSend } from "./message";
import { Notifications } from "./notification";
import { Stats } from "./player";
import { ResetPassword } from "./resetPassword";
import { IndividualPlayer, Player, PlayerAvatar } from "./roster";
import { Schedule } from "./schedule";
import { Scrimmages, ScrimmageSend } from "./scrimmages";
import { TeamsInLeague } from "./standings";
import { TeamAverages } from "./statistics";
import { Leaderboard } from "./leaderboards";

export const api = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl:
      process.env.NODE_ENV === "development"
        ? "http://localhost:3500/"
        : "https://api.courthoops.com/",
    //   process.env.REACT_APP_VERCEL_ENV === "development"
    //     ? "https://beta.api.courthoops.com/"
    //     : process.env.REACT_APP_VERCEL_ENV === "production"
    //     ? "https://api.courthoops.com/"
    //     : "http://localhost:3500/",
    credentials: "include",
  }),
  tagTypes: [],
  endpoints: (build) => ({
    fetchRoster: build.query<Player[], number>({
      query: (id) => ({ url: `/Roster/${id}` }),
    }),
    getRoster: build.query<Player[], void>({
      query: () => ({ url: "/Roster" }),
    }),
    fetchSchedule: build.query<Schedule[], { id: number; season: number }>({
      query: ({ id, season }) => ({ url: `/Users/${id}/Schedule?season=${season}` }),
    }),
    fetchStandings: build.query<TeamsInLeague, { id: number; season: number }>({
      query: ({ id, season }) => ({ url: `/Users/${id}/Standings?season=${season}` }),
    }),
    fetchStatistics: build.query<TeamAverages, number>({
      query: (id) => ({ url: `/Users/${id}/Statistics` }),
    }),
    fetchContracts: build.query<Contract[], number>({
      query: (id) => ({ url: `/Users/${id}/Contracts` }),
    }),
    fetchEconomy: build.query<Economy, void>({
      query: () => ({ url: `/Economy` }),
    }),
    fetchAnnouncement: build.query<Announcement, void>({
      query: () => ({ url: `/Announcement` }),
    }),
    getFile: build.query<File, void>({
      query: () => ({ url: `/Users/avatar` }),
    }),
    fetchEnergy: build.query<Energy, void>({
      query: () => ({ url: `/Energy` }),
    }),
    fetchGame: build.query<Game, number>({
      query: (id) => ({ url: id !== 0 ? `/Game/${id}` : "/Gauntlet/16" }),
    }),
    fetchAuctions: build.query<Player[], void>({
      query: () => ({ url: `/Auctions` }),
    }),
    fetchLeagues: build.query<TeamsInLeague, { id: number; season: number }>({
      query: ({ id, season }) => ({
        url:
          id <= 10000 ? `/Leagues/${id}?season=${season}` : `/Leagues/Tier/${id}?season=${season}`,
      }),
    }),
    fetchTiers: build.query<TeamsInLeague, { id: number; season: number }>({
      query: ({ id, season }) => ({ url: `/Leagues/Tier/${id}?season=${season}` }),
    }),
    fetchMyTier: build.query<{ tier: number }, void>({
      query: () => ({ url: `/Leagues/MyTier` }),
    }),
    fetchLeaderboards: build.query<Leaderboard[], void>({
      query: () => ({ url: `/Leagues/Leaderboards` }),
    }),
    fetchMessages: build.query<Conversation[], void>({
      query: () => ({ url: `/Messages` }),
    }),
    fetchNotifications: build.query<Notifications, void>({
      query: () => ({ url: `/Notifications` }),
    }),
    fetchPlayer: build.query<IndividualPlayer & Stats & PlayerAvatar, number>({
      query: (id) => ({ url: `/Player/${id}` }),
    }),
    fetchPremiumStatus: build.query<
      { premiumStatus: boolean; teamId: number; avatarUrl: string },
      number
    >({
      query: (teamId) => ({ url: `/Users/Premium/${teamId}` }),
    }),
    fetchScrimmages: build.query<Scrimmages, void>({
      query: () => ({ url: `/Scrimmages` }),
    }),
    fetchOpposingScrimmages: build.query<Scrimmages, number>({
      query: (id) => ({ url: `/Scrimmages/${id}` }),
    }),

    // DELETE ROUTES
    clearMessage: build.mutation<void, number>({
      query: (id) => ({
        url: `/Messages/${id}`,
        method: "DELETE",
      }),
    }),
    deleteScrimmageRequest: build.mutation<void, number>({
      query: (id) => ({
        url: `/Scrimmages/${id}`,
        method: "DELETE",
      }),
    }),

    // POST ROUTES
    setTeamNameChange: build.mutation<void, string>({
      query: (name) => ({
        url: `/Users/changeName`,
        method: "POST",
        body: { name },
      }),
    }),
    setNameChange: build.mutation<void, { id: number; name: string }>({
      query: ({ id, name }) => ({
        url: `/Player/${id}/changeName`,
        method: "POST",
        body: { name },
      }),
    }),
    acceptScrimmageRequest: build.mutation<void, number>({
      query: (id) => ({
        url: `/Scrimmages/${id}`,
        method: "POST",
      }),
    }),
    sendScrimmageRequest: build.mutation<void, ScrimmageSend>({
      query: (data) => ({
        url: `/Scrimmages/Send`,
        method: "POST",
        body: data,
      }),
    }),
    resetPassword: build.mutation<void, ResetPassword>({
      query: (data) => ({
        url: `/Users/reset-password`,
        method: "POST",
        body: data,
      }),
    }),
    sendMessage: build.mutation<void, MessageSend>({
      query: (data) => ({
        url: `/Messages`,
        method: "POST",
        body: data,
      }),
    }),
    processCheckout: build.mutation<URL, number>({
      query: (id) => ({
        url: `/create-checkout-session/`,
        method: "POST",
        body: { priceId: id },
      }),
    }),
    clearNotification: build.mutation<void, number>({
      query: (id) => ({
        url: `/Notifications/${id}`,
        method: "DELETE",
      }),
    }),
    // Not tested since contracts are not live as of now on the site
    sendContracts: build.mutation<void, ContractOptions[]>({
      query: (data) => ({
        url: `/Contracts`,
        method: "POST",
        body: { contracts: data },
      }),
    }),
    buyEnergy: build.mutation<void, void>({
      query: () => ({
        url: `/Energy/transfer`,
        method: "POST",
        body: {},
      }),
    }),
    useEnergy: build.mutation<void, number>({
      query: (data) => ({
        url: `/Energy`,
        method: "POST",
        body: { energyId: data },
      }),
    }),
    useEnergyAfterHours: build.mutation<void, GymAfterHoursSubmit>({
      query: (data) => ({
        url: `/Energy`,
        method: "POST",
        body: data,
      }),
    }),
    useEnergyScrimmage: build.mutation<void, ExtraTeamScrimmageSubmit>({
      query: (data) => ({
        url: `/Energy`,
        method: "POST",
        body: data,
      }),
    }),
    useEnergyWeight: build.mutation<void, CutOrBulkSubmit>({
      query: (data) => ({
        url: `/Energy`,
        method: "POST",
        body: data,
      }),
    }),
    sendFile: build.mutation<void, FormData>({
      query: (data) => ({
        url: `/Users/avatar`,
        method: "POST",
        body: data,
      }),
    }),
  }),
});

import React from "react";
import OffensiveSkillsCard from "./OffensiveSkillsCard";
import DefensiveSkillsCard from "./DefensiveSkillsCard";
import IntangibleSkillsCard from "./IntangibleSkillsCard";
import styled from "styled-components/macro";
import { RosterPlayerWithExtraAttributes } from "../../../api/roster";
import { breakpoint } from "../../../util/breakpoint";
import { useResponsive } from "../../../util/useResponsive";
import Tile from "../Tile";
import PlayerStats from "./PlayerStats";
import BidModal from "../../lib/transferListPage/bidModal";
import ListModal from "../../lib/transferListPage/listModal";
import FireModal from "../../lib/transferListPage/fireModal";
import { fireModal } from "../../../redux/reducer/modal";
import { bidModal } from "../../../redux/reducer/modal";
import { listModal } from "../../../redux/reducer/modal";
import { getDateAndTimeFromEpoch } from "../../../util/getDateAndTimeFromEpoch";
import { RootState, AppDispatch } from "../../../redux/store";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
// import { checkGoat } from "../../../util/checkGoat";

interface DispatchProps {
  openFirePlayerModal: (playerId: number) => void;
  openListPlayerModal: (playerId: number) => void;
  openBidPlayerModal: (playerId: number) => void;
}

const PlayerCard = (props: RosterPlayerWithExtraAttributes & DispatchProps) => {
  const { isMobile, isTablet } = useResponsive();
  const internationalNumberFormat = new Intl.NumberFormat("en-US");
  const firingFee = internationalNumberFormat.format(
    Math.ceil(props.salary * 2.5 * props.duration)
  );
  const isMyPlayer: boolean = props.teamId === props.myId;
  const playerOnAuction: boolean = props.auctions[0] && props.auctions[0].status !== 0;

  return (
    <Container>
      <BidModal
        id={props.id}
        name={`${props.firstName} ${props.lastName}`}
        player={props}
      ></BidModal>
      <ListModal
        id={props.id}
        name={`${props.firstName} ${props.lastName}`}
        player={props}
      ></ListModal>
      <PlayerStats {...props}></PlayerStats>
      <FireModal
        id={props.id}
        name={`${props.firstName} ${props.lastName}`}
        player={props}
      ></FireModal>
      <div></div>
      <StyledTile
        title="Skills"
        width={isTablet ? "500px" : isMobile ? "90%" : "800px"}
        button={false}
      >
        <OffensiveSkillsCard lineSize="sml" {...props}></OffensiveSkillsCard>
        <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
          <div className="skills">
            <DefensiveSkillsCard lineSize="sml" {...props}></DefensiveSkillsCard>
            <IntangibleSkillsCard lineSize="sml" {...props}></IntangibleSkillsCard>
          </div>
          {playerOnAuction ? (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                height: "100px",
                flex: 1,
                marginBottom: "20px",
              }}
            >
              <div className="auction-details">
                <div style={{ color: "gold", fontSize: "26px" }}>
                  {props.auctions[0].bids[0]
                    ? "Bid: $" + internationalNumberFormat.format(props.auctions[0].bids[0].amount)
                    : "Price: $" +
                      internationalNumberFormat.format(props.auctions[0].startingAmount)}
                </div>
                <div style={{ color: "white", fontSize: "10px" }}>
                  {props.auctions[0].bids[0] ? (
                    <NavLink
                      className={"team-name"}
                      to={`/team/${props.auctions[0].bids[0].teamId}`}
                    >
                      {`last bid by: ${props.auctions[0].bids[0].teamName}`}
                    </NavLink>
                  ) : (
                    `Starting Bid`
                  )}
                </div>
                <div style={{ color: "red" }}>
                  <div>Ends: {getDateAndTimeFromEpoch(props.auctions[0].date)}</div>
                </div>
              </div>
              <button
                onClick={() => props.openBidPlayerModal(props.playerId)}
                className="yellow"
                type="submit"
              >
                Bid On Player
              </button>
            </div>
          ) : isMyPlayer ? (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                height: "100px",
                flex: 1,
                marginBottom: "20px",
              }}
            >
              <button
                onClick={() => props.openFirePlayerModal(props.id)}
                className="red"
                type="submit"
                disabled={!props.tlOn}
              >
                {props.tlOn ? `Fire Player For ${firingFee}` : "Free Agency Off Until Next Season"}
              </button>
              <button
                onClick={() => props.openListPlayerModal(props.playerId)}
                className="yellow"
                type="submit"
                disabled={!props.tlOn}
              >
                {props.tlOn
                  ? `List Player On Transfer List (${props.tax}% tax)`
                  : "Free Agency Off Until Next Season"}
              </button>
            </div>
          ) : (
            <></>
          )}
        </div>
      </StyledTile>
    </Container>
  );
};

export default connect<{}, DispatchProps, {}, RootState>(
  (state) => ({}),
  (dispatch: AppDispatch) => ({
    openBidPlayerModal: (playerId: number) => {
      dispatch(bidModal.actions.open());
      dispatch(bidModal.actions.setPlayerId(playerId));
    },
    openFirePlayerModal: (playerId: number) => {
      dispatch(fireModal.actions.open());
      dispatch(fireModal.actions.setPlayerId(playerId));
    },
    openListPlayerModal: (playerId: number) => {
      dispatch(listModal.actions.open());
      dispatch(listModal.actions.setPlayerId(playerId));
    },
  })
)(PlayerCard);

const StyledTile = styled(Tile)`
  min-height: 424px;
  padding: 0;
  background-color: ${(props) => props.theme.loginBackground};
  display: flex;

  .children {
    flex-direction: row;
    align-items: flex-start;
    margin-right: 10px;
    background-color: ${(props) => props.theme.loginBackground};

    .skill-name {
      width: 110px;
    }

    .skills {
      display: flex;
      flexdirection: row;
      justifycontent: center;
      height: 282px;
      margin-left: 10px;

      @media ${breakpoint.mobileTablet} {
        flex-direction: column;
        padding-top: 0;
        align-items: center;
        height: 460px;
        width: 100%;
      }
    }

    .team-name {
      font-family: LemonMilklight;
      font-size: 10px;
      color: white;
      text-decoration: none;
    }

    .auction-details {
      font-family: LemonMilklight;
      width: 40%;
      font-size: 12px;
      color: ${(props) => props.theme.whiteFont};
      letter-spacing: 0.8px;
      text-align: center;
      margin-right: 60px;
      margin-top: auto;
      margin-bottom: auto;
    }

    div {
      div {
        //increase space between SkillLine
        margin-bottom: 5px;

        @media ${breakpoint.mobileTablet} {
          margin-bottom: 0;
        }
        //font-size of SkillLine
        font-size: 12px;
        div {
          //reduce space between stars and skill-name
          //   margin: 0;
        }
      }
    }

    button {
      width: 40%;
      border: none;
      font-family: LemonMilk;
      font-size: 12px;
      letter-spacing: 4.8px;
      color: ${(props) => props.theme.whiteFont};
      height: 70px;

      &:hover {
        cursor: pointer;
      }

      &:focus {
        //delete blue border when clicked
        outline: 0;
      }

      &.yellow {
        background-color: green;
        font-family: LemonMilk;
        font-size: 12px;
        border-radius: 6px;
        letter-spacing: 0.8px;

        color: ${(props) => props.theme.whiteFont};
      }

      &.red {
        background-color: red;
        font-family: LemonMilk;
        border-radius: 6px;
        font-size: 12px;
        letter-spacing: 0.8px;

        margin-right: 50px;
        color: ${(props) => props.theme.whiteFont};
      }

      &.gray {
        background-color: ${(props) => props.theme.grayButton};
      }
    }

    @media ${breakpoint.mobileTablet} {
      flex-direction: column;
      padding-top: 0;
      align-items: center;
    }
    #shadowBox {
      background-color: ${(props) => props.theme.loginBackground};
    }

    .rainbow_text_animated {
      background: linear-gradient(
        45deg,
        #ff2400,
        #e81d1d,
        #e8b71d,
        #e3e81d,
        #1de840,
        #1ddde8,
        #2b1de8,
        #dd00f3,
        #dd00f3
      );
      -webkit-background-clip: text;
      background-clip: text;
      color: transparent;
      animation: rainbow 6s ease infinite;
      background-size: 400% 400%;
      font-family: LemonMilklight;
      font-size: 12px;
    }

    @keyframes rainbow {
      0% {
        background-position: 0% 50%;
      }
      50% {
        background-position: 100% 25%;
      }
      100% {
        background-position: 0% 50%;
      }
    }
  }
`;

const Container = styled.div`
  width: 90%;
  max-width: 1350px;
  min-heignt: 450px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  border-radius: 5px;
  border: solid 0.5px ${(props) => props.theme.inputBorder};
  background-color: ${(props) => props.theme.mediumGrayBackground};
  margin-bottom: 30px;
  padding: 20px 0;
  flex-wrap: wrap;

  & > div {
    margin: 10px;
  }

  @media ${breakpoint.mobileTablet} {
    flex-direction: column;
    // height: -moz-fit-content;
    // height: -webkit-fit-content;
    // height: -fit-content;
    height: auto;
  }
`;

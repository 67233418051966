import React from "react";
import page from "./page";
import CHLogoOne from "../lib/images/CHLogoOne.png";
import styled from "styled-components/macro";
import { NavLink } from "react-router-dom";
import { breakpoint } from "../../util/breakpoint";

const Footer = () => {
  return window.location.pathname !== "/login" &&
    window.location.pathname !== "/register" &&
    window.location.pathname !== "/logout" ? (
    <Horizontal className="expenses">
      <div
        style={{
          color: "white",
          display: "flex",
          minHeight: "25px",
          alignItems: "center",
          justifyContent: "center",
          fontFamily: "LemonMilklight",
          fontSize: "12px",
          textDecoration: "none",
        }}
      >
        <img style={{ margin: "0 20px", height: "60px" }} src={CHLogoOne} alt="logo"></img>
        &#9400; Apex Digital, LLC 2020 - 2023 All Rights Reserved.
      </div>
      <NavLink
        to={`/privacyPolicy`}
        style={{
          color: "gold",
          display: "flex",
          minHeight: "25px",
          alignItems: "center",
          justifyContent: "center",
          fontFamily: "LemonMilklight",
          fontSize: "12px",
          textDecoration: "none",
        }}
      >
        Privacy Policy
      </NavLink>
      <NavLink
        to={`/termsAndConditions`}
        style={{
          color: "gold",
          display: "flex",
          minHeight: "25px",
          alignItems: "center",
          justifyContent: "center",
          fontFamily: "LemonMilklight",
          fontSize: "12px",
          textDecoration: "none",
        }}
      >
        Terms And Conditions
      </NavLink>
      <NavLink
        to={`/cookiePolicy`}
        style={{
          color: "gold",
          display: "flex",
          minHeight: "25px",
          alignItems: "center",
          justifyContent: "center",
          fontFamily: "LemonMilklight",
          fontSize: "12px",
          textDecoration: "none",
        }}
      >
        Cookie Policy
      </NavLink>
    </Horizontal>
  ) : (
    <></>
  );
};

const Horizontal = styled.div`
  display: flex;
  flex-direction: row;
  min-height: 50px;
  width: 100%;
  align-items: center;
  justify-content: space-evenly;
  padding-bottom: 10px;

  @media ${breakpoint.mobileTablet} {
    flex-direction: column;
  }
`;

export default page("footer")(Footer);

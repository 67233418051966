import { css } from "styled-components/macro";
import { breakpoint } from "../../../util/breakpoint";

export const CardContainerStyle = css`
  display: flex;
  flex-direction: column;
  width: 255px;
  background-color: ${(props) => props.theme.loginBackground};

  @media ${breakpoint.mobileTablet} {
    margin-bottom: 5px;
    width: 95%;
  }
`;

export const PStyle = css`
  font-family: LemonMilk;
  font-size: 12px;
  letter-spacing: 0.94px;
  color: ${(props) => props.theme.whiteFont};
  margin: 10px 15px 10px 0;

  @media ${breakpoint.mobileTablet} {
    margin: 10px 0 5px 5px;
  }
`;

export {};

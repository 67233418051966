import React from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components/macro";
import { Change, SkillLevel, Specialty } from "../../../api/roster";
import { CardContainerStyle, PStyle } from "../styles/skillCardStyles";
import SkillLine from "./SkillLine";

interface OwnProps {
  id: number;
  outsideDefense?: SkillLevel;
  stealing?: SkillLevel;
  insideDefense?: SkillLevel;
  blocking?: SkillLevel;
  lineSize: "sml" | "lrg";
  changes: Change[];
  specialties: Specialty[];
}

const lineSizes = {
  sml: 35,
  lrg: 55,
};

const DefensiveSkillsCard = (props: OwnProps) => {
  const height = lineSizes[props.lineSize];
  const defensiveSpecialies = props.specialties.slice(7, 12);

  const DefensiveSkillLine = (props: {
    name: string;
    skill?: SkillLevel;
    change: Change | undefined;
  }) => {
    return props.skill ? (
      <SkillLine
        name={props.name}
        change={props.change}
        skill={props.skill}
        number={props.skill}
        height={height}
      />
    ) : (
      <SkillLine name={props.name} change={props.change} skill={0} number="-" height={height} />
    );
  };

  return (
    <CardContainer>
      <div className="badge-container">
        <P>Defensive Skills</P>
        <NavLink to={`/badges/${props.id}`} className="badge-container">
          <div className="bronze-badge">
            {props.outsideDefense ? defensiveSpecialies.filter((e) => e.level === 1).length : "?"}
          </div>
          <div className="silver-badge">
            {props.outsideDefense ? defensiveSpecialies.filter((e) => e.level === 2).length : "?"}
          </div>
          <div className="gold-badge">
            {props.outsideDefense ? defensiveSpecialies.filter((e) => e.level === 3).length : "?"}
          </div>
        </NavLink>
      </div>
      <DefensiveSkillLine
        name="Outside Def."
        skill={props.outsideDefense}
        change={props.changes.find((e) => e.stat === "Outside Def.")}
      />
      <DefensiveSkillLine
        name="Stealing"
        skill={props.stealing}
        change={props.changes.find((e) => e.stat === "Stealing")}
      />
      <DefensiveSkillLine
        name="Inside Def."
        skill={props.insideDefense}
        change={props.changes.find((e) => e.stat === "Inside Def.")}
      />
      <DefensiveSkillLine
        name="Blocking"
        skill={props.blocking}
        change={props.changes.find((e) => e.stat === "Blocking")}
      />
    </CardContainer>
  );
};

export default DefensiveSkillsCard;

const CardContainer = styled.div`
  ${CardContainerStyle}

  .badge-container {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin-top: 5px;
    text-decoration: none;
    color: black;
  }

  .gold-badge,
  .bronze-badge,
  .silver-badge {
    background: url(../../../images/badges/GoldBadgeSmall.png);
    height: 33px;
    width: 30px;
    background-size: 30px;
    margin-bottom: 0px !important;
    font-size: 20px !important;
    text-align: center;
    vertical-align: middle;
    line-height: 30px;
    font-family: LemonMilkLight;
    position: relative;
    overflow: hidden;
    margin: 0 4px 0 4px;
  }

  .bronze-badge {
    background: url(../../../images/badges/BronzeBadgeSmall.png);
    background-size: 30px;
  }
  .silver-badge {
    background: url(../../../images/badges/SilverBadgeSmall.png);
    background-size: 30px;
  }

  // .gold-badge::before {
  //   content: '';
  //   position: absolute;
  //   background-color: rgba(255, 255, 255, 0.3);
  //   transform: skewX(-30deg);
  //   height: 33px;
  //   width: 20px;
  //   left: -10px;
  //   animation: slide 2s infinite;
  //   overflow: hidden;
  // }

  // @keyframes slide {
  //   0%, 100% {
  //     left:-10px;
  //   }
  //   50% {
  //     left: 50%;
  //   }
  // }
`;

const P = styled.p`
  ${PStyle}
`;

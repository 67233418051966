import React, { useEffect } from "react";
import { connect } from "react-redux";
import { RootState } from "../../redux/store";
import reduxPage from "../../redux/reducer/page";
import reduxTeamPage from "../../redux/reducer/teamPages";
import sideMenuBar from "../../redux/reducer/sideMenuBar";
import { TeamPages } from "./teamPages";

export type Page =
  | "home"
  | "team"
  | "leagues"
  | "leaderboards"
  | "league"
  | "lineup"
  | "login"
  | "logout"
  | "register"
  | "gauntlet"
  | "discord"
  | "footer"
  | "economy"
  | "premium"
  | "energy"
  | "scrimmage"
  | "training"
  | "summary"
  | "play-by-play"
  | "manual"
  | "reset-password"
  | "sendPasswordResetLink"
  | "beta-transfer"
  | "privacyPolicy"
  | "cookiePolicy"
  | "termsAndConditions"
  | "disclaimer"
  | "transfers"
  | "checkout-success"
  | "checkout-failed"
  | "settings"
  | "contracts"
  | "landing"
  | "badges";

interface StateProps {}

interface DispatchProps {
  changePage: (p: Page) => void;
  changeTeamPage: (p: TeamPages) => void;
  closeMenuBar: () => void;
}

//Component that wraps all pages, being able to perform actions each time a new page loads
//Functionalities that are common to all pages should live here

const page = (page: Page) => (Comp: React.ComponentType) => {
  const NewComponent = (props: StateProps & DispatchProps) => {
    props.closeMenuBar();

    const { changePage } = props;

    useEffect(() => {
      changePage(page);
    }, [changePage]);

    //change teamPage back to "team" when nativating away from any page that is not team
    if (page !== "team") {
      props.changeTeamPage("team");
    }

    return <Comp />;
  };

  return connect<StateProps, DispatchProps, {}, RootState>(
    (state) => ({ page: state.page }),
    (dispatch) => ({
      changePage: (p: Page) => dispatch(reduxPage.actions.changePage(p)),
      changeTeamPage: (tp: TeamPages) => dispatch(reduxTeamPage.actions.changeTeamPages(tp)),
      closeMenuBar: () => dispatch(sideMenuBar.actions.closeMenuBar()),
    })
  )(NewComponent);
};

export default page;

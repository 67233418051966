import React, { useState, useEffect } from "react";
// material-ui imports
import { Collapse, IconButton, ListItem } from "@material-ui/core";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import List from "@material-ui/core/List";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import MenuIcon from "@material-ui/icons/Menu";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";

//style imports
import styled from "styled-components/macro";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { breakpoint } from "../../util/breakpoint";
import { navBarHeight } from "../lib/styles/navBarHeight";
import CustomListItemText from "./CustomListItemTextMobile";
import CustomListItem from "./CustomListItemMobile";
import { theme } from "../..";
import smallLogo from "../lib/images/smallLogo.png";

//energy bar imports
import Bar from "../lib/energyPage/Bar";

//function from utim
import { endsInS } from "../../util/endsInS";

//redux imports
import { connect } from "react-redux";
import { AppDispatch, RootState } from "../../redux/store";
import { LogInResponse } from "../../api/logIn";

// router-dom imports
import { NavLink as RouterLink, Link } from "react-router-dom";
import sideMenuBar from "../../redux/reducer/sideMenuBar";

// state management (switch pages) imports
import { Page } from "../../components/pages/page";
import { TeamPages } from "../../components/pages/teamPages";

// type imports
import { Team } from "../../api/team";
import UserButton from "./buttons/UserButton";
import CustomListItemPremium from "./CustomListItemPremium";

//styles applied to menu button
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    icon: { color: "white" },
    root: {},
    menuButton: {
      marginLeft: theme.spacing(2),
    },
  })
);

interface StateProps {
  menuBar: boolean;
  userInfo?: LogInResponse;
  page: Page;
  teamPage: TeamPages;
  team: Team | null;
  otherTeamId?: number;
}

interface DispatchProps {
  moveMenuBar: () => void;
  closeMenuBar: () => void;
}

const iconStyle = {
  color: "#FFDC11",
  width: "15px",
};

const chIconStyle = {
  width: "13px",
};

const container = {
  display: "flex",
  flexDirection: "row" as "row",
  alignItems: "center" as "center",
  justifyContent: "center" as "center",
  maxHeight: "17px",
  flex: "1",
};

const containerTwo = {
  display: "flex",
  flexDirection: "row" as "row",
  alignItems: "center" as "center",
  justifyContent: "center" as "center",
  maxHeight: "17px",
  marginLeft: "12px",
  flex: "1",
};

const containerThree = {
  display: "flex",
  flexDirection: "row" as "row",
  alignItems: "center" as "center",
  justifyContent: "center" as "center",
  maxHeight: "17px",
  marginLeft: "12px",
  marginBottom: "5px",
  flex: "1",
};

const textSize = {
  fontSize: "12px",
  //   color: "#9a9da2",
  fontFamily: "LemonMilk",
};

const premiumText = {
  fontSize: "12px",
  color: "#FFDC11",
  fontFamily: "LemonMilk",
};

const containerOne = {
  display: "flex",
  flexDirection: "row" as "row",
  alignItems: "center" as "center",
  justifyContent: "center" as "center",
  overflow: "hidden",
};

function shorthandCoins(amount: number): string {
  // Get the amount as a string and get the length.
  const amountString = `${amount}`;
  const len = amountString.length;

  if (len === 4) {
    // If between 1000 and 10000.
    const afterDecimal = amountString.slice(1, 3).replace(/0+$/, "");
    const suffix = afterDecimal ? `.${afterDecimal}` : "";
    return `${amountString[0]}${suffix}K`;
  } else if (len === 5) {
    // If between 10000 and 100000.
    const suffix = amountString[2] === "0" ? "" : `.${amountString[2]}`;
    return `${amountString.slice(0, 2)}${suffix}K`;
  } else if (len === 6) {
    // If between 100000 and 1000000.
    const suffix = amountString[3] === "0" ? "" : `.${amountString[3]}`;
    return `${amountString.slice(0, 3)}${suffix}K`;
  } else if (len === 7) {
    // If between 1000000 and 10000000.
    const afterDecimal = amountString.slice(1, 3).replace(/0+$/, "");
    const suffix = afterDecimal ? `.${afterDecimal}` : "";
    return `${amountString[0]}${suffix}M`;
  } else if (len === 8) {
    // If between 10000000 and 100000000.
    const suffix = amountString[2] === "0" ? "" : `.${amountString[2]}`;
    return `${amountString.slice(0, 2)}${suffix}M`;
  } else {
    return amountString;
  }
}

function msToTime(duration: number): string {
  let seconds = Math.floor((duration / 1000) % 60).toString(),
    minutes = Math.floor((duration / (1000 * 60)) % 60).toString(),
    hours = Math.floor(duration / (1000 * 60 * 60)).toString();

  hours = parseInt(hours) < 10 ? "0" + hours : hours;
  minutes = parseInt(minutes) < 10 ? "0" + minutes : minutes;
  seconds = parseInt(seconds) < 10 ? "0" + seconds : seconds;

  return hours !== "00"
    ? hours + ":" + minutes + ":" + seconds
    : minutes !== "00" || seconds !== "00"
    ? minutes + ":" + seconds
    : "LIVE";
}

//Main component
const MobileNavBarTwo = (props: StateProps & DispatchProps) => {
  const [gameTime, setGameTime] = useState(5);

  useEffect(() => {
    setTimeout(() => {
      setGameTime(
        props.userInfo
          ? props.userInfo.nextGameTime
            ? props.userInfo.nextGameTime - Date.now()
            : 100000
          : 0
      );
    }, 1000);
  }, [gameTime, props.userInfo]);

  // calls styles applied to menu button
  const classes = useStyles();

  //manages dropdown buttons
  const [openTeam, setOpenTeam] = useState(false);
  //   const [openLeague, setOpenLeague] = useState(false);
  const [openPages, setOpenPages] = useState(false);
  const [openTools, setOpenTools] = useState(false);
  const [openOtherTeam, setOpenOtherTeam] = useState(false);

  //open dropdowns
  const handleClickTeam = (v: boolean) => {
    setOpenTeam(v);
  };

  const handleClickLeague = (v: boolean) => {
    // setOpenLeague(v);
  };

  const handleClickTools = (v: boolean) => {
    setOpenTools(v);
  };

  const handleClickPages = (v: boolean) => {
    setOpenPages(v);
  };

  const handleClickOtherTeam = (v: boolean) => {
    setOpenOtherTeam(v);
  };

  // close dropdown on click away (or when you click any dropdown button)
  const handleClickAway = () => {
    handleClickTeam(false);
    handleClickLeague(false);
    handleClickTools(false);
    handleClickPages(false);
    handleClickOtherTeam(false);
    return props.closeMenuBar();
  };

  //styles applied to dropdown
  const baseStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        width: "70vw",
        padding: 0,
      },
      nested: {
        paddingLeft: theme.spacing(4),
      },
    })
  );

  // calls styles applied to dropdown
  const baseClasses = baseStyles();

  if (!props.userInfo) {
    return <></>;
  }

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      {/* ClickAwayListener only takes 1 child */}
      <div>
        {/* Top bar containing Icon and Logo */}
        <Container>
          <IconButton
            onClick={props.moveMenuBar}
            classes={{ root: classes.icon }}
            className={classes.menuButton}
            aria-label="menu"
          >
            <MenuIcon style={{ fontSize: "32px" }} />
          </IconButton>
          <img src={smallLogo} alt="logo" />
        </Container>
        <div>
          {/* Container holds buttons and slides on Click */}
          <SideNavButtons menuBar={props.menuBar}>
            {/* Main List. All other Lists are inside of this one */}
            <List component="div" className={baseClasses.root}>
              {/* Start of Energy Button */}
              <CustomListItem
                // @ts-ignore https://github.com/mui-org/material-ui/issues/17645
                component={RouterLink}
                selected={props.page === "energy"}
                exact
                to={`/energy`}
                onClick={handleClickAway}
                button
                style={{ height: "60px" }}
              >
                <div style={containerOne}>
                  <div style={container}>
                    <AttachMoneyIcon style={iconStyle}></AttachMoneyIcon>
                    <p style={textSize}>{shorthandCoins(props.userInfo.balance)}&nbsp;</p>
                  </div>
                  <div style={containerTwo}>
                    <p style={premiumText}>
                      {shorthandCoins(props.userInfo.premiumCurrency)}&nbsp;
                    </p>
                    <img alt="" src={smallLogo} style={chIconStyle}></img>
                  </div>
                  <a style={containerThree} href={`/energy`}>
                    <Bar
                      height={"12px"}
                      curr={props.userInfo.energy}
                      max={props.userInfo.premiumStatus === 1 ? 1500 : 500}
                      isOnNav={true}
                      color={theme.orangeTheme}
                      isOnDropdown={false}
                      name={"Energy"}
                    />
                  </a>
                </div>
              </CustomListItem>{" "}
              {/* End of Energy Button */}
              {/* Start of Team Button */}
              <CustomListItem
                button
                selected={props.page === "team" && props.otherTeamId === undefined}
                onClick={() => handleClickTeam(openTeam ? false : true)}
              >
                <CustomListItemText
                  primary={`${endsInS(props.userInfo.teamName)} Team`}
                  selected={props.page === "team" && props.otherTeamId === undefined}
                />
                {openTeam ? <ExpandLess /> : <ExpandMore />}
              </CustomListItem>
              {/* End of Team Button */}
              {/* Start of Team Dropdown */}
              <Collapse
                in={openTeam}
                timeout="auto"
                unmountOnExit
                style={{
                  borderBottom: `6px solid ${theme.orangeTheme}`,
                  width: "70vw",
                }}
              >
                <List disablePadding>
                  <CustomListItem
                    // @ts-ignore https://github.com/mui-org/material-ui/issues/17645
                    component={RouterLink}
                    selected={props.teamPage === "team" && props.otherTeamId === undefined}
                    exact
                    to={`/team/${props.userInfo.teamId}`}
                    onClick={handleClickAway}
                    button
                    isCollapsed={true}
                    style={{ height: "30px" }}
                  >
                    <CustomListItemText
                      selected={props.teamPage === "team" && props.otherTeamId === undefined}
                      primary="Team"
                    />
                  </CustomListItem>

                  <CustomListItem
                    // @ts-ignore
                    component={RouterLink}
                    selected={props.teamPage === "roster" && props.otherTeamId === undefined}
                    exact
                    to={`/team/${props.userInfo.teamId}/roster`}
                    onClick={handleClickAway}
                    button
                    isCollapsed={true}
                    style={{ height: "30px" }}
                  >
                    <CustomListItemText
                      selected={props.teamPage === "roster" && props.otherTeamId === undefined}
                      primary="Roster"
                    />
                  </CustomListItem>

                  <CustomListItem
                    // @ts-ignore
                    component={RouterLink}
                    selected={props.teamPage === "schedule" && props.otherTeamId === undefined}
                    exact
                    to={`/team/${props.userInfo.teamId}/schedule`}
                    onClick={handleClickAway}
                    button
                    isCollapsed={true}
                    style={{ height: "30px" }}
                  >
                    <CustomListItemText
                      selected={props.teamPage === "schedule" && props.otherTeamId === undefined}
                      primary="Schedule"
                    />
                  </CustomListItem>

                  <CustomListItem
                    // @ts-ignore
                    component={RouterLink}
                    selected={props.teamPage === "standings" && props.otherTeamId === undefined}
                    exact
                    to={`/team/${props.userInfo.teamId}/standings`}
                    onClick={handleClickAway}
                    button
                    isCollapsed={true}
                    style={{ height: "30px" }}
                  >
                    <CustomListItemText
                      selected={props.teamPage === "standings" && props.otherTeamId === undefined}
                      primary="Standings"
                    />
                  </CustomListItem>

                  <CustomListItem
                    // @ts-ignore
                    component={RouterLink}
                    selected={props.teamPage === "statistics" && props.otherTeamId === undefined}
                    exact
                    to={`/team/${props.userInfo.teamId}/statistics`}
                    onClick={handleClickAway}
                    button
                    isCollapsed={true}
                    style={{ height: "30px" }}
                  >
                    <CustomListItemText
                      selected={props.teamPage === "statistics" && props.otherTeamId === undefined}
                      primary="Statistics"
                    />
                  </CustomListItem>

                  <CustomListItem
                    // @ts-ignore
                    component={RouterLink}
                    selected={props.teamPage === "contracts" && props.otherTeamId === undefined}
                    exact
                    to={`/team/${props.userInfo.teamId}/contracts`}
                    onClick={handleClickAway}
                    button
                    isCollapsed={true}
                    style={{ height: "30px" }}
                  >
                    <CustomListItemText
                      selected={props.teamPage === "contracts" && props.otherTeamId === undefined}
                      primary="Contracts"
                    />
                  </CustomListItem>

                  <CustomListItem
                    // @ts-ignore
                    component={RouterLink}
                    selected={props.teamPage === "transfers" && props.otherTeamId === undefined}
                    exact
                    to={`/team/${props.userInfo.teamId}/transfers`}
                    onClick={handleClickAway}
                    button
                    isCollapsed={true}
                    style={{ height: "30px" }}
                  >
                    <CustomListItemText
                      selected={props.teamPage === "transfers" && props.otherTeamId === undefined}
                      primary="Transfers"
                    />
                  </CustomListItem>
                </List>
              </Collapse>
              {/* End of Team Dropdown */}
              {/* Start of other team button*/}
              {props.team && props.otherTeamId !== undefined && (
                <>
                  <CustomListItem
                    button
                    selected={props.page === "team" && props.otherTeamId !== undefined}
                    onClick={() => handleClickOtherTeam(openOtherTeam ? false : true)}
                  >
                    <CustomListItemText
                      primary={`${endsInS(props.team.name)} Team`}
                      selected={props.page === "team" && props.otherTeamId !== undefined}
                    />
                    {openOtherTeam ? <ExpandLess /> : <ExpandMore />}
                  </CustomListItem>
                  {/* End of other team button */}
                  {/* Start of other Team Dropdown */}
                  <Collapse
                    in={openOtherTeam}
                    timeout="auto"
                    unmountOnExit
                    style={{
                      borderBottom: `6px solid ${theme.orangeTheme}`,
                      width: "70vw",
                    }}
                  >
                    <List disablePadding>
                      <CustomListItem
                        // @ts-ignore https://github.com/mui-org/material-ui/issues/17645
                        component={RouterLink}
                        selected={props.teamPage === "team"}
                        exact
                        to={`/team/${props.otherTeamId}`}
                        onClick={handleClickAway}
                        button
                        isCollapsed={true}
                        style={{ height: "30px" }}
                      >
                        <CustomListItemText selected={props.teamPage === "team"} primary="Team" />
                      </CustomListItem>

                      <CustomListItem
                        // @ts-ignore
                        component={RouterLink}
                        selected={props.teamPage === "roster"}
                        exact
                        to={`/team/${props.otherTeamId}/roster`}
                        onClick={handleClickAway}
                        button
                        isCollapsed={true}
                        style={{ height: "30px" }}
                      >
                        <CustomListItemText
                          selected={props.teamPage === "roster"}
                          primary="Roster"
                        />
                      </CustomListItem>

                      <CustomListItem
                        // @ts-ignore
                        component={RouterLink}
                        selected={props.teamPage === "schedule"}
                        exact
                        to={`/team/${props.otherTeamId}/schedule`}
                        onClick={handleClickAway}
                        button
                        isCollapsed={true}
                        style={{ height: "30px" }}
                      >
                        <CustomListItemText
                          selected={props.teamPage === "schedule"}
                          primary="Schedule"
                        />
                      </CustomListItem>

                      <CustomListItem
                        // @ts-ignore
                        component={RouterLink}
                        selected={props.teamPage === "standings"}
                        exact
                        to={`/team/${props.otherTeamId}/standings`}
                        onClick={handleClickAway}
                        button
                        isCollapsed={true}
                        style={{ height: "30px" }}
                      >
                        <CustomListItemText
                          selected={props.teamPage === "standings"}
                          primary="Standings"
                        />
                      </CustomListItem>

                      <CustomListItem
                        // @ts-ignore
                        component={RouterLink}
                        selected={props.teamPage === "statistics"}
                        exact
                        to={`/team/${props.otherTeamId}/statistics`}
                        onClick={handleClickAway}
                        button
                        isCollapsed={true}
                        style={{ height: "30px" }}
                      >
                        <CustomListItemText
                          selected={props.teamPage === "statistics"}
                          primary="Statistics"
                        />
                      </CustomListItem>

                      <CustomListItem
                        // @ts-ignore
                        component={RouterLink}
                        selected={props.teamPage === "contracts"}
                        exact
                        to={`/team/${props.otherTeamId}/contracts`}
                        onClick={handleClickAway}
                        button
                        isCollapsed={true}
                        style={{ height: "30px" }}
                      >
                        <CustomListItemText
                          selected={props.teamPage === "contracts"}
                          primary="Contracts"
                        />
                      </CustomListItem>

                      <CustomListItem
                        // @ts-ignore
                        component={RouterLink}
                        selected={props.teamPage === "transfers"}
                        exact
                        to={`/team/${props.otherTeamId}/transfers`}
                        onClick={handleClickAway}
                        button
                        isCollapsed={true}
                        style={{ height: "30px" }}
                      >
                        <CustomListItemText
                          selected={props.teamPage === "transfers"}
                          primary="Transfers"
                        />
                      </CustomListItem>
                    </List>
                  </Collapse>
                  {/* End of other team dropdown */}
                </>
              )}
              {/* End of other team */}
              {/* Start of Tools button */}
              <CustomListItem
                button
                selected={
                  props.page === "training" ||
                  props.page === "lineup" ||
                  props.page === "economy" ||
                  props.page === "energy" ||
                  props.page === "badges" ||
                  props.page === "scrimmage"
                }
                onClick={() => handleClickTools(openTools ? false : true)}
              >
                <CustomListItemText
                  primary="Manage"
                  selected={
                    props.page === "training" ||
                    props.page === "lineup" ||
                    props.page === "economy" ||
                    props.page === "energy" ||
                    props.page === "badges" ||
                    props.page === "scrimmage"
                  }
                />
                {openTools ? <ExpandLess /> : <ExpandMore />}
              </CustomListItem>
              {/* End of Tools button */}
              {/* Start of Tools dropdown */}
              <Collapse
                in={openTools}
                timeout="auto"
                unmountOnExit
                style={{
                  borderBottom: `6px solid ${theme.orangeTheme}`,
                  width: "70vw",
                }}
              >
                <List disablePadding>
                  <CustomListItem
                    // @ts-ignore
                    component={RouterLink}
                    selected={props.page === "economy"}
                    exact
                    to={`/economy`}
                    onClick={handleClickAway}
                    button
                    isCollapsed={true}
                    style={{ height: "30px" }}
                  >
                    <CustomListItemText selected={props.page === "economy"} primary="Economy" />
                  </CustomListItem>

                  <CustomListItem
                    // @ts-ignore
                    component={RouterLink}
                    selected={props.page === "energy"}
                    exact
                    to={`/energy`}
                    onClick={handleClickAway}
                    button
                    isCollapsed={true}
                    style={{ height: "30px" }}
                  >
                    <CustomListItemText selected={props.page === "energy"} primary="Energy" />
                  </CustomListItem>

                  <CustomListItem
                    // @ts-ignore
                    component={RouterLink}
                    selected={props.page === "lineup"}
                    exact
                    to={`/lineup/${props.userInfo.nextGameId}`}
                    onClick={handleClickAway}
                    button
                    isCollapsed={true}
                    style={{ height: "30px" }}
                  >
                    <CustomListItemText selected={props.page === "lineup"} primary="Set Lineup" />
                  </CustomListItem>

                  <CustomListItem
                    // @ts-ignore
                    component={RouterLink}
                    selected={props.page === "scrimmage"}
                    exact
                    to={`/scrimmage`}
                    onClick={handleClickAway}
                    button
                    isCollapsed={true}
                    style={{ height: "30px" }}
                  >
                    <CustomListItemText selected={props.page === "scrimmage"} primary="Scrimmage" />
                  </CustomListItem>

                  <CustomListItem
                    // @ts-ignore https://github.com/mui-org/material-ui/issues/17645
                    component={RouterLink}
                    selected={props.page === "training"}
                    exact
                    to={`/training`}
                    onClick={handleClickAway}
                    button
                    isCollapsed={true}
                    style={{ height: "30px" }}
                  >
                    <CustomListItemText selected={props.page === "training"} primary="Training" />
                  </CustomListItem>

                  <CustomListItem
                    // @ts-ignore https://github.com/mui-org/material-ui/issues/17645
                    component={RouterLink}
                    selected={props.page === "badges"}
                    exact
                    to={`/badges`}
                    onClick={handleClickAway}
                    button
                    isCollapsed={true}
                    style={{ height: "30px" }}
                  >
                    <CustomListItemText selected={props.page === "badges"} primary="Badges" />
                  </CustomListItem>
                </List>
              </Collapse>
              {/* End of Tools dropdown */}
              {/* Start of Pages button */}
              <CustomListItem
                button
                selected={
                  props.page === "gauntlet" ||
                  props.page === "discord" ||
                  props.page === "leagues" ||
                  props.page === "manual" ||
                  props.page === "leaderboards" ||
                  props.page === "transfers"
                }
                onClick={() => handleClickPages(openPages ? false : true)}
              >
                <CustomListItemText
                  primary="Pages"
                  selected={
                    props.page === "gauntlet" ||
                    props.page === "discord" ||
                    props.page === "leagues" ||
                    props.page === "manual" ||
                    props.page === "leaderboards" ||
                    props.page === "transfers"
                  }
                />
                {openPages ? <ExpandLess /> : <ExpandMore />}
              </CustomListItem>
              {/* End of Pages button */}
              {/* Start of Pages dropdown */}
              <Collapse
                in={openPages}
                timeout="auto"
                unmountOnExit
                style={{
                  borderBottom: `6px solid ${theme.orangeTheme}`,
                  width: "70vw",
                }}
              >
                <List disablePadding>
                  <CustomListItem
                    // @ts-ignore
                    component={RouterLink}
                    selected={props.page === "discord"}
                    exact
                    to={{ pathname: "https://discord.com/invite/WX92bvR" }}
                    target="_blank"
                    onClick={handleClickAway}
                    button
                    isCollapsed={true}
                    style={{ height: "30px" }}
                  >
                    <CustomListItemText selected={props.page === "discord"} primary="Discord" />
                  </CustomListItem>

                  <CustomListItem
                    // @ts-ignore
                    component={RouterLink}
                    selected={props.page === "gauntlet"}
                    exact
                    to={`/gauntlet/16`}
                    onClick={handleClickAway}
                    button
                    isCollapsed={true}
                    style={{ height: "30px" }}
                  >
                    <CustomListItemText selected={props.page === "gauntlet"} primary="Gauntlet" />
                  </CustomListItem>

                  <CustomListItem
                    // @ts-ignore
                    component={RouterLink}
                    selected={props.page === "leagues"}
                    exact
                    to={`/leagues`}
                    onClick={handleClickAway}
                    button
                    isCollapsed={true}
                    style={{ height: "30px" }}
                  >
                    <CustomListItemText selected={props.page === "leagues"} primary="Leagues" />
                  </CustomListItem>

                  <CustomListItem
                    // @ts-ignore
                    component={RouterLink}
                    selected={props.page === "leaderboards"}
                    exact
                    to={`/leaderboards`}
                    onClick={handleClickAway}
                    button
                    isCollapsed={true}
                    style={{ height: "30px" }}
                  >
                    <CustomListItemText
                      selected={props.page === "leaderboards"}
                      primary="Leaderboard"
                    />
                  </CustomListItem>

                  <CustomListItem
                    // @ts-ignore https://github.com/mui-org/material-ui/issues/17645
                    component={RouterLink}
                    selected={props.page === "manual"}
                    exact
                    to={`/manual`}
                    onClick={handleClickAway}
                    button
                    isCollapsed={true}
                    style={{ height: "30px" }}
                  >
                    <CustomListItemText selected={props.page === "manual"} primary="Manual" />
                  </CustomListItem>

                  <CustomListItem
                    // @ts-ignore https://github.com/mui-org/material-ui/issues/17645
                    component={RouterLink}
                    selected={props.page === "transfers"}
                    exact
                    to={`/transfers`}
                    onClick={handleClickAway}
                    button
                    isCollapsed={true}
                    style={{ height: "30px" }}
                  >
                    <CustomListItemText selected={props.page === "transfers"} primary="Transfers" />
                  </CustomListItem>
                </List>
              </Collapse>
              {/* End of Pages dropdown */}
              {/* Start of Leagues button */}
              <CustomListItem
                // @ts-ignore https://github.com/mui-org/material-ui/issues/17645
                component={RouterLink}
                selected={props.page === "premium"}
                exact
                to={`/premium`}
                onClick={handleClickAway}
                button
                style={{ height: "60px" }}
              >
                <CustomListItemPremium primary="Premium" />
              </CustomListItem>
              {/* End of Leagues button */}
              {/* Start of Next Game Display */}
              {props.userInfo.nextGameTime ? (
                <div className="next-game">
                  <div className="timer">Next Game In: {msToTime(gameTime)}</div>
                  {gameTime <= 0 ? (
                    <Link
                      to={`/game/${props.userInfo.nextGameId}/play-by-play`}
                      style={{ textDecoration: "none" }}
                    >
                      <div className="live">Watch Live!</div>
                    </Link>
                  ) : props.userInfo.setLineup ? (
                    <Link
                      to={`/lineup/${props.userInfo.nextGameId}`}
                      style={{ textDecoration: "none" }}
                    >
                      <div className="edit-lineup">Edit Your Lineup</div>
                    </Link>
                  ) : (
                    <div>
                      <Link
                        to={`/lineup/${props.userInfo.nextGameId}`}
                        style={{ textDecoration: "none" }}
                      >
                        <div className="set-lineup">Set Your Lineup!</div>
                      </Link>
                      {/* <div className="set-lineup">Set Your Lineup!</div> */}
                    </div>
                  )}
                </div>
              ) : (
                <div className="next-game">
                  <div className="timer">Next Game Not Scheduled</div>
                </div>
              )}
              {/* End of Next Game Display */}
              {/* Start of User button */}
              <ListItem className={"no-gutters"}>
                <UserButton
                  id={props.userInfo.teamId}
                  avatar={props.userInfo.avatar}
                  mobile={true}
                ></UserButton>
              </ListItem>
              {/* End of User button */}
            </List>
          </SideNavButtons>
        </div>
      </div>
    </ClickAwayListener>
  );
};

export default connect<StateProps, DispatchProps, {}, RootState>(
  (state) => ({
    team: state.team.response,
    menuBar: state.sideMenuBar,
    userInfo: state.logIn.data,
    page: state.page,
    teamPage: state.teamPages,
    leagueName: state.logIn.data?.leagueName,
    otherTeamId: state.otherTeamId.id,
  }),
  (dispatch: AppDispatch) => ({
    moveMenuBar: () => dispatch(sideMenuBar.actions.moveMenuBar()),
    closeMenuBar: () => dispatch(sideMenuBar.actions.closeMenuBar()),
  })
)(MobileNavBarTwo);

const Container = styled.div`
  min-height: ${navBarHeight};
  background: ${(props) => props.theme.lightGrayBackground};
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 3;

  img {
    height: 35px;
    margin-right: 28px;
  }
`;

const SideNavButtons = styled.div<{ menuBar: boolean }>`
  display: flex;
  //   flex-direction: column-reverse;
  //   align-items: flex-start;
  //   flex-basis: auto;
  //   justify-content: flex-start;
  //   flex-shrink: 0;
  position: fixed;
  transform: ${(props) => (props.menuBar === true ? "translateX(0vw)" : "translateX(-70vw)")};
  background: ${(props) => props.theme.lightGrayBackground};
  width: 70vw;
  flex-direction: column;
  z-index: 5;
  transition: transform 1s;
  height: calc(100vh - ${navBarHeight});
  margin-top: ${navBarHeight};
  border-right: ${(props) =>
    props.menuBar === true ? `1px solid ${props.theme.inputBorder}` : ``};
  overflow-y: scroll;
  overflow-x: hidden;

  .next-game {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
  }

  .no-gutters {
    padding: 0;
  }

  .timer {
    color: ${(props) => props.theme.grayFont};
  }

  .live {
    color: ${(props) => props.theme.yellowFont};
  }

  .edit-lineup {
    color: ${(props) => props.theme.greenTheme};
  }

  .set-lineup {
    color: ${(props) => props.theme.redTheme};
  }

  @media ${breakpoint.mobile} {
    width: 70vw;
  }
`;

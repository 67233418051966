import React from "react";
import styled from "styled-components/macro";
import { breakpoint } from "../../../util/breakpoint";
import { api } from "../../../api/api";

interface OwnProps {
  title: string;
  price: number;
  period: string;
  savings: number | null;
}

const SubscriptionCard: React.FC<OwnProps> = (props) => {
  const [
    sumbitProcessCheckout,
    { isError, error, data },
  ] = api.endpoints.processCheckout.useMutation();

  if (data && data.url) window.location.href = data.url;

  return (
    <Container>
      <div className="name-container">
        <p className="name">
          {props.title} {`Subscription`}
        </p>
      </div>
      <div className="economy-container">
        <div className="expenses">
          <div className="logo">
            <img className="logo" src={"/images/CHLogoWide.png"} alt="loading..." />
          </div>
          <div className="title">
            ${props.price} {` / ${props.period}`}
          </div>
        </div>
      </div>
      <button
        onClick={() => {
          sumbitProcessCheckout(props.price === 5.99 ? 3 : props.price === 15.99 ? 4 : 5);
        }}
        className="yellow"
        type="submit"
      >
        Buy Now
      </button>
      {props.savings ? (
        <div className="benefit" style={{ color: "gold" }}>
          {`- Save ${props.savings}% compared to the monthly rate`}
        </div>
      ) : (
        <div style={{ height: "15px", margin: "0 0 5px 0" }}></div>
      )}
      {isError && <p>{error}</p>}
    </Container>
  );
};

export default SubscriptionCard;

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: ${(props) => props.theme.energyCardBackground};
  min-height: 240px;
  border-radius: 5px;
  border: solid 0.5px ${(props) => props.theme.tileHeaderBorder};
  align-items: center;
  margin: 20px 20px;

  @media ${breakpoint.mobile} {
    min-height: 350px;
  }

  button {
    width: 70%;
    border: none;
    margin-bottom: 10px;
    font-family: LemonMilk;
    font-size: 12px;
    letter-spacing: 4.8px;
    color: ${(props) => props.theme.whiteFont};
    height: 40px;

    &:hover {
      cursor: pointer;
    }

    &:focus {
      //delete blue border when clicked
      outline: 0;
    }

    &.yellow {
      background-color: green;
      font-family: LemonMilk;
      font-size: 12px;
      border-radius: 6px;
      letter-spacing: 1.8px;

      color: ${(props) => props.theme.whiteFont};
    }
  }

  .benefit {
    font-family: LemonMilkLight;
    font-size: 12px;
    color: white;
    margin-bottom: 5px;
  }

  .logo {
    height: 100px;
    width: 200px;
    object-fit: cover;
    opacity: 1;
    margin-top: 5px;
    margin-bottom: 5px;
    display: flex;
  }

  .name-container {
    width: 100%;
    height: 45px;
    border-radius: 5px 5px 0 0;
    background-color: ${(props) => props.theme.lightGrayBackground};
  }

  .grey-line {
    height: 2px;
    width: 180px;
    background-color: ${(props) => props.theme.lightGrayBackground};
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .title {
    color: gold;
    font-size: 24px;
    font-family: LemonMilk;
    margin-bottom: 10px;
  }

  .bar {
    display: flex;
    flex: 1;
    font-family: LemonMilk;
    color: gold;
  }

  .economy-container {
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    text-align: center;

    @media ${breakpoint.tabletDesktop} {
      flex-direction: column;
    }
  }

  .name {
    font-family: LemonMilk;
    font-size: 12px;
    letter-spacing: 0.94px;
    color: ${(props) => props.theme.whiteFont};
    text-align: center;
  }

  .revenues {
    display: flex;
    flex: 1;
    flex-direction: column;
    font-family: LemonMilklight;
    font-size: 12px;
    letter-spacing: 0.78px;
    color: ${(props) => props.theme.grayFont};
    padding: 5px 10px;
    align-items: center;
    text-align: center;
    justify-content: space-evenly;
  }

  .expenses {
    display: flex;
    flex: 1;
    flex-direction: column;
    font-family: LemonMilklight;
    font-size: 12px;
    letter-spacing: 0.78px;
    color: ${(props) => props.theme.grayFont};
    padding: 5px 10px;
    align-items: center;
    text-align: center;
    justify-content: space-evenly;
  }

  .expenses-chl {
    display: flex;
    flex: 1;
    flex-direction: row;
    font-family: LemonMilklight;
    font-size: 15px;
    letter-spacing: 0.78px;
    color: gold;
    padding: 5px 20px;
    align-items: center;
    text-align: center;
    justify-content: space-evenly;
  }

  .expenses-d1 {
    display: flex;
    flex: 1;
    flex-direction: row;
    font-family: LemonMilklight;
    font-size: 15px;
    letter-spacing: 0.78px;
    color: red;
    padding: 5px 20px;
    align-items: center;
    text-align: center;
    justify-content: space-evenly;
  }

  .children {
    display: flex;
    width: 95%;
    height: 100%;
  }
`;

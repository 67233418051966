import React from "react";
import { ListItemText } from "@material-ui/core";
import styled from "styled-components";
import { theme } from "../..";
import { breakpoint } from "../../util/breakpoint";

export const ListItemMobileMessenger: React.FC<
  React.ComponentProps<typeof ListItemText> & { selected?: boolean }
> = ({ children, ...other }) => {
  return (
    <StyledListItemTextMessenger
      classes={{ primary: "primary", secondary: "secondary" }}
      {...other}
    >
      {children}
    </StyledListItemTextMessenger>
  );
};

const StyledListItemTextMessenger = styled(ListItemText)<{ selected?: boolean }>`
  & .primary {
    font-size: 8px;
    font-family: "LemonMilk";
    letter-spacing: 1px;
    text-align: center;
    color: ${(props) => (props.selected ? `${theme.blackFont}` : `${theme.whiteFont}`)};

    @media ${breakpoint.mobile} {
      font-size: 6px;
    }

    @media ${breakpoint.tablet} {
      font-size: 8px;
    }
  }

  & .secondary {
    font-size: 6px;
    font-family: "LemonMilkLight";
    letter-spacing: 1px;
    text-align: center;
    color: ${(props) => `${theme.grayFont}`};

    @media ${breakpoint.mobile} {
      font-size: 5px;
    }

    @media ${breakpoint.tablet} {
      font-size: 7px;
    }
  }
`;

export default ListItemMobileMessenger;

import { api } from "../axios.config";

export interface RegisterFormWithNumbers {
  teamName: string;
  arenaName: string;
  discordName?: string;
  country: { label: string };
  email: string;
  username: string;
  password: string;
  PGtype: number;
  SGtype: number;
  SFtype: number;
  PFtype: number;
  Ctype: number;
}

export const registerUser = (user: RegisterFormWithNumbers) => {
  return api.request<void>({
    method: "post",
    headers: { "Content-Type": "application/json" },
    url: "/Users",
    data: user,
  });
};

import React, { useEffect, useState } from "react";
import List from "@material-ui/core/List";
import { NavLink } from "react-router-dom";
import CustomListItemText from "../CustomListItemText";
import CustomListItem from "../CustomListItem";
import { baseStyles } from "../baseStyles";
import { Page } from "../../../components/pages/page";
import { connect } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/store";
import page from "../../../redux/reducer/page";
import { ClickAwayListener, Collapse } from "@material-ui/core";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import { theme } from "../../..";

interface OwnProps {
  userId?: number;
  nextGame?: number | null;
}

interface StateProps {
  page: Page;
}

interface DispatchProps {
  changePage: (p: Page) => void;
}

const ToolsButton = (props: StateProps & DispatchProps & OwnProps) => {
  const classes = baseStyles();
  const [openTools, setOpenTools] = useState(false);
  const { page } = props;

  const handleClickTools = (v: boolean) => {
    setOpenTools(v);
  };

  useEffect(() => {}, [page]);

  const handleClick = () => {
    handleClickTools(false);
  };

  const handleClickAway = () => {
    handleClickTools(false);
  };

  if (!props.userId) {
    return <></>;
  }

  if (!props.nextGame) {
    return (
      <ClickAwayListener onClickAway={handleClickAway}>
        <List component="div" className={classes.root}>
          <CustomListItem
            button
            selected={
              props.page === "training" ||
              props.page === "scrimmage" ||
              props.page === "economy" ||
              props.page === "badges" ||
              props.page === "energy"
            }
            onClick={() => handleClickTools(openTools ? false : true)}
          >
            <CustomListItemText
              primary="Manage"
              selected={
                props.page === "training" ||
                props.page === "scrimmage" ||
                props.page === "economy" ||
                props.page === "badges" ||
                props.page === "energy"
              }
            />
            {openTools ? <ExpandLess /> : <ExpandMore />}
          </CustomListItem>
          <Collapse
            in={openTools}
            timeout="auto"
            unmountOnExit
            style={{
              borderBottom: `6px solid ${theme.orangeTheme}`,
              width: "120px",
              position: "absolute",
            }}
          >
            <List disablePadding>
              <CustomListItem
                // @ts-ignore
                component={NavLink}
                selected={props.page === "economy"}
                exact
                to={`/economy`}
                onClick={handleClick}
                button
                iscollapsed={true}
                style={{ height: "30px" }}
              >
                <CustomListItemText selected={props.page === "economy"} primary="Economy" />
              </CustomListItem>

              <CustomListItem
                // @ts-ignore
                component={NavLink}
                selected={props.page === "energy"}
                exact
                to={`/energy`}
                onClick={handleClick}
                button
                iscollapsed={true}
                style={{ height: "30px" }}
              >
                <CustomListItemText selected={props.page === "energy"} primary="Energy" />
              </CustomListItem>

              <CustomListItem
                // @ts-ignore https://github.com/mui-org/material-ui/issues/17645
                component={NavLink}
                selected={props.page === "scrimmage"}
                exact
                to={`/scrimmage`}
                onClick={handleClick}
                button
                iscollapsed={true}
                style={{ height: "30px" }}
              >
                <CustomListItemText selected={props.page === "scrimmage"} primary="Scrimmage" />
              </CustomListItem>

              <CustomListItem
                // @ts-ignore https://github.com/mui-org/material-ui/issues/17645
                component={NavLink}
                selected={props.page === "training"}
                exact
                to={`/training`}
                onClick={handleClick}
                button
                iscollapsed={true}
                style={{ height: "30px" }}
              >
                <CustomListItemText selected={props.page === "training"} primary="Training" />
              </CustomListItem>

              <CustomListItem
                // @ts-ignore https://github.com/mui-org/material-ui/issues/17645
                component={NavLink}
                selected={props.page === "badges"}
                exact
                to={`/badges`}
                onClick={handleClick}
                button
                iscollapsed={true}
                style={{ height: "30px" }}
              >
                <CustomListItemText selected={props.page === "badges"} primary="Badges" />
              </CustomListItem>
            </List>
          </Collapse>
        </List>
      </ClickAwayListener>
    );
  }

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <List component="div" className={classes.root}>
        <CustomListItem
          button
          selected={
            props.page === "training" ||
            props.page === "scrimmage" ||
            props.page === "lineup" ||
            props.page === "economy" ||
            props.page === "energy"
          }
          onClick={() => handleClickTools(openTools ? false : true)}
        >
          <CustomListItemText
            primary="Manage"
            selected={
              props.page === "training" ||
              props.page === "scrimmage" ||
              props.page === "lineup" ||
              props.page === "economy" ||
              props.page === "energy"
            }
          />
          {openTools ? <ExpandLess /> : <ExpandMore />}
        </CustomListItem>
        <Collapse
          in={openTools}
          timeout="auto"
          unmountOnExit
          style={{
            borderBottom: `6px solid ${theme.orangeTheme}`,
            width: "120px",
            position: "absolute",
          }}
        >
          <List disablePadding>
            <CustomListItem
              // @ts-ignore
              component={NavLink}
              selected={props.page === "economy"}
              exact
              to={`/economy`}
              onClick={handleClick}
              button
              iscollapsed={true}
              style={{ height: "30px" }}
            >
              <CustomListItemText selected={props.page === "economy"} primary="Economy" />
            </CustomListItem>

            <CustomListItem
              // @ts-ignore
              component={NavLink}
              selected={props.page === "energy"}
              exact
              to={`/energy`}
              onClick={handleClick}
              button
              iscollapsed={true}
              style={{ height: "30px" }}
            >
              <CustomListItemText selected={props.page === "energy"} primary="Energy" />
            </CustomListItem>

            <CustomListItem
              // @ts-ignore https://github.com/mui-org/material-ui/issues/17645
              component={NavLink}
              selected={props.page === "scrimmage"}
              exact
              to={`/scrimmage`}
              onClick={handleClick}
              button
              iscollapsed={true}
              style={{ height: "30px" }}
            >
              <CustomListItemText selected={props.page === "scrimmage"} primary="Scrimmage" />
            </CustomListItem>

            <CustomListItem
              // @ts-ignore
              component={NavLink}
              selected={props.page === "lineup"}
              exact
              to={`/lineup/${props.nextGame}`}
              onClick={handleClick}
              button
              iscollapsed={true}
              style={{ height: "30px" }}
            >
              <CustomListItemText selected={props.page === "lineup"} primary="Set Lineup" />
            </CustomListItem>

            <CustomListItem
              // @ts-ignore https://github.com/mui-org/material-ui/issues/17645
              component={NavLink}
              selected={props.page === "training"}
              exact
              to={`/training`}
              onClick={handleClick}
              button
              iscollapsed={true}
              style={{ height: "30px" }}
            >
              <CustomListItemText selected={props.page === "training"} primary="Training" />
            </CustomListItem>

            <CustomListItem
              // @ts-ignore https://github.com/mui-org/material-ui/issues/17645
              component={NavLink}
              selected={props.page === "badges"}
              exact
              to={`/badges`}
              onClick={handleClick}
              button
              iscollapsed={true}
              style={{ height: "30px" }}
            >
              <CustomListItemText selected={props.page === "badges"} primary="Badges" />
            </CustomListItem>
          </List>
        </Collapse>
      </List>
    </ClickAwayListener>
  );
};

export default connect<StateProps, DispatchProps, {}, RootState>(
  (state) => ({
    page: state.page,
  }),
  (dispatch: AppDispatch) => ({
    changePage: (p: Page) => dispatch(page.actions.changePage(p)),
  })
)(ToolsButton);

import React from "react";
import { ListItem, makeStyles } from "@material-ui/core";
import { theme } from "../..";
import { navBarHeight } from "../lib/styles/navBarHeight";

/* Styles */
// Mui[component name]-[style rule name]-[UUID]

// MuiListItem-root
// component name = ListItem
// style rule name = root
const useListItemStyles = (isCollapesed: boolean) =>
  makeStyles({
    root: {
      backgroundColor: isCollapesed
        ? `${theme.dropdownBackground}`
        : `${theme.lightGrayBackground}`,
      // borderBottom: isCollapesed
      //   ? `1px solid ${theme.inputBorder}`
      //   : `2px solid ${theme.lightGrayBackground}`,
      borderTop: `1px solid ${theme.inputBorder}`,
      borderLeft: `4px solid ${theme.lightGrayBackground}`,

      // https://github.com/mui-org/material-ui/issues/13672
      "&.Mui-selected": {
        backgroundColor: isCollapesed
          ? `${theme.dropdownBackground}`
          : `${theme.lightGrayBackground}`,
        borderLeft: `4px solid ${theme.yellowFont}`,

        "&:hover": {
          backgroundColor: `${theme.lightGrayBackground}`,
        },
      },
    },
    button: {
      height: `${navBarHeight}`,
      color: `${theme.whiteFont}`,
      "&:hover": {
        backgroundColor: `${theme.lightGrayBackground}`,
      },
    },
  });

type CustomListItemProps = React.ComponentProps<typeof ListItem> & { iscollapsed?: boolean };

const CustomListItem: React.FC<CustomListItemProps> = ({ children, ...other }) => {
  const classes = useListItemStyles(!!other.iscollapsed)();

  return (
    <ListItem classes={classes} {...other}>
      {children}
    </ListItem>
  );
};

export default CustomListItem;

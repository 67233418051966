import React, { useEffect } from "react";
import styled from "styled-components/macro";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import { connect } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/store";
import { listModal } from "../../../redux/reducer/modal";
import { FetchStatus } from "../../../redux/fetchStatus";
import { ListFail } from "../PlayerPage/NameChangeFail";
import { buttonStyles, useStyles, Fade } from "../energyPage/modalExports";
import listPlayer, { listPlayerAction } from "../../../redux/reducer/listPlayer";
import { yupResolver } from "@hookform/resolvers";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { refreshPage } from "../../../util/refreshPage";
import {
  IndividualPlayerWithExtraAttributes,
  RosterPlayerWithExtraAttributes,
} from "../../../api/roster";
import { conversion } from "../../../util/avatarConversion";
import { breakpoint } from "../../../util/breakpoint";

interface OwnProps {
  id: number;
  name: string;
  player: IndividualPlayerWithExtraAttributes | RosterPlayerWithExtraAttributes;
}

interface StateProps {
  modalOpen: boolean;
  openPlayerId: number;
  fetchStatus: FetchStatus;
}

interface DispatchProps {
  open: () => void;
  close: () => void;
  submit: (id: number, amount: number) => void;
  reset: () => void;
}

const schema = yup.object().shape({
  amount: yup
    .string()
    .required()
    .matches(/^[0-9]*$/)
    .min(4)
    .max(9)
    .trim(),
});

type Page = "main" | "fail";

function ListModal(props: OwnProps & StateProps & DispatchProps) {
  const classes = useStyles();
  const [page, setPage] = React.useState("main" as Page);
  const { fetchStatus, id, close } = props;

  const handleClose = () => {
    props.close();
  };

  useEffect(() => {
    if (fetchStatus === "success") {
      close();
      refreshPage();
    }

    if (fetchStatus === "failed") {
      setPage("fail");
    }
  }, [fetchStatus, id, close]);

  const handleErrorOnClose = () => {
    props.close();
    props.reset();
    setTimeout(() => setPage("main"), 600);
  };

  const pageToDisplay = {
    main: (
      <Main
        submit={(amount) => props.submit(props.id, amount)}
        onNo={() => props.close()}
        id={props.id}
        name={props.name}
        player={props.player}
      ></Main>
    ),
    fail: <ListFail onClose={() => handleErrorOnClose()} button={buttonStyles}></ListFail>,
  }[page];

  return (
    <div>
      <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        className={classes.modal}
        open={props.modalOpen && props.openPlayerId === props.player.playerId}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={props.modalOpen && props.openPlayerId === props.player.playerId}>
          <div className={classes.paper}>{pageToDisplay}</div>
        </Fade>
      </Modal>
    </div>
  );
}

interface MainProps {
  submit: (name: number) => void;
  onNo: () => void;
  id: number;
  name: string;
  player: IndividualPlayerWithExtraAttributes | RosterPlayerWithExtraAttributes;
}

export interface NameForm {
  amount: number;
}

const Warning = styled.p`
  padding: 0;
  margin: 0;
  color: red;
  font-size: 13px;
  position: relative;
  transform: translate(25px, -18px);
  height: 0;
`;

const Main = (props: MainProps) => {
  const { register, handleSubmit, errors } = useForm<NameForm>({
    resolver: yupResolver(schema),
  });

  return (
    <Container>
      <h2 className="spring-modal-title">Listing {props.name}?</h2>
      <div className="photo-name">
        <div>{conversion(props.player)}</div>
      </div>
      <form
        className="vertical"
        onSubmit={handleSubmit((f: { amount: string }) => {
          props.submit(parseInt(f.amount));
        })}
      >
        <div className="playerInput">
          <input
            onInput={(e) => {
              const target: HTMLInputElement = e.target as HTMLInputElement;
              if (target.value.length >= 30) target.value = target.value.slice(0, 30);
            }}
            type="text"
            name="amount"
            placeholder="Enter List Amount Here (only numbers)"
            ref={register}
          />
          <Warning className="warnings">
            {
              {
                required: "* You must enter a list (No Commas)",
                max: "Cannot be more than $9,999,999",
                min: "Cannot be less than $1,000",
                matches: "Must be a number",
                noError: "",
              }[(errors?.amount?.type as "required" | "max" | "min" | undefined) || "noError"]
            }
          </Warning>{" "}
        </div>
        <div className="horizontal">
          <button type="submit" className="yellow">
            List Now
          </button>
        </div>
        <div style={{ color: "red", marginTop: "5px" }}>
          <div>*A {props.player.tax}% Fee will be deducted for the transaction</div>
        </div>
      </form>
    </Container>
  );
};

export default connect<StateProps, DispatchProps, OwnProps, RootState>(
  (state) => ({
    modalOpen: state.listModal.isOpen,
    openPlayerId: state.listModal.playerId,
    fetchStatus: state.listPlayer.fetchStatus,
  }),
  (dispatch: AppDispatch) => ({
    reset: () => dispatch(listPlayer.actions.reset()),
    open: () => {
      dispatch(listModal.actions.open());
    },
    close: () => {
      dispatch(listModal.actions.close());
      dispatch(listModal.actions.setPlayerId(0));
    },
    submit: (id, amount) => dispatch(listPlayerAction({ id: id, amount: amount })),
  })
)(ListModal);

const Container = styled.div`
  .vertical {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }

  .auction-details {
    font-family: LemonMilklight;
    width: 100%;
    height: 70px;
    font-size: 12px;
    color: ${(props) => props.theme.whiteFont};
    letter-spacing: 0.8px;
    text-align: center;
    margin-right: 60px;
    margin-top: auto;
    margin-bottom: auto;
  }

  .spring-modal-title{
      text-align: center;
  }

  .yellow {
    background-color: green;
    font-family: LemonMilk;
    font-size: 12px;
    border-radius: 6px;
    letter-spacing: 1.8px;
    width: 300px;
    height: 50px;
    color: ${(props) => props.theme.whiteFont};
    border-width: 0px;
    cursor: pointer;
  }

  .photo-name {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    margin: 20px 0 0px 0;

    @media ${breakpoint.mobileTablet} {
      margin: 0 0 10px 0;
    }
  }

  .player-photo-container {
    height: 220px;
    width: 220px;
    background-color: ${(props) => props.theme.mediumGrayBackground};
    border: solid 0.5px ${(props) => props.theme.tileHeaderBorder};
    margin: 10px 0 20px 0;
    position: relative;
  }

  .player-image {
    position:absolute;
    top: 0px;
    left: 0px;
    width="220" 
    height="220"
  }

  .playerInput {
    flex: 1;
    height: 60px;
  }

  .warnings {
    margin-top: 25px;
    font-size: 10px;
  }

  .horizontal {
    padding-left: auto;
    margin-top: 15px;
    position: relative;
    align-items: right;
    justify-content: right;
    float: right;
  }

  input {
    border: solid 0.5px ${(props) => props.theme.inputBorder};
    background-color: ${(props) => props.theme.lightGrayBackground};
    font-size: 12px;
    font-family: fa-light;
    margin-top: 10px;
    border-radius: 6px;
    width: 290px;
    height: 50px;
    padding: 0 0 0 10px;
    display: flex;
    color: ${(props) => props.theme.grayFont};

    ::placeholder {
      font-family: LemonMilk;
      font-size: 12px;
    }

    :focus {
      //delete blue border when clicked
      outline: none;
    }
  }
`;

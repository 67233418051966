import { Option } from "../util/countryToFlag";
import { api } from "../axios.config";

export const fetchTeam = (id: number) => {
  return api.get<Team>(`/Users/${id}`);
};

export interface Award {
  id: number;
  name: string;
  rarity: number;
  description: string;
  awardType: number;
  seasonId: number;
}

export interface Game {
  id: number;
  teamScore: number;
  opponentsScore: number;
  date: number;
  opponentsTeam: number;
  opponentsTeamName: string;
  userWins: number;
  userLosses: number;
  opponentsWins: number;
  opponentsLosses: number;
  homeAvatar: string;
  awayAvatar: string;
  gameType: number;
  status: number;
}

export interface Team {
  username: string;
  dateJoined: number;
  lastLoggedIn: number;
  premiumStatus: number;
  userId: number;
  leagueName: string | undefined;
  teamId: number;
  name: string;
  country: Option["code"] | Option["label"];
  globalRank: number;
  leagueRank: number | undefined;
  conference: number | undefined;
  division: number | undefined;
  seasonId: number | undefined;
  leagueId: number | undefined;
  wins: number;
  losses: number;
  online: boolean;
  games: Game[];
  awards: Award[];
}

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Training } from "../../api/training";
import { MyKnownError } from "../myKnownError";
import { defaultState } from "../util/defaultState";

const trainingSlice = createSlice({
  name: "training",
  initialState: defaultState<Training>(),
  reducers: {
    getTrainingLoading: (state) => {
      state.fetchStatus = "pending";
      return state;
    },
    getTrainingSuccess: (state, action: PayloadAction<Training>) => {
      state.fetchStatus = "success";
      state.data = action.payload;
      return state;
    },
    getTrainingFailed: (state, action: PayloadAction<MyKnownError>) => {
      state.fetchStatus = "failed";
      state.error = action.payload;
      return state;
    },
    getTrainingReset: (state) => {
      state = defaultState();
      return state;
    },
  },
});

export const {
  getTrainingLoading,
  getTrainingSuccess,
  getTrainingFailed,
  getTrainingReset,
} = trainingSlice.actions;
export default trainingSlice.reducer;

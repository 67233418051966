import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LiveGame, LiveTactics } from "../../api/game";
import { RecursivePartial } from "../../util/recursiveParital";
import { defaultState } from "../util/defaultState";
import merge from "lodash/merge";

interface LiveGameInfo {
  game: LiveGame;
  home_tactic: LiveTactics;
  away_tactic: LiveTactics;
}

const liveGameSlice = createSlice({
  name: "liveGame",
  initialState: defaultState<LiveGameInfo>(),
  reducers: {
    updateGame: (state, action: PayloadAction<RecursivePartial<LiveGameInfo> | LiveGameInfo>) => {
      if (state.fetchStatus === "success") {
        merge(state.data, action.payload);
      } else {
        state.data = action.payload as any;
        state.fetchStatus = "success";
      }
    },
  },
});

export const { updateGame } = liveGameSlice.actions;
export default liveGameSlice.reducer;

import React from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components/macro";
import { Change, SkillLevel, Specialty } from "../../../api/roster";
import { CardContainerStyle, PStyle } from "../styles/skillCardStyles";
import SkillLine from "./SkillLine";

interface OwnProps {
  id: number;
  longRangeShooting?: SkillLevel;
  handling?: SkillLevel;
  passing?: SkillLevel;
  midRangeShooting?: SkillLevel;
  insideShooting?: SkillLevel;
  driving?: SkillLevel;
  finishing?: SkillLevel;
  lineSize: "sml" | "lrg";
  changes: Change[];
  specialties: Specialty[];
}

const lineSizes = {
  sml: 35,
  lrg: 55,
};

const OffensiveSkillsCard = (props: OwnProps) => {
  const height = lineSizes[props.lineSize];
  const offensiveSpecialties = props.specialties.slice(0, 7);

  const OffsensiveSkillLine = (props: {
    name: string;
    skill?: SkillLevel;
    change: Change | undefined;
  }) => {
    return props.skill ? (
      <SkillLine
        name={props.name}
        change={props.change}
        skill={props.skill}
        number={props.skill}
        height={height}
      />
    ) : (
      <SkillLine name={props.name} change={props.change} skill={0} number="-" height={height} />
    );
  };

  return (
    <CardContainer>
      <div className="badge-container">
        <P>Offensive Skills</P>
        <NavLink to={`/badges/${props.id}`} className="badge-container">
          <div className="bronze-badge">
            {props.longRangeShooting
              ? offensiveSpecialties.filter((e) => e.level === 1).length
              : "?"}
          </div>
          <div className="silver-badge">
            {props.longRangeShooting
              ? offensiveSpecialties.filter((e) => e.level === 2).length
              : "?"}
          </div>
          <div className="gold-badge">
            {props.longRangeShooting
              ? offensiveSpecialties.filter((e) => e.level === 3).length
              : "?"}
          </div>
        </NavLink>
      </div>
      <OffsensiveSkillLine
        name="Outside Shot"
        skill={props.longRangeShooting}
        change={props.changes.find((e) => e.stat === "Outside Shot")}
      />
      <OffsensiveSkillLine
        name="Ball Handling"
        skill={props.handling}
        change={props.changes.find((e) => e.stat === "Ball Handling")}
      />
      <OffsensiveSkillLine
        name="Passing"
        skill={props.passing}
        change={props.changes.find((e) => e.stat === "Passing")}
      />
      <OffsensiveSkillLine
        name="Mid-Range"
        skill={props.midRangeShooting}
        change={props.changes.find((e) => e.stat === "Mid-Range")}
      />
      <OffsensiveSkillLine
        name="Inside Shot"
        skill={props.insideShooting}
        change={props.changes.find((e) => e.stat === "Inside Shot")}
      />
      <OffsensiveSkillLine
        name="Driving"
        skill={props.driving}
        change={props.changes.find((e) => e.stat === "Driving")}
      />
      <OffsensiveSkillLine
        name="Finishing"
        skill={props.finishing}
        change={props.changes.find((e) => e.stat === "Finishing")}
      />
    </CardContainer>
  );
};

export default OffensiveSkillsCard;

const CardContainer = styled.div`
  ${CardContainerStyle}
  margin-left: 12px;

  .badge-container {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin-top: 5px;
    text-decoration: none;
    color: black;
  }

  .gold-badge,
  .bronze-badge,
  .silver-badge {
    background: url(../../../images/badges/GoldBadgeSmall.png);
    height: 33px;
    width: 30px;
    background-size: 30px;
    margin-bottom: 0px !important;
    font-size: 20px !important;
    text-align: center;
    vertical-align: middle;
    line-height: 30px;
    font-family: LemonMilkLight;
    position: relative;
    overflow: hidden;
    margin: 0 4px 0 4px;
  }

  .bronze-badge {
    background: url(../../../images/badges/BronzeBadgeSmall.png);
    background-size: 30px;
  }
  .silver-badge {
    background: url(../../../images/badges/SilverBadgeSmall.png);
    background-size: 30px;
  }
`;

const P = styled.p`
  ${PStyle}
`;

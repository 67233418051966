import React, { useState } from "react";
import styled from "styled-components/macro";
import { Potential, InjuryStatus, Attributes, Race, AuctionInfo } from "../../../api/roster";
import { theme } from "../../..";
import { breakpoint } from "../../../util/breakpoint";
import BasicInfo from "./BasicInfo";
import AdvancedInfo from "./AdvancedInfo";
import { Link, NavLink } from "react-router-dom";
import FavoriteIcon from "@material-ui/icons/Favorite";
// import { isInjured } from "../../../util/isInjured";
import { conversion } from "../../../util/rosterAvatarConversion";
import { countryToCodeSvg } from "../../../util/countryToFlag";
import { checkGoat } from "../../../util/checkGoat";
import StarIcon from "@material-ui/icons/Star";
import { checkBot } from "../../../util/checkBot";
import LaptopMacIcon from "@material-ui/icons/LaptopMac";

interface OwnProps {
  id: number;
  teamId: number;
  teamName: string;
  firstName: string;
  lastName: string;
  country: string;
  height: number;
  weight: number;
  potential: Potential;
  age: number;
  aggressiveness: Attributes;
  motivation: Attributes;
  longevity: Attributes;
  healthHistory: Attributes;
  seasonDrafted: number;
  injuryStatus: InjuryStatus;
  playerId: number;
  race: Race;
  face: number;
  eyes: number;
  eyebrows: number;
  haircut: number;
  facialHair: number;
  nose: number;
  mouth: number;
  jersey: number;
  tattoo: number;
  accessory: number;
  ears: number;
  offensiveStars?: number;
  defensiveStars?: number;
  intangibleStars?: number;
  totalStars?: number;
  salary: number;
  duration: number;
  auctions: AuctionInfo[];
  myId: number;
  premiumStatus?: boolean;
  units: number;
}

type InfoToShow = "basic" | "advanced";

// interface DispatchProps {
//   reset: () => void;
// }

const PlayerStats = (props: OwnProps) => {
  const [infoToShow, setInfoToShow] = useState("basic" as InfoToShow);

  const handleClick = (info: InfoToShow) => {
    setInfoToShow(info);
  };
  const iconStyle = {
    fontSize: "20px",
    color: `${props.injuryStatus === 0 ? theme.greenTheme : theme.orangeTheme}`,
  };

  const internationalNumberFormat = new Intl.NumberFormat("en-US");
  const salary = internationalNumberFormat.format(props.salary);

  return (
    <Container>
      <div className="player-photo-and-name-container">
        <p className="player-health">
          <div className="player-health-country">
            <div className="country">
              <React.Fragment>
                <img
                  className="country"
                  src={`/images/svg/${countryToCodeSvg(props.country)}`}
                  alt="Court"
                  width="24"
                  height="16"
                ></img>
              </React.Fragment>
            </div>
            <div className="health">
              <FavoriteIcon style={iconStyle} />
            </div>
          </div>
        </p>
        <div className="photo-name">
          <div>{conversion(props)}</div>
          <div className="player-name-position">
            <h3 style={{ display: "flex" }}>
              <Link to={`player/${props.id}`} className="colorWhite">
                {props.firstName} {props.lastName}
              </Link>
            </h3>
            {props.teamId === 100 ? (
              <div className="team-name">{"Free Agent"}</div>
            ) : props.teamId === 101 ? (
              <div className="team-name">{"Retired"}</div>
            ) : (
              <NavLink
                className={checkGoat(props.teamName) ? "rainbow_text_animated" : "team-name"}
                to={`/team/${props.teamId}`}
              >
                {`${props.teamName}`}
                {props.premiumStatus ? <StarIcon className="svg-inline"></StarIcon> : <></>}
                {checkBot(props.teamId) ? (
                  <LaptopMacIcon className="svg-inline"></LaptopMacIcon>
                ) : (
                  <></>
                )}
              </NavLink>
            )}
            <div className="contract">
              Contract: {salary} for {props.duration} {props.duration === 1 ? "season" : "seasons"}
            </div>
          </div>
        </div>
      </div>

      <div className="player-stats-buttons">
        <button
          className={infoToShow === "basic" ? "active" : ""}
          onClick={() => handleClick("basic")}
          style={{ borderRight: `0.5 solid ${theme.tileHeaderBorder}` }}
        >
          Basic Info
        </button>
        <button
          className={infoToShow === "advanced" ? "active" : ""}
          onClick={() => handleClick("advanced")}
        >
          Advanced Info
        </button>
      </div>

      {infoToShow === "basic" ? (
        <BasicInfo {...props}></BasicInfo>
      ) : (
        <AdvancedInfo {...props}></AdvancedInfo>
      )}
      <Link to={`player/${props.id}`} className="button-orange">
        View All
      </Link>
    </Container>
  );
};

export default PlayerStats;

const Container = styled.div`
  border-radius: 5px;
  border: solid 0.5px ${(props) => props.theme.tileHeaderBorder};
  background-color: ${(props) => props.theme.loginBackground};
  width: 375px;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 5px 20px 1px;

  .svg-inline {
    font-size: 13px;
    color: gold;
    display: inline;
    position: relative;
    text-align: center;
    top: 0.08em;
    width: 19px;
  }

  @media ${breakpoint.mobile} {
    width: 90%;
  }

  @media ${breakpoint.tablet} {
    width: 500px;
  }

  .team-name {
    display: flex;
    font-family: LemonMilklight;
    font-size: 12px;
    color: ${(props) => props.theme.grayFont};
  }

  .player-photo-and-name-container {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
    // margin: 20px 0;

    @media ${breakpoint.mobileTablet} {
      // flex-direction: column;
    }
  }

  .player-health-country {
    display: flex;
    flex-direction: row;
    width: 375px;
    height: 40px;
    justify-content: space-between;
    margin-top: 1px;
    position: absolute;

    @media ${breakpoint.tablet} {
      width: 500px;
    }

    @media ${breakpoint.mobileTablet} {
      position: relative;
      width: 100%;
      margin-top: 0px;
      height: 20px;
    }

    .country {
      font-size: 20px;
      margin-left: 3px;
      align-items: top;
      height: 16px;
      width: 24px;
    }
  }

  .colorWhite {
    color: white;
    text-decoration: none;
  }

  .player-photo-container {
    height: 80px;
    width: 80px;
    border-radius: 50%;
    background-color: ${(props) => props.theme.mediumGrayBackground};
    margin-left: 20px;
    overflow: hidden;
    position: relative;
    margin-bottom: 10px;
  }

  .player-image {
    position: absolute;
    top: 0px;
    left: 0px;
    object-fit: fill;
    transform: scale(1.5); /* (150% zoom)*/
    object-position: 0px 12px;
    width: 82px;
    height: 82px;
    padding-bottom: 30px;
  }

  .health {
    margin-right: 7px;
    margin-top: 4px;
  }

  .contract {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 20px;
    margin-bottom: 5px;
    width: 98%;
    font-family: LemonMilklight;
    font-size: 12px;
    color: ${(props) => props.theme.yellowFont};
  }

  .player-name-position {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 100px;
    width: 100%;
    margin-left: 20px;
    @media ${breakpoint.mobileTablet} {
      align-self: flex-start;
      margin: 5px 0 0 20px;
    }

    h3 {
      margin: 0;
      padding: 0;
      font-family: LemonMilk;
      font-size: 15px;
      letter-spacing: 0.75px;
      color: ${(props) => props.theme.whiteFont};
    }

    p {
      margin: 0;
      padding: 0;
      font-family: LemonMilklight;
      font-size: 12px;
      font-weight: 300;
      letter-spacing: 0.6px;
      color: ${(props) => props.theme.grayFont};
    }
  }

  .player-stats-buttons {
    display: flex;
    width: 100%;
    height: 40px;
    margin-bottom: 20px;

    .active {
      border-bottom: 2px solid ${(props) => props.theme.yellowFont};
    }

    button {
      flex: 1;
      border-top: 0.5px solid ${(props) => props.theme.tileHeaderBorder};
      border-bottom: 0.5px solid ${(props) => props.theme.tileHeaderBorder};
      border-left: none;
      border-right: none;
      background-color: ${(props) => props.theme.tileHeaderBackground};
      font-family: LemonMilk;
      font-size: 12px;
      letter-spacing: 0.94px;
      color: ${(props) => props.theme.whiteFont};

      :focus {
        //delete blue border when clicked
        outline: none;
      }
    }
  }

  .button-orange {
    height: 45px;
    border-radius: 3px;
    border: solid 0.5px ${(props) => props.theme.inputBorder};
    background-color: ${(props) => props.theme.orangeTheme};
    font-family: LemonMilklight;
    font-size: 12px;
    color: ${(props) => props.theme.whiteFont};
    width: 95%;
    align-self: center;
    margin: 10px 0 16px 0;
    text-align: center;
    text-decoration: none;
    line-height: 45px;
  }

  .player-health {
    font-family: LemonMilk;
    letter-spacing: 0.78px;
    color: ${(props) => props.theme.whiteFont};
    margin: 10px 10px 0 0;
    margin: 0;
  }

  .photo-name {
    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;
    margin: 20px 0 0px 0;

    @media ${breakpoint.mobileTablet} {
      margin: 0 0 10px 0;
    }
  }

  #shadowBox {
    background-color: ${(props) => props.theme.loginBackground};
  }

  .rainbow_text_animated {
    background: linear-gradient(
      45deg,
      #ff2400,
      #e81d1d,
      #e8b71d,
      #e3e81d,
      #1de840,
      #1ddde8,
      #2b1de8,
      #dd00f3,
      #dd00f3
    );
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    animation: rainbow 6s ease infinite;
    background-size: 400% 400%;
    font-family: LemonMilklight;
    font-size: 12px;
  }

  @keyframes rainbow {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 25%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
`;

import React, { useEffect } from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { AppDispatch, RootState } from "../../redux/store";
import { SelectLogIn } from "../../redux/selector/logInSelector";
import { getInfo } from "../../redux/thunk/infoThunk";

interface StateProps {
  isAuth: boolean;
  hasTried: boolean;
}

interface DispatchProps {
  getInfo: () => void;
}

//PrivateRoute acts just like the regular Route, with some extra functionality
// Each time that a component wrapped in a private route is called, it checks if that user still has valid authentication
// If yes, load the page
// If not, Redirect to Login
const PrivateRoute: React.FC<React.ComponentProps<typeof Route> & StateProps & DispatchProps> = ({
  isAuth,
  hasTried,
  getInfo,
  // Contains all props that Route has
  ...rest
}) => {
  useEffect(() => {
    if (!hasTried) {
      //When page loads, it calls get Info
      getInfo();
    }
    // eslint-disable-next-line
  }, [hasTried]);

  return hasTried ? isAuth ? <Route {...rest} /> : <Redirect to="/login" /> : <></>;
};

const mapStateToProps = (state: RootState): StateProps => ({
  //checks if redux has the user data
  isAuth: !!SelectLogIn.data(state),
  //checks if user has tried to login
  hasTried: SelectLogIn.hasTried(state),
});

const mapDispatchToProps = (dispatch: AppDispatch): DispatchProps => ({
  getInfo: () => dispatch(getInfo()),
});

export default connect<StateProps, DispatchProps, {}, RootState>(
  mapStateToProps,
  mapDispatchToProps
)(PrivateRoute);

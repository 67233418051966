import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { Tournament, fetchTournaments, Tournaments } from "../../api/tournaments";
import { winnersBracket } from "../../util/winnersBracket";
import { orderGames } from "../../util/losersBracket";
import { toTree } from "../../util/toTree";
import { Tree } from "tournament-bracket-tree";
import { AsyncThunkError } from "../asyncThunkError";
import { MyKnownError } from "../myKnownError";
import { FetchStatus } from "../fetchStatus";

export interface TournamentTree {
  winnersBracket: Tree<Tournament>;
  losersBracket: Tree<Tournament>;
  winnersBracketLength: number;
  losersBracketLength: number;
  teamId: number;
  teamStatus: GauntletStatus;
}

interface State {
  fetchStatus: FetchStatus;
  response: TournamentTree | null;
  error: MyKnownError | null;
}

export interface GauntletStatus {
  losses: number;
  nextGameDate: number | null;
}

export const fetchTournamentsAction = createAsyncThunk<
  Tournaments,
  { id: number },
  AsyncThunkError<MyKnownError>
>("Tournaments", async (props, thunkApi) => {
  try {
    const response = await fetchTournaments(props.id);
    return response.data;
  } catch (err) {
    if (err?.response?.status === 400) {
      return thunkApi.rejectWithValue("400");
    } else {
      throw err;
    }
  }
});

export default createSlice({
  name: "tournaments",
  initialState: {
    fetchStatus: "notStarted",
    response: null,
    error: null,
  } as State,
  reducers: {
    updateTournaments: (state, action: PayloadAction<TournamentTree>) => {
      state = {
        fetchStatus: state.fetchStatus,
        response: action.payload,
        error: state.error,
      };
      return state;
    },
    reset: (state) => {
      state = {
        fetchStatus: "notStarted",
        response: null,
        error: null,
      };
      return state;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTournamentsAction.fulfilled, (state, action) => {
        const newTournament = action.payload;
        // winners bracket tree construction
        const winnersBracketTree = toTree(winnersBracket(newTournament.winnersBracket), "winners");

        const t = newTournament.winnersBracket;
        const firstNode = t[t.length - 1];
        const secondNode = t[t.length - 2];

        const winnersTree = {
          data: firstNode,
          right: {
            data: secondNode,
            right: winnersBracketTree,
          },
        };

        //losers bracket tree construction
        const losersTree = toTree(orderGames(newTournament.losersBracket), "losers");

        state = {
          fetchStatus: "success",
          response: {
            winnersBracket: winnersTree,
            losersBracket: losersTree,
            winnersBracketLength: newTournament.winnersBracket.length,
            losersBracketLength: newTournament.losersBracket.length,
            teamId: newTournament.teamId,
            teamStatus: newTournament.teamStatus,
          },
          error: null,
        };
        return state;
      })
      .addCase(fetchTournamentsAction.pending, (state, action) => {
        state.fetchStatus = "pending";
        return state;
      })
      .addCase(fetchTournamentsAction.rejected, (state, action) => {
        if (action.payload) {
          state.fetchStatus = "failed";
          state.error = action.payload;
        } else {
          state.fetchStatus = "failed";
          state.error = null;
        }
        return state;
      });
  },
});

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { FetchStatus } from "../fetchStatus";
import { Energy, PlayerInfo, EnergyPerk, EnergyUsageHistory } from "../../api/energy";
import { MyKnownError } from "../myKnownError";

interface State {
  fetchStatus: FetchStatus;
  error: MyKnownError | null;
  response: {
    energyPerks: EnergyPerk[] | null;
    roster: { [key: string]: PlayerInfo } | null;
    offenses: string[] | null;
    defenses: string[] | null;
    trainings: { [key: string]: { id: number; name: string } } | null;
    energyUsageHistory: EnergyUsageHistory[] | null;
    energy: number | null;
    energyBank: number | null;
    premiumStatus: boolean;
  };
}

export default createSlice({
  name: "setLineupForm",
  initialState: {
    fetchStatus: "notStarted",
    error: null,
    response: {
      energyPerks: null,
      roster: null,
      offenses: null,
      defenses: null,
      trainings: null,
      energyUsageHistory: null,
    },
  } as State,
  reducers: {
    updateEnergy: (state, action: PayloadAction<Energy>) => {
      state = {
        fetchStatus: state.fetchStatus,
        error: state.error,
        response: action.payload,
      };
      return state;
    },
    reset: (state) => {
      state = {
        fetchStatus: "notStarted",
        response: {
          energyPerks: null,
          roster: null,
          offenses: null,
          defenses: null,
          trainings: null,
          energyUsageHistory: null,
          energy: null,
          energyBank: null,
          premiumStatus: false,
        },
        error: null,
      };
      return state;
    },
  },
});

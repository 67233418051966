import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { registerUser } from "../../api/registerUser";
import { RegisterFormWithNumbers } from "../../api/registerUser";
import { FetchStatus } from "../fetchStatus";
import { AsyncThunkError } from "../asyncThunkError";

interface State {
  fetchStatus: FetchStatus;
  response: Response | undefined;
}

export type Response =
  | "EMAIL_IN_USE"
  | "USERNAME_IN_USE"
  | "TEAM_NAME_IN_USE"
  | "ARENA_NAME_IN_USE"
  | "INVALID_BETA_KEY";

export const register = createAsyncThunk<void, RegisterFormWithNumbers, AsyncThunkError<Response>>(
  "register/user",
  async (user: RegisterFormWithNumbers, thunkApi) => {
    try {
      await registerUser(user);
    } catch (err) {
      if (err?.response?.status === 400 && err?.response?.data) {
        return thunkApi.rejectWithValue(err.response.data);
      } else {
        throw err;
      }
    }
  }
);

export default createSlice({
  name: "register",
  initialState: {
    fetchStatus: "notStarted" as FetchStatus,
    response: undefined,
  } as State,
  reducers: {
    clearResponse: (state) => {
      state.response = undefined;
      return state;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(register.fulfilled, (state, action) => {
        // register user succeeded
        state = {
          fetchStatus: "success",
          response: undefined,
        };
        return state;
      })
      // make update while waiting
      .addCase(register.pending, (state, action) => {
        state.fetchStatus = "pending";
        return state;
      })
      // make update when failed
      .addCase(register.rejected, (state, action) => {
        state = {
          fetchStatus: "failed",
          response: action.payload,
        };
        return state;
      });
  },
});

import "./wdyr.ts"; // <--- first import
import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { Provider } from "react-redux";
import store from "./redux/store";
// Fonts provided by the designer
import "./fonts/fa-light-300.ttf";
import "./fonts/fa-regular-400.ttf";
import "./fonts/fa-solid-900.ttf";
import "./fonts/LemonMilk.otf";
import "./fonts/LemonMilkbold.otf";
import "./fonts/LemonMilkbolditalic.otf";
import "./fonts/LemonMilkitalic.otf";
import "./fonts/LemonMilklight.otf";
import "./fonts/LemonMilklightitalic.otf";
import { ThemeProvider } from "styled-components";

// colors used
export const theme = {
  lightGrayBackground: "#303339",
  mediumGrayBackground: "#1e2024",
  darkGrayBackground: "#16181c",
  loginBackground: "#292c31",
  dropdownBackground: "#3a3d44",
  tileHeaderBackground: "#2f3237",
  statsButtonBackground: "#42454b",
  gauntletBackground: "#272a2f",
  hoverBackground: "#44464b",
  statsBackground: "#45484e",
  setLineupBackground: "#131519",
  cardBackground: "#25282d",
  energyCardBackground: "#3a3d43",
  energyBarBackgorund: "#15171a",
  grayFont: "#9a9da2",
  whiteFont: "#ffffff",
  blackFont: "#000000",
  yellowFont: "#FFDC11",
  diamondFont: "#66FFFF",
  orangeTheme: "#FF7E1F",
  yellowTheme: "#FFCC00",
  greenTheme: "#4dbe25",
  redTheme: "#ff5d5d",
  purpleTheme: "#9a66ca",
  inputBorder: "#41444a",
  refillBorderOne: "#151619",
  refillBorderTwo: "#474a50",
  tileHeaderBorder: "#393c41",
  infoBorder: "#2c2e31",
  grayButton: "#31404b",
};

export type CourtHoopsTheme = typeof theme;

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

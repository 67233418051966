import React from "react";
import styled from "styled-components/macro";
import SkillStars from "./SkillStars";
import { Change, SkillLevel } from "../../../api/roster";
import { breakpoint } from "../../../util/breakpoint";
import ForwardIcon from "@material-ui/icons/Forward";
import RemoveIcon from "@material-ui/icons/Remove";

interface OwnProps {
  name: string;
  skill: SkillLevel;
  number: number | string;
  height: number;
  change: Change | undefined;
}

const SkillLine = (props: OwnProps) => {
  if (props.change) {
    return (
      <Container height={props.height} change={props.change.type}>
        <p className="skill-name-shift">{props.name}</p>
        <SkillStars skill={props.skill}></SkillStars>
        <p className="skill-count-shift">{props.number}</p>
        {props.change.type === "pop" ? (
          <ForwardIcon style={iconStylePop} />
        ) : props.change.type === "drop" ? (
          <ForwardIcon style={iconStyleDrop} />
        ) : (
          <RemoveIcon style={removeIconStyle} />
        )}
      </Container>
    );
  } else {
    return (
      <Container height={props.height} change={""}>
        <p className="skill-name">{props.name}</p>
        <SkillStars skill={props.skill}></SkillStars>
        <p className="skill-count">{props.number}</p>
      </Container>
    );
  }
};

export default SkillLine;

const Container = styled.div<{ height: number; change: string }>`
  width: 250px;
  height: ${(props) => props.height}px;
  border-radius: 5px;
  border: solid 0.5px ${(props) =>
    props.change === "pop"
      ? props.theme.greenTheme
      : props.change === "drop"
      ? "red"
      : props.change === "cap"
      ? "yellow"
      : props.theme.inputBorder};
  background-color: ${(props) => props.theme.lightGrayBackground};
  display: flex;
  align-items: center;
  margin: 5px 0 0 0;
  justify-content: space-evenly;
  font-size: 10px;
  letter-spacing: 0.78px;

  @media ${breakpoint.mobileTablet} {
    width: 100%;
  }

  .skill-name {
    color: ${(props) => props.theme.grayFont};
    width: 80px;
    font-family: LemonMilklight;
    margin-left: 5px;

  }

  .skill-name-shift {
    color: ${(props) => props.theme.grayFont};
    width: 80px;
    margin-left: 5px;
    font-family: LemonMilklight;
  }

  .skill-count {
    color: ${(props) => props.theme.whiteFont};
    font-family: LemonMilk;
    width: 20px;
    text-align: right;
    margin-right: 5px;
  }

  .skill-count-shift {
    color: ${(props) => props.theme.whiteFont};
    font-family: LemonMilk;
    margin-left: 5px;
    margin-bottom: 10px;
  }

  .change-image {
    width="15" 
    height="15"
    right-margin: 5px;
  }
`;

const iconStylePop = {
  fontSize: "22px",
  color: `#4dbe25`,
  transform: "rotate(270deg)",
};

const iconStyleDrop = {
  fontSize: "22px",
  color: `red`,
  transform: "rotate(90deg)",
};

const removeIconStyle = {
  fontSize: "22px",
  color: `yellow`,
};

import React from "react";
import styled from "styled-components/macro";
import { InfoStyles } from "../RosterPage/InfoStyles";
import { Potential, Attributes } from "../../../api/roster";

interface OwnProps {
  id: number;
  potential: Potential;
  aggressiveness: Attributes;
  motivation: Attributes;
  longevity: Attributes;
  healthHistory: Attributes;
  salary: number;
  duration: number;
}

const AdvancedInfo = (props: OwnProps) => {
  const internationalNumberFormat = new Intl.NumberFormat("en-US");
  const salary = internationalNumberFormat.format(props.salary);

  return (
    <Container>
      <div className="player-stats-info" style={{ minWidth: "95%" }}>
        <p className="label">Contract</p>
        <p className="player-stats">
          {salary} for {props.duration} {props.duration === 1 ? "season" : "seasons"}
        </p>
      </div>
      <div className="player-stats-info">
        <p className="label">Potential</p>
        <p className="player-stats">{props.potential}</p>
      </div>
      <div className="player-stats-info">
        <p className="label">Health History</p>
        <p className="player-stats">{props.healthHistory}</p>
      </div>
      <div className="player-stats-info">
        <p className="label">Longevity</p>
        <p className="player-stats">{props.longevity}</p>
      </div>
      <div className="player-stats-info">
        <p className="label">Motivation</p>
        <p className="player-stats">{props.motivation}</p>
      </div>
      <div className="player-stats-info" style={{ minWidth: "95%" }}>
        <p className="label">Aggressiveness</p>
        <p className="player-stats">{props.aggressiveness}</p>
      </div>
    </Container>
  );
};

export default AdvancedInfo;

const Container = styled.div`
  ${InfoStyles}
`;

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TeamPages } from "../../components/pages/teamPages";

export default createSlice({
  name: "teamPages",
  initialState: "team" as TeamPages,
  reducers: {
    changeTeamPages: (state, action: PayloadAction<TeamPages>) => {
      return action.payload;
    },
  },
});

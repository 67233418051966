import React from "react";
import { ListItemText } from "@material-ui/core";
import styled from "styled-components";
import { theme } from "../..";

export const CustomListItemText: React.FC<
  React.ComponentProps<typeof ListItemText> & { selected?: boolean }
> = ({ children, ...other }) => {
  return (
    <StyledListItemText classes={{ primary: "primary" }} {...other}>
      {children}
    </StyledListItemText>
  );
};

const StyledListItemText = styled(ListItemText)<{ selected?: boolean }>`
  & .primary {
    font-size: 12px;
    font-family: "LemonMilk";
    letter-spacing: 1.2px;
    text-align: center;
    color: ${(props) => (props.selected ? `${theme.yellowFont}` : `${theme.whiteFont}`)};
  }
`;

export default CustomListItemText;

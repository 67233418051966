import { createAction } from "@reduxjs/toolkit";
import WebSocketClient from "../util/webSocket";

import { updateGame } from "../redux/reducer/liveGameReducer";
import { useEffect } from "react";
import { useDispatch } from "react-redux";

let websocket: WebSocketClient;

const onMessage = (store: any) => (event: any) => {
  if (event) {
    store.dispatch(updateGame(event));
  }
};

const onClose = (store: any) => () => {
  store.dispatch(WebsocketAction.close());
};

export const WebsocketAction = {
  connect: createAction<number>("WEBSOCKET:CONNECT"),
  close: createAction("WEBSOCKET:CLOSE"),
};

export const useSocket = (teamId: number) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(WebsocketAction.connect(teamId));
    return () => {
      // dispatch(WebsocketAction.close())
    };
  }, [dispatch, teamId]);
};

export const websocketMiddleware = (store: any) => (next: any) => (action: any) => {
  switch (action.type) {
    case WebsocketAction.connect.toString():
      websocket = new WebSocketClient("gameViewer", { gameId: action.payload });
      websocket.on("close", onClose(store));
      websocket.on("message", onMessage(store));
      break;
    case WebsocketAction.close.toString():
      websocket.ws.close();
      break;
    default:
      next(action);
  }
};

//contains the size limits to each type of device
//desktop:  >= 1100px
//tablet: from 600px to 1099px;
//mobile: <= 599px;

const BP = {
  desktopSmall: 1600,
  tabletLarge: 1300,
  desktopMin: 1100,
  tabletMax: 1099,
  tabletMin: 600,
  mobileMax: 599,
};

export const breakpoint = {
  mobile: `(max-width: ${BP.mobileMax}px)`,
  tablet: `(min-width: ${BP.tabletMin}px) and (max-width: ${BP.tabletMax}px)`,
  tabletAndMobile: `(min-width: 200px) and (max-width: ${BP.tabletMax}px)`,
  desktop: `(min-width: ${BP.desktopMin}px)`,
  mobileTablet: `(max-width: ${BP.tabletMax}px)`,
  tabletLarge: `(max-width: ${BP.tabletLarge}px)`,
  desktopSmall: `(max-width: ${BP.desktopSmall}px)`,
  tabletDesktop: `(min-width: ${BP.tabletMin}px)`,
};

import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { useSpring, animated } from "react-spring/web.cjs"; // web.cjs is required for IE 11 support

export interface FadeProps {
  children?: React.ReactElement;
  in: boolean;
  onEnter?: () => {};
  onExited?: () => {};
}

export const Fade = React.forwardRef<HTMLDivElement, FadeProps>(function Fade(props, ref) {
  const { in: open, children, onEnter, onExited, ...other } = props;
  const style = useSpring({
    from: { opacity: 0 },
    to: { opacity: open ? 1 : 0 },
    onStart: () => {
      if (open && onEnter) {
        onEnter();
      }
    },
    onRest: () => {
      if (!open && onExited) {
        onExited();
      }
    },
  });

  return (
    <animated.div ref={ref} style={style} {...other}>
      {children}
    </animated.div>
  );
});

export const buttonStyles = {
  height: "30px",
  borderRadius: "3px",
  backgroundColor: "#31404b",
  width: "100px",
  fontFamily: "LemonMilk",
  fontSize: "12px",
  color: "#ffffff",
  cursor: "pointer",
  marginRight: "30px",
};

export const buttonStylesGray = {
  height: "30px",
  borderRadius: "3px",
  backgroundColor: "#808080",
  width: "100px",
  fontFamily: "LemonMilk",
  fontSize: "12px",
  color: "#ffffff",
  cursor: "pointer",
  marginRight: "30px",
};

export const smallButtonStyles = {
  height: "30px",
  borderRadius: "3px",
  backgroundColor: "#31404b",
  width: "50px",
  fontFamily: "LemonMilk",
  fontSize: "12px",
  color: "#ffffff",
  cursor: "pointer",
  marginRight: "10px",
};

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    paper: {
      backgroundColor: "#16181c",
      border: `2px solid #41444a`,
      fontFamily: "LemonMilk",
      color: "#9a9da2",
      fontSize: "12px",
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
  })
);

import { useMatchMedia } from "./useMatchMedia";
import { breakpoint } from "./breakpoint";

// Hook used to taggle components between desktop, tablet and mobile
const mapResponsive = (isMobile: boolean, isTablet: boolean) => <T extends {}>(
  desktop: T,
  tablet: T,
  mobile: T
) => (isMobile ? mobile : isTablet ? tablet : desktop);

export const useResponsive = () => {
  const isMobile = useMatchMedia(breakpoint.mobile);
  const isTablet = useMatchMedia(breakpoint.tablet);
  const isTabletLarge = useMatchMedia(breakpoint.tabletLarge);

  return {
    resp: mapResponsive(isMobile, isTablet),
    isMobile,
    isTablet,
    isTabletLarge,
  };
};

import { Tree } from "tournament-bracket-tree";
import { Tournament } from "../api/tournaments";

// Transforms loser's and winner's data to tree

interface GameType<T> {
  game: T;
  type: "unary" | "binary";
}

export class NodeQueue<T> {
  data: GameType<T>;
  next: NodeQueue<T> | null;

  constructor(data: GameType<T>, next: NodeQueue<T> | null) {
    this.data = data;
    this.next = next;
  }
}

export class Queue<T> {
  first: NodeQueue<T> | null;
  last: NodeQueue<T> | null;
  constructor() {
    this.first = null;
    this.last = null;
  }

  enqueue(data: GameType<T>): void {
    const newNode = new NodeQueue(data, null);
    if (this.last !== null) {
      this.last.next = newNode;
    }
    this.last = newNode;
    if (this.first === null) {
      this.first = this.last;
    }
  }

  dequeue(): GameType<T> {
    if (this.first === null) {
      throw new Error("empty queue");
    } else {
      const result = this.first.data;
      this.first = this.first.next;
      this.last = this.first === null ? null : this.last;
      return result;
    }
  }
}

export const toTree = (arr: Tournament[], type: "winners" | "losers"): Tree<Tournament> => {
  const queue = new Queue<Tree<Tournament>>();
  const first = arr.shift();
  let tree: Tree<Tournament> = { data: arr[0] };
  if (first) {
    tree = { data: first };
    queue.enqueue({
      game: tree,
      type: type === "winners" ? "binary" : "unary",
    });
  }

  while (arr.length) {
    const curr = queue.dequeue();

    if (curr.type === "binary" || type === "winners") {
      const left = arr.shift();
      const right = arr.shift();
      if (left && right) {
        curr.game.left = {
          data: left,
        };
        curr.game.right = {
          data: right,
        };
        queue.enqueue({
          game: curr.game.left,
          type: type === "winners" ? "binary" : "unary",
        });
        queue.enqueue({
          game: curr.game.right,
          type: type === "winners" ? "binary" : "unary",
        });
      }
    } else {
      const next = arr.shift();
      if (next) {
        curr.game.left = {
          data: next,
        };
        queue.enqueue({
          game: curr.game.left,
          type: "binary",
        });
      }
    }
  }

  return tree;
};

import produce from "immer";
import { SkillSort, RosterPlayerWithExtraAttributes, Attributes } from "../api/roster";

// Used in the Roster dropdown. It sorts the attributes (handles number and strings)
// immer (produce) is used so the sorting does not modify the original data
export const sortPlayers = (p: RosterPlayerWithExtraAttributes[], filter: SkillSort) =>
  produce(p, (draftState) => {
    draftState.sort((a, b) => {
      let valueA = a[filter];
      let valueB = b[filter];

      if (filter === "age") {
        valueA = Number(valueA);
        valueB = Number(valueB);
      }

      if (typeof valueA === "number" && typeof valueB === "number") {
        return valueB - valueA;
      } else if (typeof valueA !== "number" && typeof valueB !== "number") {
        return valueA && valueB ? attributeToNumber(valueB) - attributeToNumber(valueA) : 0;
      } else {
        return 0;
      }
    });
  });

const attributeToNumber = (a: Attributes): number => {
  return {
    Excellent: 3,
    Average: 2,
    Poor: 1,
  }[a];
};

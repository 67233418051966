import React, { Suspense, lazy } from "react";
import NavBar from "./components/navBar/NavBar";
import Announcement from "./components/lib/Announcement";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import PrivateRoute from "./components/lib/PrivateRoute";
import styled from "styled-components/macro";
import "./styles.css";
import Loading from "./components/lib/staticPages/Loading";
import Premium from "./components/pages/Premium";
import Footer from "./components/pages/Footer";
import PrivacyPolicy from "./components/pages/PrivacyPolicy";
import CookiePolicy from "./components/pages/CookiePolicy";
import TermsAndConditions from "./components/pages/TermsAndConditions";
import TransferMarket from "./components/pages/TransferMarket";
import CheckoutFailed from "./components/pages/CheckoutFailed";
import CheckoutSuccess from "./components/pages/CheckoutSuccess";

// All page imports
const LandingPage = lazy(() => import("./components/pages/LandingPage"));
const Home = lazy(() => import("./components/pages/Home"));
const TeamPage = lazy(() => import("./components/pages/TeamPage"));
const Roster = lazy(() => import("./components/pages/Roster"));
const Schedule = lazy(() => import("./components/pages/Schedule"));
const Scrimmage = lazy(() => import("./components/pages/Scrimmage"));
const League = lazy(() => import("./components/pages/League"));
const Leagues = lazy(() => import("./components/pages/Leagues"));
const Leaderboards = lazy(() => import("./components/pages/Leaderboards"));
const SetLineup = lazy(() => import("./components/pages/Lineup"));
// eslint-disable-next-line
const LogIn = lazy(() => import("./components/pages/LogIn"));
const Badges = lazy(() => import("./components/pages/Badges"));
const Gauntlet = lazy(() => import("./components/pages/Gauntlet"));
const Economy = lazy(() => import("./components/pages/Economy"));
const Energy = lazy(() => import("./components/pages/Energy"));
const GameSummary = lazy(() => import("./components/pages/GameSummary"));
const PlayByPlay = lazy(() => import("./components/pages/PlayByPlay"));
const Standings = lazy(() => import("./components/pages/Standings"));
const Statistics = lazy(() => import("./components/pages/Statistics"));
const Contracts = lazy(() => import("./components/pages/Contracts"));
const Manual = lazy(() => import("./components/pages/Manual"));
const Forbidden = lazy(() => import("./components/lib/staticPages/Forbidden"));
const NotFound = lazy(() => import("./components/lib/staticPages/NotFound"));
const Oops = lazy(() => import("./components/lib/staticPages/Oops"));
const BadRequest = lazy(() => import("./components/lib/staticPages/BadRequest"));
// eslint-disable-next-line
const ResetPassword = lazy(() => import("./components/pages/ResetPassword"));
const Register = lazy(() => import("./components/pages/Register"));
const Player = lazy(() => import("./components/pages/Player"));
const Training = lazy(() => import("./components/pages/Training"));
// eslint-disable-next-line
const Logout = lazy(() => import("./components/pages/Logout"));
// const Loading = lazy(() => import("./components/lib/staticPages/Loading"));
// const Premium = lazy(() => import("./components/pages/Premium"));
// const Footer = lazy(() => import("./components/pages/Footer"));
// const PrivacyPolicy = lazy(() => import("./components/pages/PrivacyPolicy"));
// const CookiePolicy = lazy(() => import("./components/pages/CookiePolicy"));
// const TermsAndConditions = lazy(() => import("./components/pages/TermsAndConditions"));
// const TransferMarket = lazy(() => import("./components/pages/TransferMarket"));
// const CheckoutFailed = lazy(() => import("./components/pages/CheckoutFailed"));
// const CheckoutSuccess = lazy(() => import("./components/pages/CheckoutSuccess"));
const Settings = lazy(() => import("./components/pages/Settings"));

function App() {
  return (
    <div className="App">
      <Router>
        <Container>
          <NavBar />
          <Suspense fallback={<Loading />}>
            <Announcement />
            <Switch>
              <PrivateRoute path="/contracts" component={Contracts} />
              <PrivateRoute path="/statistics" component={Statistics} />
              <PrivateRoute path="/standings" component={Standings} />
              <PrivateRoute path="/game/:id/summary" component={GameSummary} />
              <PrivateRoute path="/game/:id/play-by-play" component={PlayByPlay} />
              <PrivateRoute path="/badges/:id" component={Badges} />
              <PrivateRoute path="/badges" component={Badges} />
              <PrivateRoute path="/energy" component={Energy} />
              <PrivateRoute path="/economy" component={Economy} />
              <PrivateRoute path="/premium" component={Premium} />
              <PrivateRoute path="/gauntlet/:id" component={Gauntlet} />
              <PrivateRoute path="/lineup/:id" component={SetLineup} />
              <PrivateRoute path="/schedule/:id" component={Schedule} />
              <PrivateRoute path="/scrimmage" component={Scrimmage} />
              <PrivateRoute path="/league/:id" component={League} />
              <PrivateRoute path="/leagues" component={Leagues} />
              <PrivateRoute path="/leaderboards" component={Leaderboards} />
              <PrivateRoute path="/team/:id" component={TeamPage} />
              <PrivateRoute path="/roster/:id" component={Roster} />
              <PrivateRoute path="/player/:id" component={Player} />
              <PrivateRoute path="/training" component={Training} />
              <PrivateRoute path="/something-went-wrong" component={Oops} />
              <PrivateRoute path="/home" component={Home} />
              <PrivateRoute path="/manual" component={Manual} />
              <PrivateRoute path="/settings" component={Settings} />
              <PrivateRoute path="/termsAndConditions" component={TermsAndConditions} />
              <PrivateRoute path="/privacyPolicy" component={PrivacyPolicy} />
              <PrivateRoute path="/cookiePolicy" component={CookiePolicy} />
              <PrivateRoute path="/transfers" component={TransferMarket} />
              <PrivateRoute path="/checkout-success" component={CheckoutSuccess} />
              <PrivateRoute path="/checkout-failed" component={CheckoutFailed} />

              <Route path="/reset-password" component={ResetPassword} />
              <Route path="/not-found" component={NotFound} />
              <Route path="/bad-request" component={BadRequest} />
              <Route path="/forbidden" component={Forbidden} />
              <Route path="/register" exact component={Register} />
              <Route path="/login" exact component={LogIn} />
              <Route path="/logout" exact component={Logout} />
              <Route path="/" exact component={LandingPage} />
            </Switch>
          </Suspense>
          <Footer />
        </Container>
      </Router>
    </div>
  );
}

export default App;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

import React from "react";
import page from "./page";
import styled from "styled-components/macro";
import { navBarHeight } from "../lib/styles/navBarHeight";

const PrivacyPolicy = () => {
  return (
    <Iframe
      src="https://app.termly.io/document/privacy-policy/f95b726b-0e71-42dd-bce9-a186f289ce3c"
      title="Privacy Policy"
    />
  );
};

export default page("privacyPolicy")(PrivacyPolicy);

const Iframe = styled.iframe`
  width: 100%;
  height: calc(100vh - ${navBarHeight});
  box-sizing: border-box;
  border: 0;
  background: white;
`;

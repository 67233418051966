import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { submitLineup, Lineup } from "../../api/submitLineup";
import { FetchStatus } from "../fetchStatus";
import { MyKnownError } from "../myKnownError";
import { AsyncThunkError } from "../asyncThunkError";

interface State {
  fetchStatus: FetchStatus;
  error: MyKnownError | null;
}

export const setLineupAction = createAsyncThunk<
  void,
  Lineup & { id: number },
  AsyncThunkError<MyKnownError>
>("SetLineup", async (lineup: Lineup & { id: number }, thunkApi) => {
  try {
    await submitLineup(lineup, lineup.id);
  } catch (err) {
    if (err?.response?.status === 400) {
      return thunkApi.rejectWithValue("400");
    } else {
      throw err;
    }
  }
});

export default createSlice({
  name: "setLineup",
  initialState: {
    fetchStatus: "notStarted" as FetchStatus,
    error: null,
  } as State,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(setLineupAction.fulfilled, (state, action) => {
        // submit lineup succeeded
        state.fetchStatus = "success";

        return state;
      })
      // make update while waiting
      .addCase(setLineupAction.pending, (state, action) => {
        state.fetchStatus = "pending";
        return state;
      })
      // make update when failed
      .addCase(setLineupAction.rejected, (state, action) => {
        if (action.payload) {
          state.fetchStatus = "failed";
          state.error = action.payload;
        } else {
          state.fetchStatus = "failed";
          state.error = null;
        }
        return state;
      });
  },
});

import { createSlice } from "@reduxjs/toolkit";

interface State {
  id?: number;
}

export default createSlice({
  name: "otherTeamId",
  initialState: {
    id: undefined,
  } as State,
  reducers: {
    updateId: (state, action) => {
      state.id = action.payload;
      return state;
    },
    resetId: (state) => {
      state.id = undefined;
      return state;
    },
  },
});

import React from "react";
import styled from "styled-components/macro";

interface OwnProps {
  curr: number;
  max: number;
  color: string;
  isOnNav: boolean;
  isOnDropdown: boolean;
  height: string;
  name: string;
}

const Bar = (props: OwnProps) => {
  const barPercentage = props.curr > props.max ? 100 : (props.curr * 100) / props.max;

  return (
    <BarContainer
      barPercentage={barPercentage}
      color={props.color}
      isOnNav={props.isOnNav}
      isOnDropdown={props.isOnDropdown}
      height={props.height}
      name={props.name}
    >
      <div className="bar"></div>
      <span className="energySpan">{props.name}</span>
      <span className="valueSpan">
        {props.curr}/{props.max}
      </span>
    </BarContainer>
  );
};

export default Bar;

const BarContainer = styled.div<{
  barPercentage: number;
  color: string;
  isOnNav: boolean;
  isOnDropdown: boolean;
  height: string;
  name: string;
}>`
  position: relative;
  width: ${(props) => (props.isOnNav ? "105px" : "300px")};
  height: ${(props) => props.height};
  border-radius: 50px;
  background-color: #ffffff;
  margin: ${(props) => (props.isOnNav ? "auto" : 0)};
  margin-top: ${(props) => (props.isOnNav ? "5px" : 0)};
  margin-left: ${(props) => (props.isOnDropdown ? 0 : "auto")};
  overflow: hidden;

  .bar {
    position: absolute;
    border-radius: 50px;
    height: 100%;
    width: ${(props) => props.barPercentage}%;
    background-color: ${(props) => props.color};
  }

  .energySpan,
  .valueSpan {
    position: absolute;
    font-size: ${(props) => (props.isOnNav ? "8px" : "15px")};
    font-family: "LemonMilk";
    // letter-spacing: 1.2px;
  }

  .energySpan {
    left: 5px;
    color: #303339;
  }

  .valueSpan {
    right: 5px;
    color: #303339;
  }
`;

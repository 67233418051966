import React, { useState } from "react";
import styled from "styled-components/macro";
import { MenuItem } from "@material-ui/core";
import page from "./page";
import PlayerCard from "../lib/RosterPage/PlayerCard";
import DropDownOrange from "../forms/DropDownOrange";
import { sortPlayers } from "../../util/sortRoster";
import { breakpoint } from "../../util/breakpoint";
import { api } from "../../api/api";
import { RosterPlayerWithExtraAttributes, SkillSort } from "../../api/roster";

const Transfers = () => {
  const [sortValue, setSortValue] = useState(undefined as SkillSort | undefined);
  let { data: auctions, error } = api.endpoints.fetchAuctions.useQuery();

  if (!auctions || error) {
    return <></>;
  }

  if (sortValue) {
    auctions = sortPlayers(auctions, sortValue);
  }

  return (
    <Container>
      <TeamNameAndSortContainer>
        <div className="open">Free Agency On!</div>
        <CustomDropDownOrange
          className="dropdown"
          onChange={(e) => {
            setSortValue(e.target.value as SkillSort);
          }}
          defaultValue="default"
        >
          <MenuItem value="default" disabled>
            Filter
          </MenuItem>
          <MenuItem value="age">Age</MenuItem>
          <MenuItem value="height">Height</MenuItem>
          <MenuItem value="weight">Weight</MenuItem>
          <MenuItem value="potential">Potential</MenuItem>
          <MenuItem value="longevity">Longevity</MenuItem>
          <MenuItem value="healthHistory">Health History</MenuItem>
          <MenuItem value="aggressiveness">Aggressiveness</MenuItem>
          <MenuItem value="motivation">Motivation</MenuItem>
          <MenuItem disabled>---------------</MenuItem>,
          <MenuItem value="longRangeShooting">Outside Shot</MenuItem>,
          <MenuItem value="handling">Ball Handling</MenuItem>,
          <MenuItem value="passing">Passing</MenuItem>,
          <MenuItem value="midRangeShooting">Mid-Range</MenuItem>,
          <MenuItem value="insideShooting">Inside Shot</MenuItem>,
          <MenuItem value="driving">Driving</MenuItem>,
          <MenuItem value="finishing">Finishing</MenuItem>,
          <MenuItem value="outsideDefense">Outside Def.</MenuItem>,
          <MenuItem value="stealing">Stealing</MenuItem>,
          <MenuItem value="insideDefense">Inside Def.</MenuItem>,
          <MenuItem value="blocking">Blocking</MenuItem>,
          <MenuItem value="offensiveIntellect">Offfensive IQ</MenuItem>,
          <MenuItem value="defensiveIntellect">Defensive IQ</MenuItem>,
          <MenuItem value="mobility">Mobility</MenuItem>,
          <MenuItem value="physique">Physique</MenuItem>,
          <MenuItem value="rebounding">Rebounding</MenuItem>,
          <MenuItem disabled>---------------</MenuItem>,
          <MenuItem value="offensiveStars">Offensive Stars</MenuItem>,
          <MenuItem value="defensiveStars">Defensive Stars</MenuItem>,
          <MenuItem value="intangibleStars">Intangible Stars</MenuItem>,
          <MenuItem value="totalStars">Total Stars</MenuItem>
        </CustomDropDownOrange>
      </TeamNameAndSortContainer>
      {auctions.map((p: RosterPlayerWithExtraAttributes) => {
        return <PlayerCard {...p} key={p.id} />;
      })}
    </Container>
  );
};

export default page("transfers")(Transfers);

const CustomDropDownOrange = styled(DropDownOrange)`
  width: 200px;
  margin: 20px 0;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.darkGrayBackground};
  width: 100%;

  .open {
    justify-content: center;
    align-items: center;
    text-align: center;
    font-family: LemonMilk;
    font-size: 16px;
    letter-spacing: 0.6px;
    margin-left: 10px;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
    color: ${(props) => props.theme.yellowFont};

    @media ${breakpoint.mobile} {
      font-size: 12px;
    }
  }
`;

const TeamNameAndSortContainer = styled.div`
  width: 90%;
  justify-content: flex-end;
  display: flex;
  max-width: 1350px;
`;

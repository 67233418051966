import React, { useState, useEffect } from "react";
import List from "@material-ui/core/List";
import ListItemTextMessenger from "../ListItemTextMessenger";
import ListItemMessengerMobile from "../ListItemMessengerMobile";
import CustomListItem from "../CustomListItem";
import { baseStyles } from "../baseStyles";
import { ClickAwayListener, Collapse, ListItem } from "@material-ui/core";
import { theme } from "../../..";
import { Link, useLocation, NavLink } from "react-router-dom";
import styled from "styled-components";
import IconButton from "@material-ui/core/IconButton";
import { yupResolver } from "@hookform/resolvers";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { breakpoint } from "../../../util/breakpoint";
import { getDateAndTimeFromEpoch } from "../../../util/getDateAndTimeFromEpoch";

import NotificationsIcon from "@material-ui/icons/Notifications";
import NotificationsNoneIcon from "@material-ui/icons/NotificationsNone";

import EmailSharpIcon from "@material-ui/icons/EmailSharp";
import MailOutlineSharpIcon from "@material-ui/icons/MailOutlineSharp";
import SendIcon from "@material-ui/icons/Send";
import SettingsOutlinedIcon from "@material-ui/icons/SettingsOutlined";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { Message, Conversation } from "../../../api/message";
import { api } from "../../../api/api";

interface OwnProps {
  id: number;
  avatar?: string;
  mobile: boolean;
}

const schema = yup.object().shape({
  message: yup.string().required().max(999).trim(),
});

export interface MessageForm {
  message: string;
}

const UserButton = (props: OwnProps) => {
  const { mobile } = props;
  const classes = baseStyles();
  const [openNotifications, setOpenNotifications] = useState(false);
  const [openMessages, setOpenMessages] = useState(false);
  const [receiver, setReceiver] = useState(600); // SET THIS TO MOST RECENT RECEIVED OR SENT MESSAGE
  const [receiverName, setReceiverName] = useState("");
  let location = useLocation(); // Used to refresh component every page refresh

  const [clearNotification] = api.endpoints.clearNotification.useMutation();
  const [clearMessage] = api.endpoints.clearMessage.useMutation();
  const [sendMessage] = api.endpoints.sendMessage.useMutation();
  let {
    data: conversations,
    refetch: conversationsRefetch,
  } = api.endpoints.fetchMessages.useQuery();
  let { data: notifications } = api.endpoints.fetchNotifications.useQuery();

  const onSubmit = (data: MessageForm) => {
    sendMessage({ receiver, message: data.message });
    reset({ message: "" });
    conversationsRefetch();
  };

  useEffect(() => {
    setReceiver(conversations ? (conversations.length ? conversations[0].teamId : 600) : 600);
    setReceiverName(conversations ? (conversations.length ? conversations[0].username : "") : "");
    // eslint-disable-next-line
  }, [setReceiver, setReceiverName, location]);

  const handleClickNotifications = (v: boolean) => {
    setOpenNotifications(v);
  };

  const handleClickMessages = (v: boolean) => {
    setOpenMessages(v);
  };

  const handleMessagesClickAway = () => {
    handleClickMessages(false);
  };

  const handleNotificationClickAway = () => {
    handleClickNotifications(false);
  };

  const handleClickNotif = (id: number) => {
    clearNotification(id);
  };

  const { register, handleSubmit, reset } = useForm<MessageForm>({
    resolver: yupResolver(schema),
  });

  const handleConversationClick = (teamId: number, username: string) => {
    setReceiver(teamId);
    reset({ message: "" });
    clearMessage(teamId);
    setReceiverName(username);
  };

  const findConversationMessages = (conversations: Conversation[], receiver: number): Message[] => {
    const convo = conversations.find((e) => e.teamId === receiver);
    return convo ? convo.messages : [];
  };

  const checkNewMessages = (id: number, conversation: Conversation): boolean => {
    return (
      conversation.messages.find((e) => parseInt(e.receiver) === props.id && e.cleared === 0) !==
      undefined
    );
  };

  if (!conversations || !props.avatar || !notifications) {
    return <></>;
  }

  return (
    <Container>
      {props.mobile ? (
        <div className="messages-panel">
          <div className="user-list">
            <List disablePadding>
              <CustomListItem
                button
                key={0}
                style={{ borderBottom: "1px solid gold", width: "112px", height: "30px" }}
              >
                <ListItemMessengerMobile primary={"Conversations"} />
              </CustomListItem>
              {conversations.map((p: Conversation) => {
                return (
                  <CustomListItem
                    button
                    dense
                    key={p.teamId}
                    onClick={() => handleConversationClick(p.teamId, p.username)}
                    style={{
                      borderBottom: "1px solid gold",
                      width: "112px",
                      height: "30px",
                      backgroundColor:
                        p.teamId === receiver ? "gold" : `${theme.lightGrayBackground}`,
                    }}
                  >
                    <ListItemMessengerMobile
                      selected={p.teamId === receiver}
                      primary={p.username}
                      secondary={p.teamname}
                    />
                  </CustomListItem>
                );
              })}
            </List>
          </div>
          <div className="message-and-input">
            <div className="conversation-header">Conversation with {receiverName} </div>
            <div className="message-list">
              {findConversationMessages(conversations, receiver).map((m: Message) => {
                return (
                  <div>
                    <div
                      className={
                        parseInt(m.receiver) === receiver
                          ? "message-container-sent"
                          : "message-container-received"
                      }
                    >
                      <div
                        className={parseInt(m.receiver) === receiver ? "text-right" : "text-left"}
                      >
                        {m.message}
                      </div>
                    </div>
                    <div
                      className={parseInt(m.receiver) === receiver ? "date-sent" : "date-received"}
                    >
                      {`Sent ${getDateAndTimeFromEpoch(m.date)}`}
                    </div>
                  </div>
                );
              })}
            </div>
            <form className="input" onSubmit={handleSubmit(onSubmit)}>
              <div className="text-box">
                <textarea
                  id="myTextarea"
                  onInput={(e) => {
                    const target: HTMLInputElement = e.target as HTMLInputElement;
                    if (target.value.length >= 999) target.value = target.value.slice(0, 999);
                  }}
                  cols={28}
                  rows={4}
                  name="message"
                  //   value={message}
                  //   onChange={handleTextAreaChange}
                  style={{
                    resize: "none",
                    fontFamily: "Georgia",
                    fontSize: "8px",
                    color: "white",
                    backgroundColor: "grey",
                    paddingRight: "5px",
                  }}
                  ref={register}
                />
              </div>
              <div className="send-button">
                <IconButton type="submit">
                  <SendIcon style={{ fontSize: 15, color: "gold" }}></SendIcon>
                </IconButton>
              </div>
            </form>
          </div>
        </div>
      ) : (
        <></>
      )}
      <List component="div" className={props.mobile ? "mobile-list" : classes.root} id="user">
        <ListItem className={"no-gutters"}>
          <div className="team-photo-container">
            <img
              className="team-image"
              src={`/images/user_avatars/${props.avatar}`}
              alt="Court"
            ></img>
          </div>
          {/* MESSAGES */}
          <ClickAwayListener onClickAway={handleMessagesClickAway}>
            <div>
              {!props.mobile ? (
                <div>
                  <IconButton onClick={() => handleClickMessages(openMessages ? false : true)}>
                    {conversations.find((e) =>
                      e.messages.find((f) => parseInt(f.receiver) === props.id && f.cleared === 0)
                    ) ? (
                      <EmailSharpIcon style={{ fontSize: 25, color: "gold" }}></EmailSharpIcon>
                    ) : (
                      <MailOutlineSharpIcon
                        style={{ fontSize: 25, color: "white" }}
                      ></MailOutlineSharpIcon>
                    )}
                  </IconButton>
                  <Collapse
                    in={openMessages}
                    timeout="auto"
                    style={{
                      borderBottom: `6px solid ${theme.orangeTheme}`,
                      opacity: "0.8",
                      width: props.mobile ? "70vw" : "500px",
                      position: props.mobile ? "absolute" : "absolute",
                      display: "float",
                      transform: props.mobile ? "translateX(0px)" : "translateX(-350px)",
                    }}
                  >
                    <div className="messages-panel">
                      <div className="user-list">
                        <List disablePadding>
                          <CustomListItem button key={0} style={{ borderBottom: "1px solid gold" }}>
                            <ListItemTextMessenger primary={"Conversations"} />
                          </CustomListItem>
                          {conversations.map((p: Conversation) => {
                            return (
                              <CustomListItem
                                button
                                dense
                                key={p.teamId}
                                onClick={() => handleConversationClick(p.teamId, p.username)}
                                style={{
                                  borderBottom: "1px solid gold",
                                  backgroundColor:
                                    p.teamId === receiver
                                      ? "gold"
                                      : checkNewMessages(props.id, p)
                                      ? `${theme.orangeTheme}`
                                      : `${theme.lightGrayBackground}`,
                                }}
                              >
                                <ListItemTextMessenger
                                  selected={p.teamId === receiver}
                                  primary={p.username}
                                  secondary={p.teamname}
                                />
                              </CustomListItem>
                            );
                          })}
                        </List>
                      </div>
                      <div className="message-and-input">
                        <div className="conversation-header">Conversation with {receiverName} </div>
                        <div className="message-list">
                          {findConversationMessages(conversations, receiver).map((m: Message) => {
                            return (
                              <div>
                                <div
                                  className={
                                    parseInt(m.receiver) === receiver
                                      ? "message-container-sent"
                                      : "message-container-received"
                                  }
                                >
                                  <div
                                    className={
                                      parseInt(m.receiver) === receiver ? "text-right" : "text-left"
                                    }
                                  >
                                    {m.message}
                                  </div>
                                </div>
                                <div
                                  className={
                                    parseInt(m.receiver) === receiver
                                      ? "date-sent"
                                      : "date-received"
                                  }
                                >
                                  {`Sent ${getDateAndTimeFromEpoch(m.date)}`}
                                </div>
                              </div>
                            );
                          })}
                        </div>
                        <form className="input" onSubmit={handleSubmit(onSubmit)}>
                          <div className="text-box">
                            <textarea
                              id="myTextarea"
                              onInput={(e) => {
                                const target: HTMLInputElement = e.target as HTMLInputElement;
                                if (target.value.length >= 999)
                                  target.value = target.value.slice(0, 999);
                              }}
                              cols={34}
                              rows={4}
                              name="message"
                              //   value={message}
                              //   onChange={handleTextAreaChange}
                              //   onKeyPress={(e) => {
                              //     if (e.key === "Enter" && !e.shiftKey) {
                              //       handleSubmit(onSubmit);
                              //       console.log(
                              //         document.getElementById("myTextarea")
                              //           ? document.getElementById("myTextarea").value
                              //           : ""
                              //       );
                              //       e.preventDefault();
                              //     }
                              //   }}
                              style={{
                                resize: "none",
                                fontFamily: "Georgia",
                                fontSize: "14px",
                                color: "white",
                                backgroundColor: "grey",
                                paddingRight: "5px",
                              }}
                              ref={register}
                            />
                          </div>
                          <div className="send-button">
                            <IconButton type="submit">
                              <SendIcon style={{ fontSize: 25, color: "gold" }}></SendIcon>
                            </IconButton>
                          </div>
                        </form>
                      </div>
                    </div>
                  </Collapse>
                </div>
              ) : (
                <></>
              )}
            </div>
          </ClickAwayListener>
          {/* NOTIFICATIONS */}
          <ClickAwayListener onClickAway={handleNotificationClickAway}>
            <IconButton onClick={() => handleClickNotifications(openNotifications ? false : true)}>
              {notifications.newNotifications.length ? (
                <div style={{ height: "25px" }}>
                  <NotificationsIcon style={{ fontSize: 25, color: "gold" }}></NotificationsIcon>
                  <div className="bell-number">{notifications.newNotifications.length}</div>
                </div>
              ) : (
                <NotificationsNoneIcon
                  style={{ fontSize: 25, color: "white" }}
                ></NotificationsNoneIcon>
              )}
            </IconButton>
          </ClickAwayListener>
          {/* Settings */}
          <NavLink to={`/settings`}>
            <IconButton>
              <SettingsOutlinedIcon style={{ fontSize: 25, color: "white" }}></SettingsOutlinedIcon>
            </IconButton>
          </NavLink>
          {/* Logout */}
          <NavLink to={`/logout`}>
            <IconButton>
              <ExitToAppIcon style={{ fontSize: 25, color: "white" }}></ExitToAppIcon>
            </IconButton>
          </NavLink>
        </ListItem>

        {/* NOTIFICATIONS */}

        <Collapse
          in={openNotifications}
          timeout="auto"
          unmountOnExit
          style={{
            borderBottom: `6px solid ${theme.orangeTheme}`,
            width: props.mobile ? "100%" : "250px",
            position: "absolute",
            marginTop: mobile ? "50px" : "0px",
          }}
        >
          <List disablePadding>
            {notifications.newNotifications.length ? (
              notifications.newNotifications.map((f) => {
                return (
                  <CustomListItem
                    // @ts-ignore https://github.com/mui-org/material-ui/issues/17645
                    component={Link}
                    to={
                      f.message.includes("accepted your request!")
                        ? `/team/${props.id}/schedule`
                        : f.message.includes("Gym now open for training!")
                        ? `/energy`
                        : f.message.includes("Outbid on ")
                        ? `/player/${f.playerId}`
                        : "/scrimmage"
                    }
                    button
                    onClick={() => {
                      handleClickNotif(f.id);
                    }}
                    iscollapsed={true}
                    style={{ height: "50px" }}
                    key={f.id}
                  >
                    <ListItem key={f.id}>
                      <div className="notification-text">{f.message}</div>
                    </ListItem>
                  </CustomListItem>
                );
              })
            ) : (
              <CustomListItem
                // @ts-ignore https://github.com/mui-org/material-ui/issues/17645
                component={Link}
                onClick={() => {}}
                iscollapsed={true}
                style={{ height: "50px" }}
                key={1}
              >
                <ListItem key={0}>
                  <div className="notification-text">{"No New Notifications (in last 7 days)"}</div>
                </ListItem>
              </CustomListItem>
            )}
          </List>
        </Collapse>
      </List>
    </Container>
  );
};

// UserButton.whyDidYouRender = true;
export default UserButton;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${breakpoint.mobileTablet} {
    padding: 0px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
  }

  .team-photo-container {
    height: 30px;
    width: 30px;
    margin-left: 10px;
    margin-right: 10px;
    border-radius: 50%;
    background-color: ${(props) => props.theme.mediumGrayBackground};
    overflow: hidden;
    position: relative;
    border-radius: 50%;
    background-color: black;
    // transform: translateY(-40px);
    opacity: 1;
  }

  .team-image {
    position: absolute;
    top: 0px;
    left: 0px;
    object-fit: fill;
    width: 30px;
    height: 30px;
    opacity: 1;
  }

  .messages-panel {
    background-color: #303339;
    height: 600px;
    width: 500px;
    display: flex;
    margin-top: 8px;

    @media ${breakpoint.mobileTablet} {
      width: 100%;
      height: 300px;
      flex-direction: row;
    }

    .user-list {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      height: 100%;
      overflow-y: scroll;
      flex: 3;
      align-items: center;

      @media ${breakpoint.mobileTablet} {
        width: 112px;
        overflow-x: hidden;
        flex: 4;
      }
    }

    .message-and-input {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      padding: 5px 5px 5px 5px;
      flex: 2;
      border-left: solid 0.5px ${(props) => props.theme.inputBorder};

      @media ${breakpoint.mobileTablet} {
        width: 300px;
        flex: 5;
      }

      .conversation-header {
        display: flex;
        flex-direction: column;
        justify-content: center;
        max-height: 48px;
        align-items: center;
        padding-right: 30px;
        font-size: 12px;
        color: gold;
        font-family: "LemonMilk";
        letter-spacing: 1.2px;
        text-align: center;
        flex: 1;
        width: 100%;

        @media ${breakpoint.mobileTablet} {
          font-size: 8px;
          padding-right: 0px;
        }
      }

      .message-list {
        display: flex;
        flex-direction: column-reverse;
        justify-content: flex-start;
        align-items: flex-start;
        overflow: scroll;
        flex-basis: auto;
        width: 330px;
        flex: 6;
        margin: 5px 5px 5px 5px;

        @media ${breakpoint.mobileTablet} {
          width: 170px;
        }

        .message-container-sent {
          display: flex;
          font-size: 12px;
          word-wrap: break-word;
          font-family: "LemonMilk";
          letter-spacing: 1.2px;
          width: 310px;
          min-height: 40px;
          height: fit-content;
          min-height: 0;
          line-height: 1.6;
          text-align: center;
          align-items: center;
          justify-content: flex-end;
          background-color: ${(props) => props.theme.orangeTheme};

          @media ${breakpoint.mobileTablet} {
            width: 170px;
          }
        }

        .message-container-received {
          display: flex;
          word-wrap: break-word;
          font-size: 12px;
          font-family: "LemonMilk";
          letter-spacing: 1.2px;
          width: 310px;
          height: fit-content;
          text-align: center;
          justify-content: flex-start;
          align-items: center;
          background-color: ${(props) => props.theme.darkGrayBackground};

          @media ${breakpoint.mobileTablet} {
            width: 170px;
            background-color: white;
          }
        }

        .text-right {
          word-wrap: break-word;
          font-size: 12px;
          font-family: "LemonMilk";
          letter-spacing: 1.2px;
          width: 300px;
          text-align: end;
          padding-right: 10px;

          @media ${breakpoint.mobileTablet} {
            width: 170px;
            padding: 0px;
            font-size: 8px;
          }
        }

        .text-left {
          word-wrap: break-word;
          font-size: 12px;
          font-family: "LemonMilk";
          letter-spacing: 1.2px;
          width: 300px;
          text-align: start;
          padding-left: 10px;

          @media ${breakpoint.mobileTablet} {
            width: 170px;
            padding: 0px;
            font-size: 8px;
          }
        }

        .date-sent {
          height: 10px;
          flex: 1;
          font-size: 6px;
          font-family: "LemonMilk";
          letter-spacing: 1.2px;
          text-align: right;
          padding-right: 10px;

          @media ${breakpoint.mobileTablet} {
            width: 170px;
            padding: 0px;
            font-size: 5px;
            color: white;
          }
        }

        .date-received {
          flex: 1;
          height: 10px;
          font-size: 6px;
          font-family: "LemonMilk";
          letter-spacing: 1.2px;
          padding-left: 10px;

          @media ${breakpoint.mobileTablet} {
            width: 170px;
            padding: 0px;
            font-size: 5px;
            color: white;
          }
        }
      }

      .input {
        display: flex;
        width: 99%;
        align-items: center;
        flex: 1;
        margin: 5px 5px 5px 5px;

        @media ${breakpoint.mobileTablet} {
          width: 170px;
        }

        .text-box {
          display: flex;
          width: 100%;
          height: 90%;
          align-items: center;
          flex: 4;
          //   margin: 5px 5px 5px 5px;
        }

        .send-button {
          display: flex;
          width: 100%;
          align-items: left;
          flex: 3;
          width: 50px;
          margin: 5px 5px 5px 5px;
        }
      }

      input {
        border: solid 0.5px ${(props) => props.theme.white};
        background-color: ${(props) => props.theme.lightGrayBackground};
        font-size: 12px;
        padding-left: 5px;
        padding-top: 5px;
        padding-bottom: 5px;
        font-family: fa-light;
        margin-top: 10px;
        height: 100%;
        color: ${(props) => props.theme.grayFont};

        ::placeholder {
          font-family: LemonMilk;
          font-size: 12px;
        }

        :focus {
          //delete blue border when clicked
          outline: none;
        }
      }
    }
  }

  .bell-number {
    color: white;
    height: 14px;
    width: 14px;
    position: absolute;
    border-radius: 50%;
    background-color: red;
    margin-top: -32px;
    margin-left: 15px;
    border-color: white 1px;
    font-size: 10px;
    font-family: LemonMilk;
  }

  .notification-text {
    font-size: 11px;
    color: white;
  }

  .text {
    margin-top: 6px;

    @media ${breakpoint.mobileTablet} {
      margin-left: 16px;
    }
  }

  .mobile-list {
    @media ${breakpoint.mobileTablet} {
      width: 100%;
      padding: 0px;
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
    }
  }

  .no-gutters {
    padding-top: 0px;
    padding-bottom: 0px;
    justify-content: center;
    display: flex;
    @media ${breakpoint.mobileTablet} {
      padding: 0px;
    }
  }

  @media ${breakpoint.mobileTablet} {
    flex-direction: row;
    width: 100%;
  }
`;

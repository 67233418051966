import { InfoApi } from "../../api/info";
import { submitLogInFailed, submitLogInLoading, submitLogInSuccess } from "../reducer/logInReducer";
import { CHThunkAction } from "./chThunkAction";

export function getInfo(): CHThunkAction<void> {
  return async (dispatch) => {
    try {
      dispatch(submitLogInLoading());
      const { data } = await InfoApi.info();
      dispatch(submitLogInSuccess(data));
    } catch (err) {
      const error = err?.response?.status === 400 ? "400" : err;
      dispatch(submitLogInFailed(error));
    }
  };
}

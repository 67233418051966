import React from "react";
import styled from "styled-components/macro";
import { breakpoint } from "../../../util/breakpoint";
import { useResponsive } from "../../../util/useResponsive";
import StarIcon from "@material-ui/icons/Star";

interface OwnProps {
  title: string;
  price: number;
  period: string;
  savings: number | null;
}

const PremiumBenefits: React.FC<OwnProps> = (props) => {
  const { isMobile, isTablet } = useResponsive();

  return (
    <Container>
      <div className="name-container">
        <p className="name">{props.title}</p>
      </div>
      <div className="economy-container">
        {isMobile || isTablet ? (
          <></>
        ) : (
          <div className="logo">
            <img className="logo" src={"/images/CHLogoWide.png"} alt="loading..." />
          </div>
        )}
        <div className="expenses">
          <ul className="benefit">- 600 CourtHoops Coins Included Per Month Purchased</ul>
          <ul className="benefit">- Choose Team Name Color (Not yet added)</ul>
          <ul className="benefit">- 20 Weeks Of Team Training History Available (From 2)</ul>
          <ul className="benefit">- Max Energy expanded to 900 Energy (From 300)</ul>
          <ul className="benefit">- Energy Bank expanded to 20,000 Energy (From 10,000)</ul>
          <ul className="benefit">- 5 Savable Lineup Slots (From 1)</ul>
          <ul className="benefit">
            - Gold Star
            {<StarIcon className="svg-inline"></StarIcon>}
            After Your Team Name
          </ul>
          <ul className="benefit" style={{ color: "gold" }}>
            - Supporting and funding the development and growth of CourtHoops
          </ul>
          <ul className="benefit" style={{ color: "gold" }}>
            - 100% Of Sales go back into the Site until at least the end of 2023
          </ul>
          <ul className="benefit" style={{ color: "gold" }}>
            - ALL Benefits are Quality-Of-Life Benefits and none provide a competitive edge
          </ul>
        </div>
      </div>
    </Container>
  );
};

export default PremiumBenefits;

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: ${(props) => props.theme.energyCardBackground};
  min-height: 240px;
  border-radius: 5px;
  border: solid 0.5px ${(props) => props.theme.tileHeaderBorder};
  align-items: center;
  margin: 25px 20px 10px 20px;

  @media ${breakpoint.mobile} {
    min-height: 350px;
  }

  .svg-inline {
    font-size: 12px;
    color: gold;
    display: inline;
    position: relative;
    text-align: center;
    top: 0.10em;
    width: 15px;
  }

  button {
    width: 70%;
    border: none;
    margin-bottom: 10px;
    font-family: LemonMilk;
    font-size: 12px;
    letter-spacing: 4.8px;
    color: ${(props) => props.theme.whiteFont};
    height: 40px;

    &:hover {
      cursor: pointer;
    }

    &:focus {
      //delete blue border when clicked
      outline: 0;
    }

    &.yellow {
      background-color: green;
      font-family: LemonMilk;
      font-size: 12px;
      border-radius: 6px;
      letter-spacing: 1.8px;

      color: ${(props) => props.theme.whiteFont};
    }
  }

  .logo {
    height: 200px;
    width: 400px;
    object-fit: cover;
    opacity: 1;
    margin-top: 10px;
    margin-bottom: 10px;
    display: flex;
    flex: 1;

    @media ${breakpoint.mobileTablet} {
        height: 100px;
        width: 200px;
        margin-left: 10px;
      }
  }

  .name-container {
    width: 100%;
    height: 45px;
    border-radius: 5px 5px 0 0;
    background-color: ${(props) => props.theme.lightGrayBackground};
  }

  .benefit {
    color: white;
    margin-right: auto;
    text-align-last: left;
    taxt-align: left;
    margin-top: 5px;
    margin-bottom: 5px;
    padding-left: 20px;
    flex-direction: column;
  }

  .grey-line {
    height: 2px;
    width: 180px;
    background-color: ${(props) => props.theme.lightGrayBackground};
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .title {
    color: gold;
    font-size: 24px;
    font-family: LemonMilk;
  }

  .bar {
    display: flex;
    flex: 1;
    font-family: LemonMilk;
    color: gold;
  }

  .economy-container {
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    text-align: center;

    // @media ${breakpoint.tabletDesktop} {
    //   flex-direction: column;
    // }
  }

  .name {
    font-family: LemonMilk;
    font-size: 12px;
    letter-spacing: 0.94px;
    color: ${(props) => props.theme.whiteFont};
    text-align: center;
  }

  .revenues {
    display: flex;
    flex: 1;
    flex-direction: column;
    font-family: LemonMilklight;
    font-size: 12px;
    letter-spacing: 0.78px;
    color: ${(props) => props.theme.grayFont};
    padding: 5px 10px;
    align-items: center;
    text-align: center;
    justify-content: space-evenly;
  }

  .expenses {
    display: flex;
    flex-direction: column;
    font-family: LemonMilklight;
    font-size: 12px;
    letter-spacing: 0.78px;
    color: ${(props) => props.theme.grayFont};
    padding: 5px 10px;
    align-items: center;
    text-align: center;
    justify-content: space-evenly;
    flex: 4;
  }

  .expenses-chl {
    display: flex;
    flex: 1;
    flex-direction: row;
    font-family: LemonMilklight;
    font-size: 15px;
    letter-spacing: 0.78px;
    color: gold;
    padding: 5px 20px;
    align-items: center;
    text-align: center;
    justify-content: space-evenly;
  }

  .expenses-d1 {
    display: flex;
    flex: 1;
    flex-direction: row;
    font-family: LemonMilklight;
    font-size: 15px;
    letter-spacing: 0.78px;
    color: red;
    padding: 5px 20px;
    align-items: center;
    text-align: center;
    justify-content: space-evenly;
  }

  .children {
    display: flex;
    width: 95%;
    height: 100%;
  }
`;

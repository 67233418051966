import { css } from "styled-components/macro";
import { breakpoint } from "../../../util/breakpoint";

export const InfoStyles = css`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;

  .player-stats-info {
    width: 45%;
    display: flex;
    min-width: 165px;
    border-radius: 3px;
    border: solid 0.5px ${(props) => props.theme.inputBorder};
    background-color: ${(props) => props.theme.lightGrayBackground};
    height: 35px;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 5px;

    @media ${breakpoint.mobile} {
      min-width: 94%;
    }
  }

  .label {
    font-family: LemonMilklight;
    font-size: 10px;
    letter-spacing: 0.78px;
    color: ${(props) => props.theme.grayFont};
    margin-left: 15px;
  }

  .player-stats {
    font-family: LemonMilk;
    font-size: 10px;
    letter-spacing: 0.78px;
    color: ${(props) => props.theme.whiteFont};
    margin-right: 15px;
  }
`;

import { api } from "../axios.config";
import { GauntletStatus } from "../redux/reducer/tournaments";

export const fetchTournaments = (id: number) => {
  return api.get<Tournaments>(`/Tournaments/${id + 5}`);
};

export interface Tournament {
  tournamentId: number;
  id: number;
  gameId: number | null;
  homeScore: number | null;
  awayScore: number | null;
  status: number | null;
  homeTeamId: number | null;
  awayTeamId: number | null;
  homeTeam: string | null;
  awayTeam: string | null;
  date: number | null;
  homeTeamAbb: string | null;
  awayTeamAbb: string | null;
  homeAvatar: string | null;
  awayAvatar: string | null;
}

export interface Tournaments {
  teamStatus: GauntletStatus;
  winnersBracket: Tournament[];
  losersBracket: Tournament[];
  teamId: number;
}

import { api } from "../axios.config";

export const firePlayer = (id: number) => {
  return api.request<void>({
    method: "post",
    headers: { "Content-Type": "application/json" },
    url: `/Player/${id}/fire`,
  });
};

export const bidPlayer = (id: number, amount: number) => {
  const amountObj = { amount: amount };

  return api.request<void>({
    method: "post",
    headers: { "Content-Type": "application/json" },
    url: `/Player/${id}/bid`,
    data: amountObj,
  });
};

export const listPlayer = (id: number, amount: number) => {
  const amountObj = { amount: amount };

  return api.request<void>({
    method: "post",
    headers: { "Content-Type": "application/json" },
    url: `/Player/${id}/list`,
    data: amountObj,
  });
};

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SettingsGet } from "../../api/settings";
import { MyKnownError } from "../myKnownError";
import { defaultState } from "../util/defaultState";

const getSettingsSlice = createSlice({
  name: "getSettings",
  initialState: defaultState<SettingsGet>(),
  reducers: {
    getSettingsLoading: (state) => {
      state.fetchStatus = "pending";
      return state;
    },
    getSettingsSuccess: (state, action: PayloadAction<SettingsGet>) => {
      state.fetchStatus = "success";
      state.data = action.payload;
      return state;
    },
    getSettingsFailed: (state, action: PayloadAction<MyKnownError>) => {
      state.fetchStatus = "failed";
      state.error = action.payload;
      return state;
    },
    getSettingsReset: (state) => {
      state = defaultState();
      return state;
    },
  },
});

export const {
  getSettingsLoading,
  getSettingsSuccess,
  getSettingsFailed,
  getSettingsReset,
} = getSettingsSlice.actions;
export default getSettingsSlice.reducer;

import { api } from "../axios.config";
import { LogInResponse } from "./logIn";

export const info = () => {
  return api.request<LogInResponse>({
    method: "get",
    headers: { "Content-Type": "application/json" },
    url: `/Users/info`,
  });
};

export const InfoApi = {
  info,
};

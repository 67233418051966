import React, { useState, useEffect } from "react";
import List from "@material-ui/core/List";
import { NavLink } from "react-router-dom";
import CustomListItemText from "../CustomListItemText";
import CustomListItem from "../CustomListItem";
import { baseStyles } from "../baseStyles";
import { Page } from "../../../components/pages/page";
import { TeamPages } from "../../../components/pages/teamPages";
import { connect } from "react-redux";
import { RootState } from "../../../redux/store";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import Collapse from "@material-ui/core/Collapse";
import { ClickAwayListener } from "@material-ui/core";
import { theme } from "../../..";

interface OwnProps {
  userId?: number;
}

interface StateProps {
  page: Page;
  teamPage: TeamPages;
}

const TeamButton = (props: StateProps & OwnProps) => {
  const classes = baseStyles();
  const [openTeam, setOpenTeam] = useState(false);
  const { page, teamPage } = props;

  const handleClickTeam = (v: boolean) => {
    setOpenTeam(v);
  };

  useEffect(() => {}, [page, teamPage]);

  const handleClick = () => {
    handleClickTeam(false);
  };

  const handleClickAway = () => {
    handleClickTeam(false);
  };

  if (!props.userId) {
    return <></>;
  }

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <List component="div" className={classes.root}>
        <CustomListItem
          button
          selected={props.page === "team"}
          onClick={() => handleClickTeam(openTeam ? false : true)}
        >
          <CustomListItemText primary="Team" selected={props.page === "team"} />
          {openTeam ? <ExpandLess /> : <ExpandMore />}
        </CustomListItem>
        <Collapse
          in={openTeam}
          timeout="auto"
          unmountOnExit
          style={{
            borderBottom: `6px solid ${theme.orangeTheme}`,
            width: "120px",
            position: "absolute",
          }}
        >
          <List disablePadding>
            <CustomListItem
              // @ts-ignore https://github.com/mui-org/material-ui/issues/17645
              component={NavLink}
              selected={props.teamPage === "team"}
              exact
              to={`/team/${props.userId}`}
              onClick={handleClick}
              button
              iscollapsed={true}
              style={{ height: "30px" }}
            >
              <CustomListItemText selected={props.teamPage === "team"} primary="Team" />
            </CustomListItem>

            <CustomListItem
              // @ts-ignore
              component={NavLink}
              selected={props.teamPage === "roster"}
              exact
              to={`/team/${props.userId}/roster`}
              onClick={handleClick}
              button
              iscollapsed={true}
              style={{ height: "30px" }}
            >
              <CustomListItemText selected={props.teamPage === "roster"} primary="Roster" />
            </CustomListItem>

            <CustomListItem
              // @ts-ignore
              component={NavLink}
              selected={props.teamPage === "schedule"}
              exact
              to={`/team/${props.userId}/schedule`}
              onClick={handleClick}
              button
              iscollapsed={true}
              style={{ height: "30px" }}
            >
              <CustomListItemText selected={props.teamPage === "schedule"} primary="Schedule" />
            </CustomListItem>

            <CustomListItem
              // @ts-ignore
              component={NavLink}
              selected={props.teamPage === "standings"}
              exact
              to={`/team/${props.userId}/standings`}
              onClick={handleClick}
              button
              iscollapsed={true}
              style={{ height: "30px" }}
            >
              <CustomListItemText selected={props.teamPage === "standings"} primary="Standings" />
            </CustomListItem>

            <CustomListItem
              // @ts-ignore
              component={NavLink}
              selected={props.teamPage === "statistics"}
              exact
              to={`/team/${props.userId}/statistics`}
              onClick={handleClick}
              button
              iscollapsed={true}
              style={{ height: "30px" }}
            >
              <CustomListItemText selected={props.teamPage === "statistics"} primary="Statistics" />
            </CustomListItem>

            <CustomListItem
              // @ts-ignore
              component={NavLink}
              selected={props.teamPage === "contracts"}
              exact
              to={`/team/${props.userId}/contracts`}
              onClick={handleClick}
              button
              iscollapsed={true}
              style={{ height: "30px" }}
            >
              <CustomListItemText selected={props.teamPage === "contracts"} primary="Contracts" />
            </CustomListItem>

            <CustomListItem
              // @ts-ignore
              component={NavLink}
              selected={props.teamPage === "transfers"}
              exact
              to={`/team/${props.userId}/transfers`}
              onClick={handleClick}
              button
              iscollapsed={true}
              style={{ height: "30px" }}
            >
              <CustomListItemText selected={props.teamPage === "transfers"} primary="Transfers" />
            </CustomListItem>
          </List>
        </Collapse>
      </List>
    </ClickAwayListener>
  );
};

// && (props.teamPage === "team" || props.teamPage === "contracts" || props.teamPage === "roster" || props.teamPage === "schedule" || props.teamPage === "standings" || props.teamPage === "statistics")

export default connect<StateProps, {}, {}, RootState>((state) => ({
  // teamID: state.team.response.teamId,
  page: state.page,
  teamPage: state.teamPages,
}))(TeamButton);

import React from "react";
import page from "./page";
import styled from "styled-components/macro";
import { navBarHeight } from "../lib/styles/navBarHeight";

const TermsAndConditions = () => {
  return (
    <Iframe
      src="https://app.termly.io/document/terms-of-use-for-website/4aa63811-e853-4876-bea2-202adc92be8d"
      title="Privacy Policy"
    />
  );
};

export default page("termsAndConditions")(TermsAndConditions);

const Iframe = styled.iframe`
  width: 100%;
  height: calc(100vh - ${navBarHeight});
  box-sizing: border-box;
  border: 0;
  background: white;
`;

import { configureStore, isRejectedWithValue, Middleware } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/dist/query";
import { api } from "../api/api";
import rootReducer from "./rootReducer";
import { websocketMiddleware } from "./websocketMiddleware";

export const unauthenticatedMiddleware: Middleware = () => (next) => (action) => {
  if (isRejectedWithValue(action)) {
    switch (action.payload.status) {
      case 401:
        //clear session cookie
        window.location.href =
          window.location.origin + `/login?redirectURI=${window.location.href}`;
        break;
      case 403:
        window.location.pathname = "/forbidden";
        break;
      case 404:
        window.location.pathname = "/not-found";
        break;
      case 500:
        window.location.pathname = "/something-went-wrong";
        break;
    }
  }

  return next(action);
};

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([unauthenticatedMiddleware, api.middleware, websocketMiddleware]),
});

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;

export default store;

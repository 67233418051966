import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Transaction } from "../../api/transactions";
import { MyKnownError } from "../myKnownError";
import { defaultState } from "../util/defaultState";

const transactionsSlice = createSlice({
  name: "transactions",
  initialState: defaultState<Transaction[]>(),
  reducers: {
    getTransactionsLoading: (state) => {
      state.fetchStatus = "pending";
      return state;
    },
    getTransactionsSuccess: (state, action: PayloadAction<Transaction[]>) => {
      state.fetchStatus = "success";
      state.data = action.payload;
      return state;
    },
    getTransactionsFailed: (state, action: PayloadAction<MyKnownError>) => {
      state.fetchStatus = "failed";
      state.error = action.payload;
      return state;
    },
    getTransactionsReset: (state) => {
      state = defaultState();
      return state;
    },
  },
});

export const {
  getTransactionsLoading,
  getTransactionsSuccess,
  getTransactionsFailed,
  getTransactionsReset,
} = transactionsSlice.actions;
export default transactionsSlice.reducer;

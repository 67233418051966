// Converts cm to feet
//currently being used in the Roster and Player pages (component: "BasicInfo")
export function cmToFeet(n: number): string {
  //   var realFeet = (n * 0.3937) / 12;
  //   var feet = Math.ceil(realFeet);
  //   var inches = Math.round((realFeet - feet) * 12);

  //   if (inches === 12) {
  //     feet++;
  //     inches = 0;
  //   }
  //   return `${feet}'${inches}"`;

  if (n <= 184) return `6'0"`;
  if (n <= 187) return `6'1"`;
  if (n <= 190) return `6'2"`;
  if (n <= 192) return `6'3"`;
  if (n <= 195) return `6'4"`;
  if (n <= 197) return `6'5"`;
  if (n <= 200) return `6'6"`;
  if (n <= 202) return `6'7"`;
  if (n <= 205) return `6'8"`;
  if (n <= 207) return `6'9"`;
  if (n <= 210) return `6'10"`;
  if (n <= 212) return `6'11"`;
  else return `7'0"`;
}

// Function used in the mobile nav bar on team names
// If the team name ends in "s", it only adds a ' to the end of the word
// If the team name does not end in "s", id adds 's to the end of the word
// Function takes the team name, and returns it with the correct end

export const endsInS = (s: string): string => {
  if (s.charAt(s.length - 1).toLocaleLowerCase() === "s") {
    return `${s}'`;
  } else {
    return `${s}'s`;
  }
};

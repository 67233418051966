import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { MyKnownError } from "../myKnownError";
import { defaultState } from "../util/defaultState";

const logoutSlice = createSlice({
  name: "logout",
  initialState: defaultState<void>(),
  reducers: {
    logoutLoading: (state) => {
      state.fetchStatus = "pending";
      return state;
    },
    logoutSuccess: (state) => {
      state.fetchStatus = "success";
      return state;
    },
    logoutFailed: (state, action: PayloadAction<MyKnownError>) => {
      state.fetchStatus = "failed";
      state.error = action.payload;
      return state;
    },
  },
});

export const { logoutLoading, logoutSuccess, logoutFailed } = logoutSlice.actions;
export default logoutSlice.reducer;

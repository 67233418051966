// Function used in the Gauntlet for the winners bracket
// It is a helper funtion to modify the data
// Given a winner's bracket, it will re-order the games
// The modified data will be given to the "toTree" function

//This function assumes that an array larger than 4 will be passed in
export function winnersBracket<T>(b: T[]): T[] {
  // exclude last two items
  let brackets = b.slice(0, b.length - 2);

  //get depth
  const depth = getDepth(brackets);

  //construct new array
  return nodesPerDepth(brackets, depth, []);
}

export function getDepth<T>(b: T[]): number {
  const log2 = (nodes: number) => Math.log(nodes) / Math.log(2);
  return Math.floor(log2(b.length));
}

function nodesPerDepth<T>(arr: T[], depth: number, collect: T[]): T[] {
  //base case
  if (depth === 0) {
    return [arr[0], ...collect];
  }

  //define how many nodes will be transferred
  const nodesPerCall = Math.pow(2, depth);

  //break up original array
  const arrPiece = arr.slice(0, nodesPerCall);
  const leftOver = arr.slice(nodesPerCall, arr.length);

  return nodesPerDepth(leftOver, depth - 1, [...arrPiece, ...collect]);
}

import React from "react";
import { PlayerAvatar } from "../api/roster";

export const conversion = (player: PlayerAvatar) => {
  if (!player) {
    return <></>;
  }

  const accessories = [
    "/images/avatar_suite_png_1.0/race_1/accessories/armband_1.png",
    "/images/avatar_suite_png_1.0/race_1/accessories/armband_2.png",
    "/images/avatar_suite_png_1.0/race_1/accessories/goggles.png",
    "/images/avatar_suite_png_1.0/race_1/accessories/headband_blue.png",
    "/images/avatar_suite_png_1.0/race_1/accessories/headband_red.png",
    "/images/avatar_suite_png_1.0/race_1/accessories/headband_yellow.png",
    "/images/avatar_suite_png_1.0/race_1/accessories/mask_black.png",
    "/images/avatar_suite_png_1.0/race_1/accessories/mask_red.png",
    "/images/avatar_suite_png_1.0/race_1/accessories/mouthguard.png",
    "/images/avatar_suite_png_1.0/race_1/accessories/tattoo_1.png",
    "/images/avatar_suite_png_1.0/race_1/accessories/tattoo_2.png",
    "/images/avatar_suite_png_1.0/race_1/accessories/tattoo_3.png",
    "/images/avatar_suite_png_1.0/race_1/accessories/tattoo_4.png",
    "/images/avatar_suite_png_1.0/race_1/accessories/tattoo_5.png",
  ];

  const jerseys = [
    "/images/avatar_suite_png_1.0/race_1/jerseys/Jersey_Black.png",
    "/images/avatar_suite_png_1.0/race_1/jerseys/Jersey_Blue.png",
    "/images/avatar_suite_png_1.0/race_1/jerseys/Jersey_Mixed.png",
    "/images/avatar_suite_png_1.0/race_1/jerseys/Jersey_Red.png",
  ];

  const mouthes = [
    "/images/avatar_suite_png_1.0/race_1/mouths/mouth_1.png",
    "/images/avatar_suite_png_1.0/race_1/mouths/mouth_10.png",
    "/images/avatar_suite_png_1.0/race_1/mouths/mouth_11.png",
    "/images/avatar_suite_png_1.0/race_1/mouths/mouth_12.png",
    "/images/avatar_suite_png_1.0/race_1/mouths/mouth_13.png",
    "/images/avatar_suite_png_1.0/race_1/mouths/mouth_17.png",
    "/images/avatar_suite_png_1.0/race_1/mouths/mouth_18.png",
    "/images/avatar_suite_png_1.0/race_1/mouths/mouth_19.png",
    "/images/avatar_suite_png_1.0/race_1/mouths/mouth_2.png",
    "/images/avatar_suite_png_1.0/race_1/mouths/mouth_20.png",
    "/images/avatar_suite_png_1.0/race_1/mouths/mouth_3.png",
    "/images/avatar_suite_png_1.0/race_1/mouths/mouth_4.png",
    "/images/avatar_suite_png_1.0/race_1/mouths/mouth_5.png",
    "/images/avatar_suite_png_1.0/race_1/mouths/mouth_6.png",
    "/images/avatar_suite_png_1.0/race_1/mouths/mouth_7.png",
    "/images/avatar_suite_png_1.0/race_1/mouths/mouth_8.png",
    "/images/avatar_suite_png_1.0/race_1/mouths/mouth_9.png",
    "/images/avatar_suite_png_1.0/race_1/mouths/mouth_14.png",
    "/images/avatar_suite_png_1.0/race_1/mouths/mouth_15.png",
    "/images/avatar_suite_png_1.0/race_1/mouths/mouth_16.png",
    "/images/avatar_suite_png_1.0/race_2/mouths/mouth_1.png",
    "/images/avatar_suite_png_1.0/race_2/mouths/mouth_10.png",
    "/images/avatar_suite_png_1.0/race_2/mouths/mouth_11.png",
    "/images/avatar_suite_png_1.0/race_2/mouths/mouth_12.png",
    "/images/avatar_suite_png_1.0/race_2/mouths/mouth_13.png",
    "/images/avatar_suite_png_1.0/race_2/mouths/mouth_17.png",
    "/images/avatar_suite_png_1.0/race_2/mouths/mouth_18.png",
    "/images/avatar_suite_png_1.0/race_2/mouths/mouth_19.png",
    "/images/avatar_suite_png_1.0/race_2/mouths/mouth_2.png",
    "/images/avatar_suite_png_1.0/race_2/mouths/mouth_20.png",
    "/images/avatar_suite_png_1.0/race_2/mouths/mouth_3.png",
    "/images/avatar_suite_png_1.0/race_2/mouths/mouth_4.png",
    "/images/avatar_suite_png_1.0/race_2/mouths/mouth_5.png",
    "/images/avatar_suite_png_1.0/race_2/mouths/mouth_6.png",
    "/images/avatar_suite_png_1.0/race_2/mouths/mouth_7.png",
    "/images/avatar_suite_png_1.0/race_2/mouths/mouth_8.png",
    "/images/avatar_suite_png_1.0/race_2/mouths/mouth_9.png",
    "/images/avatar_suite_png_1.0/race_2/mouths/mouth_14.png",
    "/images/avatar_suite_png_1.0/race_2/mouths/mouth_15.png",
    "/images/avatar_suite_png_1.0/race_2/mouths/mouth_16.png",
    "/images/avatar_suite_png_1.0/race_3/mouths/mouth_1.png",
    "/images/avatar_suite_png_1.0/race_3/mouths/mouth_10.png",
    "/images/avatar_suite_png_1.0/race_3/mouths/mouth_11.png",
    "/images/avatar_suite_png_1.0/race_3/mouths/mouth_12.png",
    "/images/avatar_suite_png_1.0/race_3/mouths/mouth_13.png",
    "/images/avatar_suite_png_1.0/race_3/mouths/mouth_17.png",
    "/images/avatar_suite_png_1.0/race_3/mouths/mouth_18.png",
    "/images/avatar_suite_png_1.0/race_3/mouths/mouth_19.png",
    "/images/avatar_suite_png_1.0/race_3/mouths/mouth_2.png",
    "/images/avatar_suite_png_1.0/race_3/mouths/mouth_20.png",
    "/images/avatar_suite_png_1.0/race_3/mouths/mouth_3.png",
    "/images/avatar_suite_png_1.0/race_3/mouths/mouth_4.png",
    "/images/avatar_suite_png_1.0/race_3/mouths/mouth_5.png",
    "/images/avatar_suite_png_1.0/race_3/mouths/mouth_6.png",
    "/images/avatar_suite_png_1.0/race_3/mouths/mouth_7.png",
    "/images/avatar_suite_png_1.0/race_3/mouths/mouth_8.png",
    "/images/avatar_suite_png_1.0/race_3/mouths/mouth_9.png",
    "/images/avatar_suite_png_1.0/race_3/mouths/mouth_14.png",
    "/images/avatar_suite_png_1.0/race_3/mouths/mouth_15.png",
    "/images/avatar_suite_png_1.0/race_3/mouths/mouth_16.png",
    "/images/avatar_suite_png_1.0/race_4/mouths/mouth_1.png",
    "/images/avatar_suite_png_1.0/race_4/mouths/mouth_10.png",
    "/images/avatar_suite_png_1.0/race_4/mouths/mouth_11.png",
    "/images/avatar_suite_png_1.0/race_4/mouths/mouth_12.png",
    "/images/avatar_suite_png_1.0/race_4/mouths/mouth_13.png",
    "/images/avatar_suite_png_1.0/race_4/mouths/mouth_17.png",
    "/images/avatar_suite_png_1.0/race_4/mouths/mouth_18.png",
    "/images/avatar_suite_png_1.0/race_4/mouths/mouth_19.png",
    "/images/avatar_suite_png_1.0/race_4/mouths/mouth_2.png",
    "/images/avatar_suite_png_1.0/race_4/mouths/mouth_20.png",
    "/images/avatar_suite_png_1.0/race_4/mouths/mouth_3.png",
    "/images/avatar_suite_png_1.0/race_4/mouths/mouth_4.png",
    "/images/avatar_suite_png_1.0/race_4/mouths/mouth_5.png",
    "/images/avatar_suite_png_1.0/race_4/mouths/mouth_6.png",
    "/images/avatar_suite_png_1.0/race_4/mouths/mouth_7.png",
    "/images/avatar_suite_png_1.0/race_4/mouths/mouth_8.png",
    "/images/avatar_suite_png_1.0/race_4/mouths/mouth_9.png",
    "/images/avatar_suite_png_1.0/race_4/mouths/mouth_14.png",
    "/images/avatar_suite_png_1.0/race_4/mouths/mouth_15.png",
    "/images/avatar_suite_png_1.0/race_4/mouths/mouth_16.png",
  ];

  const eyes = [
    "/images/avatar_suite_png_1.0/race_1/eyes/black/eyes_1.png",
    "/images/avatar_suite_png_1.0/race_1/eyes/black/eyes_10.png",
    "/images/avatar_suite_png_1.0/race_1/eyes/black/eyes_11.png",
    "/images/avatar_suite_png_1.0/race_1/eyes/black/eyes_12.png",
    "/images/avatar_suite_png_1.0/race_1/eyes/black/eyes_13.png",
    "/images/avatar_suite_png_1.0/race_1/eyes/black/eyes_14.png",
    "/images/avatar_suite_png_1.0/race_1/eyes/black/eyes_15.png",
    "/images/avatar_suite_png_1.0/race_1/eyes/black/eyes_16.png",
    "/images/avatar_suite_png_1.0/race_1/eyes/black/eyes_17.png",
    "/images/avatar_suite_png_1.0/race_1/eyes/black/eyes_18.png",
    "/images/avatar_suite_png_1.0/race_1/eyes/black/eyes_19.png",
    "/images/avatar_suite_png_1.0/race_1/eyes/black/eyes_2.png",
    "/images/avatar_suite_png_1.0/race_1/eyes/black/eyes_20.png",
    "/images/avatar_suite_png_1.0/race_1/eyes/black/eyes_3.png",
    "/images/avatar_suite_png_1.0/race_1/eyes/black/eyes_4.png",
    "/images/avatar_suite_png_1.0/race_1/eyes/black/eyes_5.png",
    "/images/avatar_suite_png_1.0/race_1/eyes/black/eyes_6.png",
    "/images/avatar_suite_png_1.0/race_1/eyes/black/eyes_7.png",
    "/images/avatar_suite_png_1.0/race_1/eyes/black/eyes_8.png",
    "/images/avatar_suite_png_1.0/race_1/eyes/black/eyes_9.png",
    "/images/avatar_suite_png_1.0/race_1/eyes/blue/eyes_1.png",
    "/images/avatar_suite_png_1.0/race_1/eyes/blue/eyes_10.png",
    "/images/avatar_suite_png_1.0/race_1/eyes/blue/eyes_11.png",
    "/images/avatar_suite_png_1.0/race_1/eyes/blue/eyes_12.png",
    "/images/avatar_suite_png_1.0/race_1/eyes/blue/eyes_13.png",
    "/images/avatar_suite_png_1.0/race_1/eyes/blue/eyes_14.png",
    "/images/avatar_suite_png_1.0/race_1/eyes/blue/eyes_15.png",
    "/images/avatar_suite_png_1.0/race_1/eyes/blue/eyes_16.png",
    "/images/avatar_suite_png_1.0/race_1/eyes/blue/eyes_17.png",
    "/images/avatar_suite_png_1.0/race_1/eyes/blue/eyes_18.png",
    "/images/avatar_suite_png_1.0/race_1/eyes/blue/eyes_19.png",
    "/images/avatar_suite_png_1.0/race_1/eyes/blue/eyes_2.png",
    "/images/avatar_suite_png_1.0/race_1/eyes/blue/eyes_20.png",
    "/images/avatar_suite_png_1.0/race_1/eyes/blue/eyes_3.png",
    "/images/avatar_suite_png_1.0/race_1/eyes/blue/eyes_4.png",
    "/images/avatar_suite_png_1.0/race_1/eyes/blue/eyes_5.png",
    "/images/avatar_suite_png_1.0/race_1/eyes/blue/eyes_6.png",
    "/images/avatar_suite_png_1.0/race_1/eyes/blue/eyes_7.png",
    "/images/avatar_suite_png_1.0/race_1/eyes/blue/eyes_8.png",
    "/images/avatar_suite_png_1.0/race_1/eyes/blue/eyes_9.png",
    "/images/avatar_suite_png_1.0/race_2/eyes/black/eyes_1.png",
    "/images/avatar_suite_png_1.0/race_2/eyes/black/eyes_10.png",
    "/images/avatar_suite_png_1.0/race_2/eyes/black/eyes_11.png",
    "/images/avatar_suite_png_1.0/race_2/eyes/black/eyes_12.png",
    "/images/avatar_suite_png_1.0/race_2/eyes/black/eyes_13.png",
    "/images/avatar_suite_png_1.0/race_2/eyes/black/eyes_14.png",
    "/images/avatar_suite_png_1.0/race_2/eyes/black/eyes_15.png",
    "/images/avatar_suite_png_1.0/race_2/eyes/black/eyes_16.png",
    "/images/avatar_suite_png_1.0/race_2/eyes/black/eyes_17.png",
    "/images/avatar_suite_png_1.0/race_2/eyes/black/eyes_18.png",
    "/images/avatar_suite_png_1.0/race_2/eyes/black/eyes_19.png",
    "/images/avatar_suite_png_1.0/race_2/eyes/black/eyes_2.png",
    "/images/avatar_suite_png_1.0/race_2/eyes/black/eyes_20.png",
    "/images/avatar_suite_png_1.0/race_2/eyes/black/eyes_3.png",
    "/images/avatar_suite_png_1.0/race_2/eyes/black/eyes_4.png",
    "/images/avatar_suite_png_1.0/race_2/eyes/black/eyes_5.png",
    "/images/avatar_suite_png_1.0/race_2/eyes/black/eyes_6.png",
    "/images/avatar_suite_png_1.0/race_2/eyes/black/eyes_7.png",
    "/images/avatar_suite_png_1.0/race_2/eyes/black/eyes_8.png",
    "/images/avatar_suite_png_1.0/race_2/eyes/black/eyes_9.png",
    "/images/avatar_suite_png_1.0/race_2/eyes/brown/eyes_1.png",
    "/images/avatar_suite_png_1.0/race_2/eyes/brown/eyes_10.png",
    "/images/avatar_suite_png_1.0/race_2/eyes/brown/eyes_11.png",
    "/images/avatar_suite_png_1.0/race_2/eyes/brown/eyes_12.png",
    "/images/avatar_suite_png_1.0/race_2/eyes/brown/eyes_13.png",
    "/images/avatar_suite_png_1.0/race_2/eyes/brown/eyes_14.png",
    "/images/avatar_suite_png_1.0/race_2/eyes/brown/eyes_15.png",
    "/images/avatar_suite_png_1.0/race_2/eyes/brown/eyes_16.png",
    "/images/avatar_suite_png_1.0/race_2/eyes/brown/eyes_17.png",
    "/images/avatar_suite_png_1.0/race_2/eyes/brown/eyes_18.png",
    "/images/avatar_suite_png_1.0/race_2/eyes/brown/eyes_19.png",
    "/images/avatar_suite_png_1.0/race_2/eyes/brown/eyes_2.png",
    "/images/avatar_suite_png_1.0/race_2/eyes/brown/eyes_20.png",
    "/images/avatar_suite_png_1.0/race_2/eyes/brown/eyes_3.png",
    "/images/avatar_suite_png_1.0/race_2/eyes/brown/eyes_4.png",
    "/images/avatar_suite_png_1.0/race_2/eyes/brown/eyes_5.png",
    "/images/avatar_suite_png_1.0/race_2/eyes/brown/eyes_6.png",
    "/images/avatar_suite_png_1.0/race_2/eyes/brown/eyes_7.png",
    "/images/avatar_suite_png_1.0/race_2/eyes/brown/eyes_8.png",
    "/images/avatar_suite_png_1.0/race_2/eyes/brown/eyes_9.png",
    "/images/avatar_suite_png_1.0/race_3/eyes/black/eyes_1.png",
    "/images/avatar_suite_png_1.0/race_3/eyes/black/eyes_10.png",
    "/images/avatar_suite_png_1.0/race_3/eyes/black/eyes_11.png",
    "/images/avatar_suite_png_1.0/race_3/eyes/black/eyes_12.png",
    "/images/avatar_suite_png_1.0/race_3/eyes/black/eyes_13.png",
    "/images/avatar_suite_png_1.0/race_3/eyes/black/eyes_14.png",
    "/images/avatar_suite_png_1.0/race_3/eyes/black/eyes_15.png",
    "/images/avatar_suite_png_1.0/race_3/eyes/black/eyes_16.png",
    "/images/avatar_suite_png_1.0/race_3/eyes/black/eyes_17.png",
    "/images/avatar_suite_png_1.0/race_3/eyes/black/eyes_18.png",
    "/images/avatar_suite_png_1.0/race_3/eyes/black/eyes_19.png",
    "/images/avatar_suite_png_1.0/race_3/eyes/black/eyes_2.png",
    "/images/avatar_suite_png_1.0/race_3/eyes/black/eyes_20.png",
    "/images/avatar_suite_png_1.0/race_3/eyes/black/eyes_3.png",
    "/images/avatar_suite_png_1.0/race_3/eyes/black/eyes_4.png",
    "/images/avatar_suite_png_1.0/race_3/eyes/black/eyes_5.png",
    "/images/avatar_suite_png_1.0/race_3/eyes/black/eyes_6.png",
    "/images/avatar_suite_png_1.0/race_3/eyes/black/eyes_7.png",
    "/images/avatar_suite_png_1.0/race_3/eyes/black/eyes_8.png",
    "/images/avatar_suite_png_1.0/race_3/eyes/black/eyes_9.png",
    "/images/avatar_suite_png_1.0/race_3/eyes/brown/eyes_1.png",
    "/images/avatar_suite_png_1.0/race_3/eyes/brown/eyes_10.png",
    "/images/avatar_suite_png_1.0/race_3/eyes/brown/eyes_11.png",
    "/images/avatar_suite_png_1.0/race_3/eyes/brown/eyes_12.png",
    "/images/avatar_suite_png_1.0/race_3/eyes/brown/eyes_13.png",
    "/images/avatar_suite_png_1.0/race_3/eyes/brown/eyes_14.png",
    "/images/avatar_suite_png_1.0/race_3/eyes/brown/eyes_15.png",
    "/images/avatar_suite_png_1.0/race_3/eyes/brown/eyes_16.png",
    "/images/avatar_suite_png_1.0/race_3/eyes/brown/eyes_17.png",
    "/images/avatar_suite_png_1.0/race_3/eyes/brown/eyes_18.png",
    "/images/avatar_suite_png_1.0/race_3/eyes/brown/eyes_19.png",
    "/images/avatar_suite_png_1.0/race_3/eyes/brown/eyes_2.png",
    "/images/avatar_suite_png_1.0/race_3/eyes/brown/eyes_20.png",
    "/images/avatar_suite_png_1.0/race_3/eyes/brown/eyes_3.png",
    "/images/avatar_suite_png_1.0/race_3/eyes/brown/eyes_4.png",
    "/images/avatar_suite_png_1.0/race_3/eyes/brown/eyes_5.png",
    "/images/avatar_suite_png_1.0/race_3/eyes/brown/eyes_6.png",
    "/images/avatar_suite_png_1.0/race_3/eyes/brown/eyes_7.png",
    "/images/avatar_suite_png_1.0/race_3/eyes/brown/eyes_8.png",
    "/images/avatar_suite_png_1.0/race_3/eyes/brown/eyes_9.png",
    "/images/avatar_suite_png_1.0/race_4/eyes/black/eyes_1.png",
    "/images/avatar_suite_png_1.0/race_4/eyes/black/eyes_10.png",
    "/images/avatar_suite_png_1.0/race_4/eyes/black/eyes_11.png",
    "/images/avatar_suite_png_1.0/race_4/eyes/black/eyes_12.png",
    "/images/avatar_suite_png_1.0/race_4/eyes/black/eyes_13.png",
    "/images/avatar_suite_png_1.0/race_4/eyes/black/eyes_14.png",
    "/images/avatar_suite_png_1.0/race_4/eyes/black/eyes_15.png",
    "/images/avatar_suite_png_1.0/race_4/eyes/black/eyes_16.png",
    "/images/avatar_suite_png_1.0/race_4/eyes/black/eyes_17.png",
    "/images/avatar_suite_png_1.0/race_4/eyes/black/eyes_18.png",
    "/images/avatar_suite_png_1.0/race_4/eyes/black/eyes_19.png",
    "/images/avatar_suite_png_1.0/race_4/eyes/black/eyes_2.png",
    "/images/avatar_suite_png_1.0/race_4/eyes/black/eyes_20.png",
    "/images/avatar_suite_png_1.0/race_4/eyes/black/eyes_3.png",
    "/images/avatar_suite_png_1.0/race_4/eyes/black/eyes_4.png",
    "/images/avatar_suite_png_1.0/race_4/eyes/black/eyes_5.png",
    "/images/avatar_suite_png_1.0/race_4/eyes/black/eyes_6.png",
    "/images/avatar_suite_png_1.0/race_4/eyes/black/eyes_7.png",
    "/images/avatar_suite_png_1.0/race_4/eyes/black/eyes_8.png",
    "/images/avatar_suite_png_1.0/race_4/eyes/black/eyes_9.png",
    "/images/avatar_suite_png_1.0/race_4/eyes/brown/eyes_1.png",
    "/images/avatar_suite_png_1.0/race_4/eyes/brown/eyes_10.png",
    "/images/avatar_suite_png_1.0/race_4/eyes/brown/eyes_11.png",
    "/images/avatar_suite_png_1.0/race_4/eyes/brown/eyes_12.png",
    "/images/avatar_suite_png_1.0/race_4/eyes/brown/eyes_13.png",
    "/images/avatar_suite_png_1.0/race_4/eyes/brown/eyes_14.png",
    "/images/avatar_suite_png_1.0/race_4/eyes/brown/eyes_15.png",
    "/images/avatar_suite_png_1.0/race_4/eyes/brown/eyes_16.png",
    "/images/avatar_suite_png_1.0/race_4/eyes/brown/eyes_17.png",
    "/images/avatar_suite_png_1.0/race_4/eyes/brown/eyes_18.png",
    "/images/avatar_suite_png_1.0/race_4/eyes/brown/eyes_19.png",
    "/images/avatar_suite_png_1.0/race_4/eyes/brown/eyes_2.png",
    "/images/avatar_suite_png_1.0/race_4/eyes/brown/eyes_20.png",
    "/images/avatar_suite_png_1.0/race_4/eyes/brown/eyes_3.png",
    "/images/avatar_suite_png_1.0/race_4/eyes/brown/eyes_4.png",
    "/images/avatar_suite_png_1.0/race_4/eyes/brown/eyes_5.png",
    "/images/avatar_suite_png_1.0/race_4/eyes/brown/eyes_6.png",
    "/images/avatar_suite_png_1.0/race_4/eyes/brown/eyes_7.png",
    "/images/avatar_suite_png_1.0/race_4/eyes/brown/eyes_8.png",
    "/images/avatar_suite_png_1.0/race_4/eyes/brown/eyes_9.png",
  ];

  const ears = [
    "/images/avatar_suite_png_1.0/race_1/ears/ear_1.png",
    "/images/avatar_suite_png_1.0/race_1/ears/ear_10.png",
    "/images/avatar_suite_png_1.0/race_1/ears/ear_11.png",
    "/images/avatar_suite_png_1.0/race_1/ears/ear_12.png",
    "/images/avatar_suite_png_1.0/race_1/ears/ear_13.png",
    "/images/avatar_suite_png_1.0/race_1/ears/ear_14.png",
    "/images/avatar_suite_png_1.0/race_1/ears/ear_15.png",
    "/images/avatar_suite_png_1.0/race_1/ears/ear_2.png",
    "/images/avatar_suite_png_1.0/race_1/ears/ear_3.png",
    "/images/avatar_suite_png_1.0/race_1/ears/ear_4.png",
    "/images/avatar_suite_png_1.0/race_1/ears/ear_5.png",
    "/images/avatar_suite_png_1.0/race_1/ears/ear_6.png",
    "/images/avatar_suite_png_1.0/race_1/ears/ear_7.png",
    "/images/avatar_suite_png_1.0/race_1/ears/ear_8.png",
    "/images/avatar_suite_png_1.0/race_1/ears/ear_9.png",
    "/images/avatar_suite_png_1.0/race_2/ears/ear_1.png",
    "/images/avatar_suite_png_1.0/race_2/ears/ear_10.png",
    "/images/avatar_suite_png_1.0/race_2/ears/ear_11.png",
    "/images/avatar_suite_png_1.0/race_2/ears/ear_12.png",
    "/images/avatar_suite_png_1.0/race_2/ears/ear_13.png",
    "/images/avatar_suite_png_1.0/race_2/ears/ear_14.png",
    "/images/avatar_suite_png_1.0/race_2/ears/ear_15.png",
    "/images/avatar_suite_png_1.0/race_2/ears/ear_2.png",
    "/images/avatar_suite_png_1.0/race_2/ears/ear_3.png",
    "/images/avatar_suite_png_1.0/race_2/ears/ear_4.png",
    "/images/avatar_suite_png_1.0/race_2/ears/ear_5.png",
    "/images/avatar_suite_png_1.0/race_2/ears/ear_6.png",
    "/images/avatar_suite_png_1.0/race_2/ears/ear_7.png",
    "/images/avatar_suite_png_1.0/race_2/ears/ear_8.png",
    "/images/avatar_suite_png_1.0/race_2/ears/ear_9.png",
    "/images/avatar_suite_png_1.0/race_3/ears/ear_1.png",
    "/images/avatar_suite_png_1.0/race_3/ears/ear_10.png",
    "/images/avatar_suite_png_1.0/race_3/ears/ear_11.png",
    "/images/avatar_suite_png_1.0/race_3/ears/ear_12.png",
    "/images/avatar_suite_png_1.0/race_3/ears/ear_13.png",
    "/images/avatar_suite_png_1.0/race_3/ears/ear_14.png",
    "/images/avatar_suite_png_1.0/race_3/ears/ear_15.png",
    "/images/avatar_suite_png_1.0/race_3/ears/ear_2.png",
    "/images/avatar_suite_png_1.0/race_3/ears/ear_3.png",
    "/images/avatar_suite_png_1.0/race_3/ears/ear_4.png",
    "/images/avatar_suite_png_1.0/race_3/ears/ear_5.png",
    "/images/avatar_suite_png_1.0/race_3/ears/ear_6.png",
    "/images/avatar_suite_png_1.0/race_3/ears/ear_7.png",
    "/images/avatar_suite_png_1.0/race_3/ears/ear_8.png",
    "/images/avatar_suite_png_1.0/race_3/ears/ear_9.png",
    "/images/avatar_suite_png_1.0/race_4/ears/ear_1.png",
    "/images/avatar_suite_png_1.0/race_4/ears/ear_10.png",
    "/images/avatar_suite_png_1.0/race_4/ears/ear_11.png",
    "/images/avatar_suite_png_1.0/race_4/ears/ear_12.png",
    "/images/avatar_suite_png_1.0/race_4/ears/ear_13.png",
    "/images/avatar_suite_png_1.0/race_4/ears/ear_14.png",
    "/images/avatar_suite_png_1.0/race_4/ears/ear_15.png",
    "/images/avatar_suite_png_1.0/race_4/ears/ear_2.png",
    "/images/avatar_suite_png_1.0/race_4/ears/ear_3.png",
    "/images/avatar_suite_png_1.0/race_4/ears/ear_4.png",
    "/images/avatar_suite_png_1.0/race_4/ears/ear_5.png",
    "/images/avatar_suite_png_1.0/race_4/ears/ear_6.png",
    "/images/avatar_suite_png_1.0/race_4/ears/ear_7.png",
    "/images/avatar_suite_png_1.0/race_4/ears/ear_8.png",
    "/images/avatar_suite_png_1.0/race_4/ears/ear_9.png",
  ];
  const noses = [
    "/images/avatar_suite_png_1.0/race_1/noses/nose_1.png",
    "/images/avatar_suite_png_1.0/race_1/noses/nose_10.png",
    "/images/avatar_suite_png_1.0/race_1/noses/nose_11.png",
    "/images/avatar_suite_png_1.0/race_1/noses/nose_12.png",
    "/images/avatar_suite_png_1.0/race_1/noses/nose_13.png",
    "/images/avatar_suite_png_1.0/race_1/noses/nose_14.png",
    "/images/avatar_suite_png_1.0/race_1/noses/nose_15.png",
    "/images/avatar_suite_png_1.0/race_1/noses/nose_16.png",
    "/images/avatar_suite_png_1.0/race_1/noses/nose_17.png",
    "/images/avatar_suite_png_1.0/race_1/noses/nose_18.png",
    "/images/avatar_suite_png_1.0/race_1/noses/nose_19.png",
    "/images/avatar_suite_png_1.0/race_1/noses/nose_2.png",
    "/images/avatar_suite_png_1.0/race_1/noses/nose_20.png",
    "/images/avatar_suite_png_1.0/race_1/noses/nose_3.png",
    "/images/avatar_suite_png_1.0/race_1/noses/nose_4.png",
    "/images/avatar_suite_png_1.0/race_1/noses/nose_5.png",
    "/images/avatar_suite_png_1.0/race_1/noses/nose_6.png",
    "/images/avatar_suite_png_1.0/race_1/noses/nose_7.png",
    "/images/avatar_suite_png_1.0/race_1/noses/nose_8.png",
    "/images/avatar_suite_png_1.0/race_1/noses/nose_9.png",
    "/images/avatar_suite_png_1.0/race_2/noses/nose_1.png",
    "/images/avatar_suite_png_1.0/race_2/noses/nose_10.png",
    "/images/avatar_suite_png_1.0/race_2/noses/nose_11.png",
    "/images/avatar_suite_png_1.0/race_2/noses/nose_12.png",
    "/images/avatar_suite_png_1.0/race_2/noses/nose_13.png",
    "/images/avatar_suite_png_1.0/race_2/noses/nose_14.png",
    "/images/avatar_suite_png_1.0/race_2/noses/nose_15.png",
    "/images/avatar_suite_png_1.0/race_2/noses/nose_16.png",
    "/images/avatar_suite_png_1.0/race_2/noses/nose_17.png",
    "/images/avatar_suite_png_1.0/race_2/noses/nose_18.png",
    "/images/avatar_suite_png_1.0/race_2/noses/nose_19.png",
    "/images/avatar_suite_png_1.0/race_2/noses/nose_2.png",
    "/images/avatar_suite_png_1.0/race_2/noses/nose_20.png",
    "/images/avatar_suite_png_1.0/race_2/noses/nose_3.png",
    "/images/avatar_suite_png_1.0/race_2/noses/nose_4.png",
    "/images/avatar_suite_png_1.0/race_2/noses/nose_5.png",
    "/images/avatar_suite_png_1.0/race_2/noses/nose_6.png",
    "/images/avatar_suite_png_1.0/race_2/noses/nose_7.png",
    "/images/avatar_suite_png_1.0/race_2/noses/nose_8.png",
    "/images/avatar_suite_png_1.0/race_2/noses/nose_9.png",
    "/images/avatar_suite_png_1.0/race_3/noses/nose_1.png",
    "/images/avatar_suite_png_1.0/race_3/noses/nose_10.png",
    "/images/avatar_suite_png_1.0/race_3/noses/nose_11.png",
    "/images/avatar_suite_png_1.0/race_3/noses/nose_12.png",
    "/images/avatar_suite_png_1.0/race_3/noses/nose_13.png",
    "/images/avatar_suite_png_1.0/race_3/noses/nose_14.png",
    "/images/avatar_suite_png_1.0/race_3/noses/nose_15.png",
    "/images/avatar_suite_png_1.0/race_3/noses/nose_16.png",
    "/images/avatar_suite_png_1.0/race_3/noses/nose_17.png",
    "/images/avatar_suite_png_1.0/race_3/noses/nose_18.png",
    "/images/avatar_suite_png_1.0/race_3/noses/nose_19.png",
    "/images/avatar_suite_png_1.0/race_3/noses/nose_2.png",
    "/images/avatar_suite_png_1.0/race_3/noses/nose_20.png",
    "/images/avatar_suite_png_1.0/race_3/noses/nose_3.png",
    "/images/avatar_suite_png_1.0/race_3/noses/nose_4.png",
    "/images/avatar_suite_png_1.0/race_3/noses/nose_5.png",
    "/images/avatar_suite_png_1.0/race_3/noses/nose_6.png",
    "/images/avatar_suite_png_1.0/race_3/noses/nose_7.png",
    "/images/avatar_suite_png_1.0/race_3/noses/nose_8.png",
    "/images/avatar_suite_png_1.0/race_3/noses/nose_9.png",
    "/images/avatar_suite_png_1.0/race_4/noses/nose_1.png",
    "/images/avatar_suite_png_1.0/race_4/noses/nose_10.png",
    "/images/avatar_suite_png_1.0/race_4/noses/nose_11.png",
    "/images/avatar_suite_png_1.0/race_4/noses/nose_12.png",
    "/images/avatar_suite_png_1.0/race_4/noses/nose_13.png",
    "/images/avatar_suite_png_1.0/race_4/noses/nose_14.png",
    "/images/avatar_suite_png_1.0/race_4/noses/nose_15.png",
    "/images/avatar_suite_png_1.0/race_4/noses/nose_16.png",
    "/images/avatar_suite_png_1.0/race_4/noses/nose_17.png",
    "/images/avatar_suite_png_1.0/race_4/noses/nose_18.png",
    "/images/avatar_suite_png_1.0/race_4/noses/nose_19.png",
    "/images/avatar_suite_png_1.0/race_4/noses/nose_2.png",
    "/images/avatar_suite_png_1.0/race_4/noses/nose_20.png",
    "/images/avatar_suite_png_1.0/race_4/noses/nose_3.png",
    "/images/avatar_suite_png_1.0/race_4/noses/nose_4.png",
    "/images/avatar_suite_png_1.0/race_4/noses/nose_5.png",
    "/images/avatar_suite_png_1.0/race_4/noses/nose_6.png",
    "/images/avatar_suite_png_1.0/race_4/noses/nose_7.png",
    "/images/avatar_suite_png_1.0/race_4/noses/nose_8.png",
    "/images/avatar_suite_png_1.0/race_4/noses/nose_9.png",
  ];

  const faces = [
    "/images/avatar_suite_png_1.0/race_1/faceTypes/diamond.png",
    "/images/avatar_suite_png_1.0/race_1/faceTypes/oblong.png",
    "/images/avatar_suite_png_1.0/race_1/faceTypes/round.png",
    "/images/avatar_suite_png_1.0/race_1/faceTypes/thinner.png",
    "/images/avatar_suite_png_1.0/race_1/faceTypes/wider.png",
    "/images/avatar_suite_png_1.0/race_2/face types/diamond.png",
    "/images/avatar_suite_png_1.0/race_2/face types/oblong.png",
    "/images/avatar_suite_png_1.0/race_2/face types/round.png",
    "/images/avatar_suite_png_1.0/race_2/face types/thinner.png",
    "/images/avatar_suite_png_1.0/race_2/face types/wider.png",
    "/images/avatar_suite_png_1.0/race_3/face types/diamond.png",
    "/images/avatar_suite_png_1.0/race_3/face types/oblong.png",
    "/images/avatar_suite_png_1.0/race_3/face types/round.png",
    "/images/avatar_suite_png_1.0/race_3/face types/thinner.png",
    "/images/avatar_suite_png_1.0/race_3/face types/wider.png",
    "/images/avatar_suite_png_1.0/race_4/face types/diamond.png",
    "/images/avatar_suite_png_1.0/race_4/face types/oblong.png",
    "/images/avatar_suite_png_1.0/race_4/face types/round.png",
    "/images/avatar_suite_png_1.0/race_4/face types/thinner.png",
    "/images/avatar_suite_png_1.0/race_4/face types/wider.png",
  ];

  const facialHair: { [index: string]: any } = {
    1: [
      "/images/avatar_suite_png_1.0/race_1/facialHair/black/diamond/Beard_1.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/black/diamond/Beard_10_All Face Types.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/black/diamond/Beard_12_All Face Types.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/black/diamond/Beard_13_All Face Types.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/black/diamond/Beard_14_All Face Types.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/black/diamond/Beard_15_All Face Types.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/black/diamond/Beard_16_All Face Types.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/black/diamond/Beard_17_All Face Types.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/black/diamond/Beard_18_Diamond & Oblong.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/black/diamond/Beard_19_All Face Types.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/black/diamond/Beard_20_All Face Types.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/black/diamond/Beard_2_All Face Types.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/black/diamond/Beard_3_All Face Types.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/black/diamond/Beard_4_All except Wider.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/black/diamond/Beard_6_All Face Types.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/black/diamond/Beard_7_All Face Types.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/black/oblong/Beard_10_All Face Types.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/black/oblong/Beard_11_Oblong & Round.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/black/oblong/Beard_12_All Face Types.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/black/oblong/Beard_13_All Face Types.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/black/oblong/Beard_14_All Face Types.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/black/oblong/Beard_15_All Face Types.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/black/oblong/Beard_16_All Face Types.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/black/oblong/Beard_17_All Face Types.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/black/oblong/Beard_18_Diamond & Oblong.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/black/oblong/Beard_19_All Face Types.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/black/oblong/Beard_1_All except Wider.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/black/oblong/Beard_20_All Face Types.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/black/oblong/Beard_2_All Face Types.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/black/oblong/Beard_3_All Face Types.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/black/oblong/Beard_4_All except Wider.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/black/oblong/Beard_5_Thinner & Oblong.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/black/oblong/Beard_6_All Face Types.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/black/oblong/Beard_7_All Face Types.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/black/oblong/Beard_8_Thinner & Oblong.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/black/oblong/Beard_9_Thinner & Oblong.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/black/round/Beard_10_All Face Types.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/black/round/Beard_11_Oblong & Round.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/black/round/Beard_12_All Face Types.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/black/round/Beard_13_All Face Types.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/black/round/Beard_14_All Face Types.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/black/round/Beard_15_All Face Types.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/black/round/Beard_16_All Face Types.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/black/round/Beard_17_All Face Types.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/black/round/Beard_19_All Face Types.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/black/round/Beard_1_All except Wider.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/black/round/Beard_20_All Face Types.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/black/round/Beard_2_All Face Types.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/black/round/Beard_3_All Face Types.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/black/round/Beard_4_All except Wider.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/black/round/Beard_6_All Face Types.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/black/round/Beard_7_All Face Types.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/black/thinner/Beard_10_All Face Types.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/black/thinner/Beard_12_All Face Types.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/black/thinner/Beard_13_All Face Types.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/black/thinner/Beard_14_All Face Types.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/black/thinner/Beard_15_All Face Types.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/black/thinner/Beard_16_All Face Types.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/black/thinner/Beard_17_All Face Types.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/black/thinner/Beard_19_All Face Types.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/black/thinner/Beard_1_All except Wider.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/black/thinner/Beard_20_All Face Types.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/black/thinner/Beard_2_All Face Types.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/black/thinner/Beard_3_All Face Types.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/black/thinner/Beard_4_All except Wider.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/black/thinner/Beard_5_Thinner & Oblong.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/black/thinner/Beard_6_All Face Types.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/black/thinner/Beard_7_All Face Types.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/black/thinner/Beard_8_Thinner & Oblong.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/black/thinner/Beard_9_Thinner & Oblong.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/black/wider/Beard_10_All Face Types.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/black/wider/Beard_12_All Face Types.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/black/wider/Beard_13_All Face Types.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/black/wider/Beard_14_All Face Types.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/black/wider/Beard_15_All Face Types.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/black/wider/Beard_16_All Face Types.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/black/wider/Beard_17_All Face Types.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/black/wider/Beard_19_All Face Types.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/black/wider/Beard_20_All Face Types.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/black/wider/Beard_2_All Face Types.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/black/wider/Beard_3_All Face Types.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/black/wider/Beard_6_All Face Types.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/black/wider/Beard_7_All Face Types.png",
    ],
    2: [
      "/images/avatar_suite_png_1.0/race_1/facialHair/blonde/diamond/Beard_10_blonde_All Face Types.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/blonde/diamond/Beard_12_blonde_All Face Types.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/blonde/diamond/Beard_13_blonde_All Face Types.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/blonde/diamond/Beard_14_Blonde_All Face Types.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/blonde/diamond/Beard_15_blonde_All Face Types.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/blonde/diamond/Beard_16_Blonde_All Face Types.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/blonde/diamond/Beard_17_blonde_All Face Types.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/blonde/diamond/Beard_18_blonde_Diamond & Oblong.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/blonde/diamond/Beard_19_blonde_All Face Types.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/blonde/diamond/Beard_1_blonde_All except Wider.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/blonde/diamond/Beard_20_blonde_All Face Types.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/blonde/diamond/Beard_2_blonde_All Face Types.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/blonde/diamond/Beard_3_Blonde_All Face Types.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/blonde/diamond/Beard_4_Blonde_All except Wider.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/blonde/diamond/Beard_6_Blonde_All Face Types.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/blonde/diamond/Beard_7_Blonde_All Face Types.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/blonde/oblong/Beard_10_blonde_All Face Types.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/blonde/oblong/Beard_11_blonde_Oblong & Round.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/blonde/oblong/Beard_12_blonde_All Face Types.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/blonde/oblong/Beard_13_blonde_All Face Types.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/blonde/oblong/Beard_14_Blonde_All Face Types.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/blonde/oblong/Beard_15_blonde_All Face Types.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/blonde/oblong/Beard_16_Blonde_All Face Types.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/blonde/oblong/Beard_17_blonde_All Face Types.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/blonde/oblong/Beard_18_blonde_Diamond & Oblong.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/blonde/oblong/Beard_19_blonde_All Face Types.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/blonde/oblong/Beard_1_blonde_All except Wider.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/blonde/oblong/Beard_20_blonde_All Face Types.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/blonde/oblong/Beard_2_blonde_All Face Types.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/blonde/oblong/Beard_3_Blonde_All Face Types.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/blonde/oblong/Beard_4_Blonde_All except Wider.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/blonde/oblong/Beard_5_Blonde_Thinner & Oblong.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/blonde/oblong/Beard_6_Blonde_All Face Types.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/blonde/oblong/Beard_7_Blonde_All Face Types.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/blonde/oblong/Beard_8_blonde_Thinner & Oblong.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/blonde/oblong/Beard_9_blonde_Thinner & Oblong.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/blonde/round/Beard_10_blonde_All Face Types.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/blonde/round/Beard_11_blonde_Oblong & Round.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/blonde/round/Beard_12_blonde_All Face Types.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/blonde/round/Beard_13_blonde_All Face Types.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/blonde/round/Beard_14_Blonde_All Face Types.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/blonde/round/Beard_15_blonde_All Face Types.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/blonde/round/Beard_16_Blonde_All Face Types.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/blonde/round/Beard_17_blonde_All Face Types.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/blonde/round/Beard_19_blonde_All Face Types.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/blonde/round/Beard_1_blonde_All except Wider.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/blonde/round/Beard_20_blonde_All Face Types.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/blonde/round/Beard_2_blonde_All Face Types.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/blonde/round/Beard_3_Blonde_All Face Types.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/blonde/round/Beard_4_Blonde_All except Wider.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/blonde/round/Beard_6_Blonde_All Face Types.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/blonde/round/Beard_7_Blonde_All Face Types.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/blonde/thinner/Beard_10_blonde_All Face Types.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/blonde/thinner/Beard_12_blonde_All Face Types.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/blonde/thinner/Beard_13_blonde_All Face Types.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/blonde/thinner/Beard_14_Blonde_All Face Types.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/blonde/thinner/Beard_15_blonde_All Face Types.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/blonde/thinner/Beard_16_Blonde_All Face Types.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/blonde/thinner/Beard_17_blonde_All Face Types.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/blonde/thinner/Beard_19_blonde_All Face Types.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/blonde/thinner/Beard_1_blonde_All except Wider.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/blonde/thinner/Beard_20_blonde_All Face Types.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/blonde/thinner/Beard_2_blonde_All Face Types.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/blonde/thinner/Beard_3_Blonde_All Face Types.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/blonde/thinner/Beard_4_Blonde_All except Wider.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/blonde/thinner/Beard_5_Blonde_Thinner & Oblong.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/blonde/thinner/Beard_6_Blonde_All Face Types.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/blonde/thinner/Beard_7_Blonde_All Face Types.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/blonde/thinner/Beard_8_blonde_Thinner & Oblong.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/blonde/thinner/Beard_9_blonde_Thinner & Oblong.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/blonde/wider/Beard_10_blonde_All Face Types.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/blonde/wider/Beard_12_blonde_All Face Types.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/blonde/wider/Beard_13_blonde_All Face Types.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/blonde/wider/Beard_14_Blonde_All Face Types.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/blonde/wider/Beard_15_blonde_All Face Types.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/blonde/wider/Beard_16_Blonde_All Face Types.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/blonde/wider/Beard_17_blonde_All Face Types.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/blonde/wider/Beard_19_blonde_All Face Types.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/blonde/wider/Beard_20_blonde_All Face Types.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/blonde/wider/Beard_2_blonde_All Face Types.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/blonde/wider/Beard_3_Blonde_All Face Types.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/blonde/wider/Beard_6_Blonde_All Face Types.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/blonde/wider/Beard_7_Blonde_All Face Types.png",
    ],
    3: [
      "/images/avatar_suite_png_1.0/race_1/facialHair/brown/diamond/Beard_10_brown_All Face Types.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/brown/diamond/Beard_12 _brown_All Face Types.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/brown/diamond/Beard_13_brown_All Face Types.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/brown/diamond/Beard_14__Brown_All Face Types.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/brown/diamond/Beard_15_brown_All Face Types.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/brown/diamond/Beard_16_Brown_All Face Types.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/brown/diamond/Beard_17_Brown_All Face Types.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/brown/diamond/Beard_18_Brown_Diamond & Oblong.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/brown/diamond/Beard_19_Brown_All Face Types.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/brown/diamond/Beard_1_brown_All except Wider.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/brown/diamond/Beard_20_Brown_All Face Types.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/brown/diamond/Beard_2_Brown_All Face Types.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/brown/diamond/Beard_3_Brown_All Face Types.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/brown/diamond/Beard_4_Brown_All except Wider.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/brown/diamond/Beard_6_Brown_All Face Types.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/brown/diamond/Beard_7_Brown_All Face Types.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/brown/oblong/Beard_10_brown_All Face Types.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/brown/oblong/Beard_11_brown_Oblong & Round.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/brown/oblong/Beard_12 _brown_All Face Types.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/brown/oblong/Beard_13_brown_All Face Types.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/brown/oblong/Beard_14__Brown_All Face Types.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/brown/oblong/Beard_15_brown_All Face Types.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/brown/oblong/Beard_16_Brown_All Face Types.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/brown/oblong/Beard_17_Brown_All Face Types.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/brown/oblong/Beard_18_Brown_Diamond & Oblong.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/brown/oblong/Beard_19_Brown_All Face Types.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/brown/oblong/Beard_1_brown_All except Wider.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/brown/oblong/Beard_20_Brown_All Face Types.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/brown/oblong/Beard_2_Brown_All Face Types.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/brown/oblong/Beard_3_Brown_All Face Types.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/brown/oblong/Beard_4_Brown_All except Wider.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/brown/oblong/Beard_5_Brown_Thinner & Oblong.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/brown/oblong/Beard_6_Brown_All Face Types.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/brown/oblong/Beard_7_Brown_All Face Types.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/brown/oblong/Beard_8_Brown_Thinner & Oblong.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/brown/oblong/Beard_9_brown_Thinner & Oblong.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/brown/round/Beard_10_brown_All Face Types.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/brown/round/Beard_11_brown_Oblong & Round.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/brown/round/Beard_12 _brown_All Face Types.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/brown/round/Beard_13_brown_All Face Types.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/brown/round/Beard_14__Brown_All Face Types.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/brown/round/Beard_15_brown_All Face Types.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/brown/round/Beard_16_Brown_All Face Types.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/brown/round/Beard_17_Brown_All Face Types.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/brown/round/Beard_19_Brown_All Face Types.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/brown/round/Beard_1_brown_All except Wider.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/brown/round/Beard_20_Brown_All Face Types.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/brown/round/Beard_2_Brown_All Face Types.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/brown/round/Beard_3_Brown_All Face Types.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/brown/round/Beard_4_Brown_All except Wider.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/brown/round/Beard_6_Brown_All Face Types.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/brown/round/Beard_7_Brown_All Face Types.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/brown/thinner/Beard_10_brown_All Face Types.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/brown/thinner/Beard_12 _brown_All Face Types.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/brown/thinner/Beard_13_brown_All Face Types.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/brown/thinner/Beard_14__Brown_All Face Types.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/brown/thinner/Beard_15_brown_All Face Types.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/brown/thinner/Beard_16_Brown_All Face Types.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/brown/thinner/Beard_17_Brown_All Face Types.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/brown/thinner/Beard_19_Brown_All Face Types.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/brown/thinner/Beard_1_brown_All except Wider.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/brown/thinner/Beard_20_Brown_All Face Types.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/brown/thinner/Beard_2_Brown_All Face Types.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/brown/thinner/Beard_3_Brown_All Face Types.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/brown/thinner/Beard_4_Brown_All except Wider.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/brown/thinner/Beard_5_Brown_Thinner & Oblong.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/brown/thinner/Beard_6_Brown_All Face Types.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/brown/thinner/Beard_7_Brown_All Face Types.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/brown/thinner/Beard_8_Brown_Thinner & Oblong.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/brown/thinner/Beard_9_brown_Thinner & Oblong.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/brown/wider/Beard_10_brown_All Face Types.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/brown/wider/Beard_12 _brown_All Face Types.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/brown/wider/Beard_13_brown_All Face Types.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/brown/wider/Beard_14__Brown_All Face Types.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/brown/wider/Beard_15_brown_All Face Types.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/brown/wider/Beard_16_Brown_All Face Types.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/brown/wider/Beard_17_Brown_All Face Types.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/brown/wider/Beard_19_Brown_All Face Types.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/brown/wider/Beard_20_Brown_All Face Types.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/brown/wider/Beard_2_Brown_All Face Types.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/brown/wider/Beard_3_Brown_All Face Types.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/brown/wider/Beard_6_Brown_All Face Types.png",
      "/images/avatar_suite_png_1.0/race_1/facialHair/brown/wider/Beard_7_Brown_All Face Types.png",
    ],
  };

  const haircuts = [
    "/images/avatar_suite_png_1.0/race_1/haircuts/black/hair_1.png",
    "/images/avatar_suite_png_1.0/race_1/haircuts/black/hair_10.png",
    "/images/avatar_suite_png_1.0/race_1/haircuts/black/hair_11.png",
    "/images/avatar_suite_png_1.0/race_1/haircuts/black/hair_12.png",
    "/images/avatar_suite_png_1.0/race_1/haircuts/black/hair_13.png",
    "/images/avatar_suite_png_1.0/race_1/haircuts/black/hair_14.png",
    "/images/avatar_suite_png_1.0/race_1/haircuts/black/hair_15.png",
    "/images/avatar_suite_png_1.0/race_1/haircuts/black/hair_16.png",
    "/images/avatar_suite_png_1.0/race_1/haircuts/black/hair_17.png",
    "/images/avatar_suite_png_1.0/race_1/haircuts/black/hair_18.png",
    "/images/avatar_suite_png_1.0/race_1/haircuts/black/hair_19.png",
    "/images/avatar_suite_png_1.0/race_1/haircuts/black/hair_2.png",
    "/images/avatar_suite_png_1.0/race_1/haircuts/black/hair_20.png",
    "/images/avatar_suite_png_1.0/race_1/haircuts/black/hair_3.png",
    "/images/avatar_suite_png_1.0/race_1/haircuts/black/hair_4.png",
    "/images/avatar_suite_png_1.0/race_1/haircuts/black/hair_5.png",
    "/images/avatar_suite_png_1.0/race_1/haircuts/black/hair_6.png",
    "/images/avatar_suite_png_1.0/race_1/haircuts/black/hair_7.png",
    "/images/avatar_suite_png_1.0/race_1/haircuts/black/hair_8.png",
    "/images/avatar_suite_png_1.0/race_1/haircuts/black/hair_9.png",
    "/images/avatar_suite_png_1.0/race_1/haircuts/blonde/Hair_Blonde_1.png",
    "/images/avatar_suite_png_1.0/race_1/haircuts/blonde/Hair_Blonde_10.png",
    "/images/avatar_suite_png_1.0/race_1/haircuts/blonde/Hair_Blonde_13.png",
    "/images/avatar_suite_png_1.0/race_1/haircuts/blonde/Hair_Blonde_14.png",
    "/images/avatar_suite_png_1.0/race_1/haircuts/blonde/Hair_Blonde_2.png",
    "/images/avatar_suite_png_1.0/race_1/haircuts/blonde/Hair_Blonde_3.png",
    "/images/avatar_suite_png_1.0/race_1/haircuts/blonde/Hair_Blonde_5.png",
    "/images/avatar_suite_png_1.0/race_1/haircuts/blonde/Hair_Blonde_9.png",
    "/images/avatar_suite_png_1.0/race_1/haircuts/blonde/Hair_blonde_15.png",
    "/images/avatar_suite_png_1.0/race_1/haircuts/blonde/Hair_blonde_16.png",
    "/images/avatar_suite_png_1.0/race_1/haircuts/blonde/Hair_blonde_19.png",
    "/images/avatar_suite_png_1.0/race_1/haircuts/blonde/Hair_blonde_20.png",
    "/images/avatar_suite_png_1.0/race_1/haircuts/blonde/Hair_brown_16.png",
    "/images/avatar_suite_png_1.0/race_1/haircuts/brown/Hair_Blonde_13.png",
    "/images/avatar_suite_png_1.0/race_1/haircuts/brown/Hair_Brown_1.png",
    "/images/avatar_suite_png_1.0/race_1/haircuts/brown/Hair_Brown_13.png",
    "/images/avatar_suite_png_1.0/race_1/haircuts/brown/Hair_Brown_14.png",
    "/images/avatar_suite_png_1.0/race_1/haircuts/brown/Hair_Brown_2.png",
    "/images/avatar_suite_png_1.0/race_1/haircuts/brown/Hair_Brown_3.png",
    "/images/avatar_suite_png_1.0/race_1/haircuts/brown/Hair_Brown_5.png",
    "/images/avatar_suite_png_1.0/race_1/haircuts/brown/Hair_brown_15.png",
    "/images/avatar_suite_png_1.0/race_1/haircuts/brown/Hair_brown_16.png",
    "/images/avatar_suite_png_1.0/race_1/haircuts/brown/Hair_brown_19.png",
    "/images/avatar_suite_png_1.0/race_1/haircuts/brown/Hair_brown_20.png",
    "/images/avatar_suite_png_1.0/race_2/haircuts/black/hair_1.png",
    "/images/avatar_suite_png_1.0/race_2/haircuts/black/hair_10.png",
    "/images/avatar_suite_png_1.0/race_2/haircuts/black/hair_11.png",
    "/images/avatar_suite_png_1.0/race_2/haircuts/black/hair_12.png",
    "/images/avatar_suite_png_1.0/race_2/haircuts/black/hair_13.png",
    "/images/avatar_suite_png_1.0/race_2/haircuts/black/hair_14.png",
    "/images/avatar_suite_png_1.0/race_2/haircuts/black/hair_15.png",
    "/images/avatar_suite_png_1.0/race_2/haircuts/black/hair_16.png",
    "/images/avatar_suite_png_1.0/race_2/haircuts/black/hair_17.png",
    "/images/avatar_suite_png_1.0/race_2/haircuts/black/hair_18.png",
    "/images/avatar_suite_png_1.0/race_2/haircuts/black/hair_19.png",
    "/images/avatar_suite_png_1.0/race_2/haircuts/black/hair_2.png",
    "/images/avatar_suite_png_1.0/race_2/haircuts/black/hair_20.png",
    "/images/avatar_suite_png_1.0/race_2/haircuts/black/hair_3.png",
    "/images/avatar_suite_png_1.0/race_2/haircuts/black/hair_4.png",
    "/images/avatar_suite_png_1.0/race_2/haircuts/black/hair_5.png",
    "/images/avatar_suite_png_1.0/race_2/haircuts/black/hair_6.png",
    "/images/avatar_suite_png_1.0/race_2/haircuts/black/hair_7.png",
    "/images/avatar_suite_png_1.0/race_2/haircuts/black/hair_8.png",
    "/images/avatar_suite_png_1.0/race_2/haircuts/black/hair_9.png",
    "/images/avatar_suite_png_1.0/race_2/haircuts/blonde/Hair_Blonde_1.png",
    "/images/avatar_suite_png_1.0/race_2/haircuts/blonde/Hair_Blonde_10.png",
    "/images/avatar_suite_png_1.0/race_2/haircuts/blonde/Hair_Blonde_13.png",
    "/images/avatar_suite_png_1.0/race_2/haircuts/blonde/Hair_Blonde_14.png",
    "/images/avatar_suite_png_1.0/race_2/haircuts/blonde/Hair_Blonde_2.png",
    "/images/avatar_suite_png_1.0/race_2/haircuts/blonde/Hair_Blonde_3.png",
    "/images/avatar_suite_png_1.0/race_2/haircuts/blonde/Hair_Blonde_5.png",
    "/images/avatar_suite_png_1.0/race_2/haircuts/blonde/Hair_Blonde_9.png",
    "/images/avatar_suite_png_1.0/race_2/haircuts/blonde/Hair_blonde_15.png",
    "/images/avatar_suite_png_1.0/race_2/haircuts/blonde/Hair_blonde_16.png",
    "/images/avatar_suite_png_1.0/race_2/haircuts/blonde/Hair_blonde_19.png",
    "/images/avatar_suite_png_1.0/race_2/haircuts/blonde/Hair_blonde_20.png",
    "/images/avatar_suite_png_1.0/race_2/haircuts/blonde/Hair_brown_16.png",
    "/images/avatar_suite_png_1.0/race_2/haircuts/brown/Hair_Blonde_13.png",
    "/images/avatar_suite_png_1.0/race_2/haircuts/brown/Hair_Brown_1.png",
    "/images/avatar_suite_png_1.0/race_2/haircuts/brown/Hair_Brown_13.png",
    "/images/avatar_suite_png_1.0/race_2/haircuts/brown/Hair_Brown_14.png",
    "/images/avatar_suite_png_1.0/race_2/haircuts/brown/Hair_Brown_2.png",
    "/images/avatar_suite_png_1.0/race_2/haircuts/brown/Hair_Brown_3.png",
    "/images/avatar_suite_png_1.0/race_2/haircuts/brown/Hair_Brown_5.png",
    "/images/avatar_suite_png_1.0/race_2/haircuts/brown/Hair_brown_15.png",
    "/images/avatar_suite_png_1.0/race_2/haircuts/brown/Hair_brown_16.png",
    "/images/avatar_suite_png_1.0/race_2/haircuts/brown/Hair_brown_19.png",
    "/images/avatar_suite_png_1.0/race_2/haircuts/brown/Hair_brown_20.png",
    "/images/avatar_suite_png_1.0/race_3/haircuts/black/hair_1.png",
    "/images/avatar_suite_png_1.0/race_3/haircuts/black/hair_10.png",
    "/images/avatar_suite_png_1.0/race_3/haircuts/black/hair_11.png",
    "/images/avatar_suite_png_1.0/race_3/haircuts/black/hair_12.png",
    "/images/avatar_suite_png_1.0/race_3/haircuts/black/hair_13.png",
    "/images/avatar_suite_png_1.0/race_3/haircuts/black/hair_14.png",
    "/images/avatar_suite_png_1.0/race_3/haircuts/black/hair_15.png",
    "/images/avatar_suite_png_1.0/race_3/haircuts/black/hair_16.png",
    "/images/avatar_suite_png_1.0/race_3/haircuts/black/hair_17.png",
    "/images/avatar_suite_png_1.0/race_3/haircuts/black/hair_18.png",
    "/images/avatar_suite_png_1.0/race_3/haircuts/black/hair_19.png",
    "/images/avatar_suite_png_1.0/race_3/haircuts/black/hair_2.png",
    "/images/avatar_suite_png_1.0/race_3/haircuts/black/hair_20.png",
    "/images/avatar_suite_png_1.0/race_3/haircuts/black/hair_3.png",
    "/images/avatar_suite_png_1.0/race_3/haircuts/black/hair_4.png",
    "/images/avatar_suite_png_1.0/race_3/haircuts/black/hair_5.png",
    "/images/avatar_suite_png_1.0/race_3/haircuts/black/hair_6.png",
    "/images/avatar_suite_png_1.0/race_3/haircuts/black/hair_7.png",
    "/images/avatar_suite_png_1.0/race_3/haircuts/black/hair_8.png",
    "/images/avatar_suite_png_1.0/race_3/haircuts/black/hair_9.png",
    "/images/avatar_suite_png_1.0/race_3/haircuts/blonde/Hair_Blonde_1.png",
    "/images/avatar_suite_png_1.0/race_3/haircuts/blonde/Hair_Blonde_10.png",
    "/images/avatar_suite_png_1.0/race_3/haircuts/blonde/Hair_Blonde_13.png",
    "/images/avatar_suite_png_1.0/race_3/haircuts/blonde/Hair_Blonde_14.png",
    "/images/avatar_suite_png_1.0/race_3/haircuts/blonde/Hair_Blonde_2.png",
    "/images/avatar_suite_png_1.0/race_3/haircuts/blonde/Hair_Blonde_3.png",
    "/images/avatar_suite_png_1.0/race_3/haircuts/blonde/Hair_Blonde_5.png",
    "/images/avatar_suite_png_1.0/race_3/haircuts/blonde/Hair_Blonde_9.png",
    "/images/avatar_suite_png_1.0/race_3/haircuts/blonde/Hair_blonde_15.png",
    "/images/avatar_suite_png_1.0/race_3/haircuts/blonde/Hair_blonde_16.png",
    "/images/avatar_suite_png_1.0/race_3/haircuts/blonde/Hair_blonde_19.png",
    "/images/avatar_suite_png_1.0/race_3/haircuts/blonde/Hair_blonde_20.png",
    "/images/avatar_suite_png_1.0/race_3/haircuts/blonde/Hair_brown_16.png",
    "/images/avatar_suite_png_1.0/race_3/haircuts/brown/Hair_Blonde_13.png",
    "/images/avatar_suite_png_1.0/race_3/haircuts/brown/Hair_Brown_1.png",
    "/images/avatar_suite_png_1.0/race_3/haircuts/brown/Hair_Brown_13.png",
    "/images/avatar_suite_png_1.0/race_3/haircuts/brown/Hair_Brown_14.png",
    "/images/avatar_suite_png_1.0/race_3/haircuts/brown/Hair_Brown_2.png",
    "/images/avatar_suite_png_1.0/race_3/haircuts/brown/Hair_Brown_3.png",
    "/images/avatar_suite_png_1.0/race_3/haircuts/brown/Hair_Brown_5.png",
    "/images/avatar_suite_png_1.0/race_3/haircuts/brown/Hair_brown_15.png",
    "/images/avatar_suite_png_1.0/race_3/haircuts/brown/Hair_brown_16.png",
    "/images/avatar_suite_png_1.0/race_3/haircuts/brown/Hair_brown_19.png",
    "/images/avatar_suite_png_1.0/race_3/haircuts/brown/Hair_brown_20.png",
    "/images/avatar_suite_png_1.0/race_4/haircuts/black/hair_1.png",
    "/images/avatar_suite_png_1.0/race_4/haircuts/black/hair_10.png",
    "/images/avatar_suite_png_1.0/race_4/haircuts/black/hair_11.png",
    "/images/avatar_suite_png_1.0/race_4/haircuts/black/hair_12.png",
    "/images/avatar_suite_png_1.0/race_4/haircuts/black/hair_13.png",
    "/images/avatar_suite_png_1.0/race_4/haircuts/black/hair_14.png",
    "/images/avatar_suite_png_1.0/race_4/haircuts/black/hair_15.png",
    "/images/avatar_suite_png_1.0/race_4/haircuts/black/hair_16.png",
    "/images/avatar_suite_png_1.0/race_4/haircuts/black/hair_17.png",
    "/images/avatar_suite_png_1.0/race_4/haircuts/black/hair_18.png",
    "/images/avatar_suite_png_1.0/race_4/haircuts/black/hair_19.png",
    "/images/avatar_suite_png_1.0/race_4/haircuts/black/hair_2.png",
    "/images/avatar_suite_png_1.0/race_4/haircuts/black/hair_20.png",
    "/images/avatar_suite_png_1.0/race_4/haircuts/black/hair_3.png",
    "/images/avatar_suite_png_1.0/race_4/haircuts/black/hair_4.png",
    "/images/avatar_suite_png_1.0/race_4/haircuts/black/hair_5.png",
    "/images/avatar_suite_png_1.0/race_4/haircuts/black/hair_6.png",
    "/images/avatar_suite_png_1.0/race_4/haircuts/black/hair_7.png",
    "/images/avatar_suite_png_1.0/race_4/haircuts/black/hair_8.png",
    "/images/avatar_suite_png_1.0/race_4/haircuts/black/hair_9.png",
    "/images/avatar_suite_png_1.0/race_4/haircuts/blonde/Hair_Blonde_1.png",
    "/images/avatar_suite_png_1.0/race_4/haircuts/blonde/Hair_Blonde_10.png",
    "/images/avatar_suite_png_1.0/race_4/haircuts/blonde/Hair_Blonde_13.png",
    "/images/avatar_suite_png_1.0/race_4/haircuts/blonde/Hair_Blonde_14.png",
    "/images/avatar_suite_png_1.0/race_4/haircuts/blonde/Hair_Blonde_2.png",
    "/images/avatar_suite_png_1.0/race_4/haircuts/blonde/Hair_Blonde_3.png",
    "/images/avatar_suite_png_1.0/race_4/haircuts/blonde/Hair_Blonde_5.png",
    "/images/avatar_suite_png_1.0/race_4/haircuts/blonde/Hair_Blonde_9.png",
    "/images/avatar_suite_png_1.0/race_4/haircuts/blonde/Hair_blonde_15.png",
    "/images/avatar_suite_png_1.0/race_4/haircuts/blonde/Hair_blonde_16.png",
    "/images/avatar_suite_png_1.0/race_4/haircuts/blonde/Hair_blonde_19.png",
    "/images/avatar_suite_png_1.0/race_4/haircuts/blonde/Hair_blonde_20.png",
    "/images/avatar_suite_png_1.0/race_4/haircuts/brown/Hair_Brown_1.png",
    "/images/avatar_suite_png_1.0/race_4/haircuts/brown/Hair_Brown_13.png",
    "/images/avatar_suite_png_1.0/race_4/haircuts/brown/Hair_Brown_14.png",
    "/images/avatar_suite_png_1.0/race_4/haircuts/brown/Hair_Brown_2.png",
    "/images/avatar_suite_png_1.0/race_4/haircuts/brown/Hair_Brown_3.png",
    "/images/avatar_suite_png_1.0/race_4/haircuts/brown/Hair_Brown_5.png",
    "/images/avatar_suite_png_1.0/race_4/haircuts/brown/Hair_brown_15.png",
    "/images/avatar_suite_png_1.0/race_4/haircuts/brown/Hair_brown_16.png",
    "/images/avatar_suite_png_1.0/race_4/haircuts/brown/Hair_brown_19.png",
    "/images/avatar_suite_png_1.0/race_4/haircuts/brown/Hair_brown_20.png",
  ];

  const eyebrows = [
    "/images/avatar_suite_png_1.0/race_1/eyebrows/black/Eyebrows_1.png",
    "/images/avatar_suite_png_1.0/race_1/eyebrows/black/Eyebrows_12.png",
    "/images/avatar_suite_png_1.0/race_1/eyebrows/black/Eyebrows_13.png",
    "/images/avatar_suite_png_1.0/race_1/eyebrows/black/Eyebrows_14.png",
    "/images/avatar_suite_png_1.0/race_1/eyebrows/black/Eyebrows_15.png",
    "/images/avatar_suite_png_1.0/race_1/eyebrows/black/Eyebrows_16.png",
    "/images/avatar_suite_png_1.0/race_1/eyebrows/black/Eyebrows_17.png",
    "/images/avatar_suite_png_1.0/race_1/eyebrows/black/Eyebrows_2.png",
    "/images/avatar_suite_png_1.0/race_1/eyebrows/black/Eyebrows_20.png",
    "/images/avatar_suite_png_1.0/race_1/eyebrows/black/Eyebrows_3.png",
    "/images/avatar_suite_png_1.0/race_1/eyebrows/black/Eyebrows_4.png",
    "/images/avatar_suite_png_1.0/race_1/eyebrows/black/Eyebrows_5.png",
    "/images/avatar_suite_png_1.0/race_1/eyebrows/black/Eyebrows_6.png",
    "/images/avatar_suite_png_1.0/race_1/eyebrows/black/Eyebrows_7.png",
    "/images/avatar_suite_png_1.0/race_1/eyebrows/black/Eyebrows_8.png",
    "/images/avatar_suite_png_1.0/race_1/eyebrows/black/Eyebrows_9.png",
    "/images/avatar_suite_png_1.0/race_1/eyebrows/black/Eyebrows_11.png",
    "/images/avatar_suite_png_1.0/race_1/eyebrows/black/Eyebrows_19.png",
    "/images/avatar_suite_png_1.0/race_1/eyebrows/black/Eyebrows_18.png",
    "/images/avatar_suite_png_1.0/race_1/eyebrows/black/Eyebrows_10.png",
    "/images/avatar_suite_png_1.0/race_1/eyebrows/blonde/eyebrows_1.png",
    "/images/avatar_suite_png_1.0/race_1/eyebrows/blonde/eyebrows_12.png",
    "/images/avatar_suite_png_1.0/race_1/eyebrows/blonde/eyebrows_13.png",
    "/images/avatar_suite_png_1.0/race_1/eyebrows/blonde/eyebrows_14.png",
    "/images/avatar_suite_png_1.0/race_1/eyebrows/blonde/eyebrows_15.png",
    "/images/avatar_suite_png_1.0/race_1/eyebrows/blonde/eyebrows_16.png",
    "/images/avatar_suite_png_1.0/race_1/eyebrows/blonde/eyebrows_17.png",
    "/images/avatar_suite_png_1.0/race_1/eyebrows/blonde/eyebrows_2.png",
    "/images/avatar_suite_png_1.0/race_1/eyebrows/blonde/eyebrows_20.png",
    "/images/avatar_suite_png_1.0/race_1/eyebrows/blonde/eyebrows_3.png",
    "/images/avatar_suite_png_1.0/race_1/eyebrows/blonde/eyebrows_4.png",
    "/images/avatar_suite_png_1.0/race_1/eyebrows/blonde/eyebrows_5.png",
    "/images/avatar_suite_png_1.0/race_1/eyebrows/blonde/eyebrows_6.png",
    "/images/avatar_suite_png_1.0/race_1/eyebrows/blonde/eyebrows_7.png",
    "/images/avatar_suite_png_1.0/race_1/eyebrows/blonde/eyebrows_8.png",
    "/images/avatar_suite_png_1.0/race_1/eyebrows/blonde/eyebrows_9.png",
    "/images/avatar_suite_png_1.0/race_1/eyebrows/blonde/eyebrows_19.png",
    "/images/avatar_suite_png_1.0/race_1/eyebrows/blonde/eyebrows_18.png",
    "/images/avatar_suite_png_1.0/race_1/eyebrows/blonde/eyebrows_11.png",
    "/images/avatar_suite_png_1.0/race_1/eyebrows/blonde/eyebrows_10.png",
    "/images/avatar_suite_png_1.0/race_1/eyebrows/brown/Eyebrows_1.png",
    "/images/avatar_suite_png_1.0/race_1/eyebrows/brown/Eyebrows_12.png",
    "/images/avatar_suite_png_1.0/race_1/eyebrows/brown/Eyebrows_13.png",
    "/images/avatar_suite_png_1.0/race_1/eyebrows/brown/Eyebrows_14.png",
    "/images/avatar_suite_png_1.0/race_1/eyebrows/brown/Eyebrows_15.png",
    "/images/avatar_suite_png_1.0/race_1/eyebrows/brown/Eyebrows_16.png",
    "/images/avatar_suite_png_1.0/race_1/eyebrows/brown/Eyebrows_17.png",
    "/images/avatar_suite_png_1.0/race_1/eyebrows/brown/Eyebrows_2.png",
    "/images/avatar_suite_png_1.0/race_1/eyebrows/brown/Eyebrows_20.png",
    "/images/avatar_suite_png_1.0/race_1/eyebrows/brown/Eyebrows_3.png",
    "/images/avatar_suite_png_1.0/race_1/eyebrows/brown/Eyebrows_4.png",
    "/images/avatar_suite_png_1.0/race_1/eyebrows/brown/Eyebrows_5.png",
    "/images/avatar_suite_png_1.0/race_1/eyebrows/brown/Eyebrows_6.png",
    "/images/avatar_suite_png_1.0/race_1/eyebrows/brown/Eyebrows_7.png",
    "/images/avatar_suite_png_1.0/race_1/eyebrows/brown/Eyebrows_8.png",
    "/images/avatar_suite_png_1.0/race_1/eyebrows/brown/Eyebrows_9.png",
    "/images/avatar_suite_png_1.0/race_1/eyebrows/brown/Eyebrows_19.png",
    "/images/avatar_suite_png_1.0/race_1/eyebrows/brown/Eyebrows_18.png",
    "/images/avatar_suite_png_1.0/race_1/eyebrows/brown/Eyebrows_11.png",
    "/images/avatar_suite_png_1.0/race_1/eyebrows/brown/Eyebrows_10.png",
    "/images/avatar_suite_png_1.0/race_2/eyebrows/black/eyebrows_1.png",
    "/images/avatar_suite_png_1.0/race_2/eyebrows/black/eyebrows_12.png",
    "/images/avatar_suite_png_1.0/race_2/eyebrows/black/eyebrows_13.png",
    "/images/avatar_suite_png_1.0/race_2/eyebrows/black/eyebrows_14.png",
    "/images/avatar_suite_png_1.0/race_2/eyebrows/black/eyebrows_15.png",
    "/images/avatar_suite_png_1.0/race_2/eyebrows/black/eyebrows_16.png",
    "/images/avatar_suite_png_1.0/race_2/eyebrows/black/eyebrows_17.png",
    "/images/avatar_suite_png_1.0/race_2/eyebrows/black/eyebrows_2.png",
    "/images/avatar_suite_png_1.0/race_2/eyebrows/black/eyebrows_20.png",
    "/images/avatar_suite_png_1.0/race_2/eyebrows/black/eyebrows_3.png",
    "/images/avatar_suite_png_1.0/race_2/eyebrows/black/eyebrows_4.png",
    "/images/avatar_suite_png_1.0/race_2/eyebrows/black/eyebrows_5.png",
    "/images/avatar_suite_png_1.0/race_2/eyebrows/black/eyebrows_6.png",
    "/images/avatar_suite_png_1.0/race_2/eyebrows/black/eyebrows_7.png",
    "/images/avatar_suite_png_1.0/race_2/eyebrows/black/eyebrows_8.png",
    "/images/avatar_suite_png_1.0/race_2/eyebrows/black/eyebrows_9.png",
    "/images/avatar_suite_png_1.0/race_2/eyebrows/black/eyebrows_18.png",
    "/images/avatar_suite_png_1.0/race_2/eyebrows/black/eyebrows_19.png",
    "/images/avatar_suite_png_1.0/race_2/eyebrows/black/eyebrows_11.png",
    "/images/avatar_suite_png_1.0/race_2/eyebrows/black/eyebrows_10.png",
    "/images/avatar_suite_png_1.0/race_2/eyebrows/blonde/eyebrows_1.png",
    "/images/avatar_suite_png_1.0/race_2/eyebrows/blonde/eyebrows_12.png",
    "/images/avatar_suite_png_1.0/race_2/eyebrows/blonde/eyebrows_13.png",
    "/images/avatar_suite_png_1.0/race_2/eyebrows/blonde/eyebrows_14.png",
    "/images/avatar_suite_png_1.0/race_2/eyebrows/blonde/eyebrows_15.png",
    "/images/avatar_suite_png_1.0/race_2/eyebrows/blonde/eyebrows_16.png",
    "/images/avatar_suite_png_1.0/race_2/eyebrows/blonde/eyebrows_17.png",
    "/images/avatar_suite_png_1.0/race_2/eyebrows/blonde/eyebrows_2.png",
    "/images/avatar_suite_png_1.0/race_2/eyebrows/blonde/eyebrows_20.png",
    "/images/avatar_suite_png_1.0/race_2/eyebrows/blonde/eyebrows_3.png",
    "/images/avatar_suite_png_1.0/race_2/eyebrows/blonde/eyebrows_4.png",
    "/images/avatar_suite_png_1.0/race_2/eyebrows/blonde/eyebrows_5.png",
    "/images/avatar_suite_png_1.0/race_2/eyebrows/blonde/eyebrows_6.png",
    "/images/avatar_suite_png_1.0/race_2/eyebrows/blonde/eyebrows_7.png",
    "/images/avatar_suite_png_1.0/race_2/eyebrows/blonde/eyebrows_8.png",
    "/images/avatar_suite_png_1.0/race_2/eyebrows/blonde/eyebrows_9.png",
    "/images/avatar_suite_png_1.0/race_2/eyebrows/blonde/eyebrows_18.png",
    "/images/avatar_suite_png_1.0/race_2/eyebrows/blonde/eyebrows_19.png",
    "/images/avatar_suite_png_1.0/race_2/eyebrows/blonde/eyebrows_11.png",
    "/images/avatar_suite_png_1.0/race_2/eyebrows/blonde/eyebrows_10.png",
    "/images/avatar_suite_png_1.0/race_2/eyebrows/brown/eyebrows_1.png",
    "/images/avatar_suite_png_1.0/race_2/eyebrows/brown/eyebrows_12.png",
    "/images/avatar_suite_png_1.0/race_2/eyebrows/brown/eyebrows_13.png",
    "/images/avatar_suite_png_1.0/race_2/eyebrows/brown/eyebrows_14.png",
    "/images/avatar_suite_png_1.0/race_2/eyebrows/brown/eyebrows_15.png",
    "/images/avatar_suite_png_1.0/race_2/eyebrows/brown/eyebrows_16.png",
    "/images/avatar_suite_png_1.0/race_2/eyebrows/brown/eyebrows_17.png",
    "/images/avatar_suite_png_1.0/race_2/eyebrows/brown/eyebrows_2.png",
    "/images/avatar_suite_png_1.0/race_2/eyebrows/brown/eyebrows_20.png",
    "/images/avatar_suite_png_1.0/race_2/eyebrows/brown/eyebrows_3.png",
    "/images/avatar_suite_png_1.0/race_2/eyebrows/brown/eyebrows_4.png",
    "/images/avatar_suite_png_1.0/race_2/eyebrows/brown/eyebrows_5.png",
    "/images/avatar_suite_png_1.0/race_2/eyebrows/brown/eyebrows_6.png",
    "/images/avatar_suite_png_1.0/race_2/eyebrows/brown/eyebrows_7.png",
    "/images/avatar_suite_png_1.0/race_2/eyebrows/brown/eyebrows_8.png",
    "/images/avatar_suite_png_1.0/race_2/eyebrows/brown/eyebrows_9.png",
    "/images/avatar_suite_png_1.0/race_2/eyebrows/brown/eyebrows_19.png",
    "/images/avatar_suite_png_1.0/race_2/eyebrows/brown/eyebrows_18.png",
    "/images/avatar_suite_png_1.0/race_2/eyebrows/brown/eyebrows_11.png",
    "/images/avatar_suite_png_1.0/race_2/eyebrows/brown/eyebrows_10.png",
    "/images/avatar_suite_png_1.0/race_3/eyebrows/black/eyebrows_1.png",
    "/images/avatar_suite_png_1.0/race_3/eyebrows/black/eyebrows_12.png",
    "/images/avatar_suite_png_1.0/race_3/eyebrows/black/eyebrows_13.png",
    "/images/avatar_suite_png_1.0/race_3/eyebrows/black/eyebrows_14.png",
    "/images/avatar_suite_png_1.0/race_3/eyebrows/black/eyebrows_15.png",
    "/images/avatar_suite_png_1.0/race_3/eyebrows/black/eyebrows_16.png",
    "/images/avatar_suite_png_1.0/race_3/eyebrows/black/eyebrows_17.png",
    "/images/avatar_suite_png_1.0/race_3/eyebrows/black/eyebrows_2.png",
    "/images/avatar_suite_png_1.0/race_3/eyebrows/black/eyebrows_20.png",
    "/images/avatar_suite_png_1.0/race_3/eyebrows/black/eyebrows_3.png",
    "/images/avatar_suite_png_1.0/race_3/eyebrows/black/eyebrows_4.png",
    "/images/avatar_suite_png_1.0/race_3/eyebrows/black/eyebrows_5.png",
    "/images/avatar_suite_png_1.0/race_3/eyebrows/black/eyebrows_6.png",
    "/images/avatar_suite_png_1.0/race_3/eyebrows/black/eyebrows_7.png",
    "/images/avatar_suite_png_1.0/race_3/eyebrows/black/eyebrows_8.png",
    "/images/avatar_suite_png_1.0/race_3/eyebrows/black/eyebrows_9.png",
    "/images/avatar_suite_png_1.0/race_3/eyebrows/black/eyebrows_18.png",
    "/images/avatar_suite_png_1.0/race_3/eyebrows/black/eyebrows_19.png",
    "/images/avatar_suite_png_1.0/race_3/eyebrows/black/eyebrows_11.png",
    "/images/avatar_suite_png_1.0/race_3/eyebrows/black/eyebrows_10.png",
    "/images/avatar_suite_png_1.0/race_3/eyebrows/blonde/eyebrows_1.png",
    "/images/avatar_suite_png_1.0/race_3/eyebrows/blonde/eyebrows_12.png",
    "/images/avatar_suite_png_1.0/race_3/eyebrows/blonde/eyebrows_13.png",
    "/images/avatar_suite_png_1.0/race_3/eyebrows/blonde/eyebrows_14.png",
    "/images/avatar_suite_png_1.0/race_3/eyebrows/blonde/eyebrows_15.png",
    "/images/avatar_suite_png_1.0/race_3/eyebrows/blonde/eyebrows_16.png",
    "/images/avatar_suite_png_1.0/race_3/eyebrows/blonde/eyebrows_17.png",
    "/images/avatar_suite_png_1.0/race_3/eyebrows/blonde/eyebrows_2.png",
    "/images/avatar_suite_png_1.0/race_3/eyebrows/blonde/eyebrows_20.png",
    "/images/avatar_suite_png_1.0/race_3/eyebrows/blonde/eyebrows_3.png",
    "/images/avatar_suite_png_1.0/race_3/eyebrows/blonde/eyebrows_4.png",
    "/images/avatar_suite_png_1.0/race_3/eyebrows/blonde/eyebrows_5.png",
    "/images/avatar_suite_png_1.0/race_3/eyebrows/blonde/eyebrows_6.png",
    "/images/avatar_suite_png_1.0/race_3/eyebrows/blonde/eyebrows_7.png",
    "/images/avatar_suite_png_1.0/race_3/eyebrows/blonde/eyebrows_8.png",
    "/images/avatar_suite_png_1.0/race_3/eyebrows/blonde/eyebrows_9.png",
    "/images/avatar_suite_png_1.0/race_3/eyebrows/blonde/eyebrows_19.png",
    "/images/avatar_suite_png_1.0/race_3/eyebrows/blonde/eyebrows_18.png",
    "/images/avatar_suite_png_1.0/race_3/eyebrows/blonde/eyebrows_11.png",
    "/images/avatar_suite_png_1.0/race_3/eyebrows/blonde/eyebrows_10.png",
    "/images/avatar_suite_png_1.0/race_3/eyebrows/brown/eyebrows_1.png",
    "/images/avatar_suite_png_1.0/race_3/eyebrows/brown/eyebrows_12.png",
    "/images/avatar_suite_png_1.0/race_3/eyebrows/brown/eyebrows_13.png",
    "/images/avatar_suite_png_1.0/race_3/eyebrows/brown/eyebrows_14.png",
    "/images/avatar_suite_png_1.0/race_3/eyebrows/brown/eyebrows_15.png",
    "/images/avatar_suite_png_1.0/race_3/eyebrows/brown/eyebrows_16.png",
    "/images/avatar_suite_png_1.0/race_3/eyebrows/brown/eyebrows_17.png",
    "/images/avatar_suite_png_1.0/race_3/eyebrows/brown/eyebrows_2.png",
    "/images/avatar_suite_png_1.0/race_3/eyebrows/brown/eyebrows_20.png",
    "/images/avatar_suite_png_1.0/race_3/eyebrows/brown/eyebrows_3.png",
    "/images/avatar_suite_png_1.0/race_3/eyebrows/brown/eyebrows_4.png",
    "/images/avatar_suite_png_1.0/race_3/eyebrows/brown/eyebrows_5.png",
    "/images/avatar_suite_png_1.0/race_3/eyebrows/brown/eyebrows_6.png",
    "/images/avatar_suite_png_1.0/race_3/eyebrows/brown/eyebrows_7.png",
    "/images/avatar_suite_png_1.0/race_3/eyebrows/brown/eyebrows_8.png",
    "/images/avatar_suite_png_1.0/race_3/eyebrows/brown/eyebrows_9.png",
    "/images/avatar_suite_png_1.0/race_3/eyebrows/brown/eyebrows_19.png",
    "/images/avatar_suite_png_1.0/race_3/eyebrows/brown/eyebrows_18.png",
    "/images/avatar_suite_png_1.0/race_3/eyebrows/brown/eyebrows_11.png",
    "/images/avatar_suite_png_1.0/race_3/eyebrows/brown/eyebrows_10.png",
    "/images/avatar_suite_png_1.0/race_4/eyebrows/black/eyebrows_1.png",
    "/images/avatar_suite_png_1.0/race_4/eyebrows/black/eyebrows_12.png",
    "/images/avatar_suite_png_1.0/race_4/eyebrows/black/eyebrows_13.png",
    "/images/avatar_suite_png_1.0/race_4/eyebrows/black/eyebrows_14.png",
    "/images/avatar_suite_png_1.0/race_4/eyebrows/black/eyebrows_15.png",
    "/images/avatar_suite_png_1.0/race_4/eyebrows/black/eyebrows_16.png",
    "/images/avatar_suite_png_1.0/race_4/eyebrows/black/eyebrows_17.png",
    "/images/avatar_suite_png_1.0/race_4/eyebrows/black/eyebrows_2.png",
    "/images/avatar_suite_png_1.0/race_4/eyebrows/black/eyebrows_20.png",
    "/images/avatar_suite_png_1.0/race_4/eyebrows/black/eyebrows_3.png",
    "/images/avatar_suite_png_1.0/race_4/eyebrows/black/eyebrows_4.png",
    "/images/avatar_suite_png_1.0/race_4/eyebrows/black/eyebrows_5.png",
    "/images/avatar_suite_png_1.0/race_4/eyebrows/black/eyebrows_6.png",
    "/images/avatar_suite_png_1.0/race_4/eyebrows/black/eyebrows_7.png",
    "/images/avatar_suite_png_1.0/race_4/eyebrows/black/eyebrows_8.png",
    "/images/avatar_suite_png_1.0/race_4/eyebrows/black/eyebrows_9.png",
    "/images/avatar_suite_png_1.0/race_4/eyebrows/black/eyebrows_19.png",
    "/images/avatar_suite_png_1.0/race_4/eyebrows/black/eyebrows_18.png",
    "/images/avatar_suite_png_1.0/race_4/eyebrows/black/eyebrows_11.png",
    "/images/avatar_suite_png_1.0/race_4/eyebrows/black/eyebrows_10.png",
    "/images/avatar_suite_png_1.0/race_4/eyebrows/blonde/eyebrows_1.png",
    "/images/avatar_suite_png_1.0/race_4/eyebrows/blonde/eyebrows_12.png",
    "/images/avatar_suite_png_1.0/race_4/eyebrows/blonde/eyebrows_13.png",
    "/images/avatar_suite_png_1.0/race_4/eyebrows/blonde/eyebrows_14.png",
    "/images/avatar_suite_png_1.0/race_4/eyebrows/blonde/eyebrows_15.png",
    "/images/avatar_suite_png_1.0/race_4/eyebrows/blonde/eyebrows_16.png",
    "/images/avatar_suite_png_1.0/race_4/eyebrows/blonde/eyebrows_17.png",
    "/images/avatar_suite_png_1.0/race_4/eyebrows/blonde/eyebrows_2.png",
    "/images/avatar_suite_png_1.0/race_4/eyebrows/blonde/eyebrows_20.png",
    "/images/avatar_suite_png_1.0/race_4/eyebrows/blonde/eyebrows_3.png",
    "/images/avatar_suite_png_1.0/race_4/eyebrows/blonde/eyebrows_4.png",
    "/images/avatar_suite_png_1.0/race_4/eyebrows/blonde/eyebrows_5.png",
    "/images/avatar_suite_png_1.0/race_4/eyebrows/blonde/eyebrows_6.png",
    "/images/avatar_suite_png_1.0/race_4/eyebrows/blonde/eyebrows_7.png",
    "/images/avatar_suite_png_1.0/race_4/eyebrows/blonde/eyebrows_8.png",
    "/images/avatar_suite_png_1.0/race_4/eyebrows/blonde/eyebrows_9.png",
    "/images/avatar_suite_png_1.0/race_4/eyebrows/blonde/eyebrows_19.png",
    "/images/avatar_suite_png_1.0/race_4/eyebrows/blonde/eyebrows_18.png",
    "/images/avatar_suite_png_1.0/race_4/eyebrows/blonde/eyebrows_11.png",
    "/images/avatar_suite_png_1.0/race_4/eyebrows/blonde/eyebrows_10.png",
    "/images/avatar_suite_png_1.0/race_4/eyebrows/brown/eyebrows_1.png",
    "/images/avatar_suite_png_1.0/race_4/eyebrows/brown/eyebrows_12.png",
    "/images/avatar_suite_png_1.0/race_4/eyebrows/brown/eyebrows_13.png",
    "/images/avatar_suite_png_1.0/race_4/eyebrows/brown/eyebrows_14.png",
    "/images/avatar_suite_png_1.0/race_4/eyebrows/brown/eyebrows_15.png",
    "/images/avatar_suite_png_1.0/race_4/eyebrows/brown/eyebrows_16.png",
    "/images/avatar_suite_png_1.0/race_4/eyebrows/brown/eyebrows_17.png",
    "/images/avatar_suite_png_1.0/race_4/eyebrows/brown/eyebrows_2.png",
    "/images/avatar_suite_png_1.0/race_4/eyebrows/brown/eyebrows_20.png",
    "/images/avatar_suite_png_1.0/race_4/eyebrows/brown/eyebrows_3.png",
    "/images/avatar_suite_png_1.0/race_4/eyebrows/brown/eyebrows_4.png",
    "/images/avatar_suite_png_1.0/race_4/eyebrows/brown/eyebrows_5.png",
    "/images/avatar_suite_png_1.0/race_4/eyebrows/brown/eyebrows_6.png",
    "/images/avatar_suite_png_1.0/race_4/eyebrows/brown/eyebrows_7.png",
    "/images/avatar_suite_png_1.0/race_4/eyebrows/brown/eyebrows_8.png",
    "/images/avatar_suite_png_1.0/race_4/eyebrows/brown/eyebrows_9.png",
    "/images/avatar_suite_png_1.0/race_4/eyebrows/brown/eyebrows_19.png",
    "/images/avatar_suite_png_1.0/race_4/eyebrows/brown/eyebrows_18.png",
    "/images/avatar_suite_png_1.0/race_4/eyebrows/brown/eyebrows_11.png",
    "/images/avatar_suite_png_1.0/race_4/eyebrows/brown/eyebrows_10.png",
  ];

  const hairColor = ((player.eyebrows - 1) / 20) % 3 | 0;

  const Accessory = accessories[player.accessory - 1];
  const Tattoo = accessories[player.tattoo - 1];
  const Jersey = jerseys[player.jersey - 1];
  let Mouth = mouthes[player.mouth + 20 * player.race - 20 - 1];
  const Eyes = eyes[player.eyes - 1];
  const Ears = ears[player.ears - 1];
  const Nose = noses[player.nose - 1];
  const Face = faces[player.face + 5 * player.race - 5 - 1];
  const FaciaHair = facialHair[hairColor + 1][player.facialHair - 1];
  const Haircut = haircuts[player.haircut - 1];
  const Eyebrows = eyebrows[player.eyebrows - 1];

  //   if (player.mouth + 20 * player.race - 20 - (1 % 20) > 16) {
  //     Mouth = mouthes[player.mouth + 20 * player.race - 20 - 4];
  //   }

  if ((player.eyes & 10) === 1) {
    return (
      <div className="player-photo-container">
        <img className="player-image" src={Face} alt="Court" width="82" height="82"></img>
        <img className="player-image" src={Ears} alt="Court" width="82" height="82"></img>
        <img className="player-image" src={Nose} alt="Court" width="82" height="82"></img>
        <img className="player-image" src={Mouth} alt="Court" width="82" height="82"></img>
        <img className="player-image" src={Haircut} alt="Court" width="82" height="82"></img>
        <img className="player-image" src={Eyebrows} alt="Court" width="82" height="82"></img>
        <img className="player-image" src={Eyes} alt="Court" width="82" height="82"></img>
        <img className="player-image" src={FaciaHair} alt="Court" width="82" height="82"></img>
        <img className="player-image" src={Jersey} alt="Court" width="82" height="82"></img>
        <img className="player-image" src={Tattoo} alt="Court" width="82" height="82"></img>
      </div>
    );
  }

  if ((player.eyes & 5) === 2) {
    return (
      <div className="player-photo-container">
        <img className="player-image" src={Face} alt="Court" width="82" height="82"></img>
        <img className="player-image" src={Ears} alt="Court" width="82" height="82"></img>
        <img className="player-image" src={Nose} alt="Court" width="82" height="82"></img>
        <img className="player-image" src={Mouth} alt="Court" width="82" height="82"></img>
        <img className="player-image" src={Haircut} alt="Court" width="82" height="82"></img>
        <img className="player-image" src={Eyebrows} alt="Court" width="82" height="82"></img>
        <img className="player-image" src={Eyes} alt="Court" width="82" height="82"></img>
        <img className="player-image" src={FaciaHair} alt="Court" width="82" height="82"></img>
        <img className="player-image" src={Jersey} alt="Court" width="82" height="82"></img>
        <img className="player-image" src={Accessory} alt="Court" width="82" height="82"></img>
      </div>
    );
  }

  return (
    <div className="player-photo-container">
      <img className="player-image" src={Face} alt="Court" width="82" height="82"></img>
      <img className="player-image" src={Ears} alt="Court" width="82" height="82"></img>
      <img className="player-image" src={Nose} alt="Court" width="82" height="82"></img>
      <img className="player-image" src={Mouth} alt="Court" width="82" height="82"></img>
      <img className="player-image" src={Haircut} alt="Court" width="82" height="82"></img>
      <img className="player-image" src={Eyebrows} alt="Court" width="82" height="82"></img>
      <img className="player-image" src={Eyes} alt="Court" width="82" height="82"></img>
      <img className="player-image" src={FaciaHair} alt="Court" width="82" height="82"></img>
      <img className="player-image" src={Jersey} alt="Court" width="82" height="82"></img>
    </div>
  );
};

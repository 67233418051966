import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { EmptyLineupInfo, SubmittedLineupInfo } from "../../api/lineup";
import { defaultState } from "../util/defaultState";
import { MyKnownError } from "../myKnownError";

const lineupSlice = createSlice({
  name: "lineup",
  initialState: defaultState<EmptyLineupInfo | SubmittedLineupInfo>(),
  reducers: {
    getLineupLoading: (state) => {
      state.fetchStatus = "pending";
      return state;
    },
    getLineupSuccess: (state, action: PayloadAction<EmptyLineupInfo | SubmittedLineupInfo>) => {
      state.fetchStatus = "success";
      state.data = action.payload;
      return state;
    },
    getLineupFailed: (state, action: PayloadAction<MyKnownError>) => {
      state.fetchStatus = "failed";
      state.error = action.payload;
      return state;
    },
    getLineupReset: (state) => {
      state = defaultState();
      return state;
    },
  },
});

export const {
  getLineupLoading,
  getLineupSuccess,
  getLineupFailed,
  getLineupReset,
} = lineupSlice.actions;
export default lineupSlice.reducer;

import React, { useEffect } from "react";
import List from "@material-ui/core/List";
import { NavLink } from "react-router-dom";
import CustomListItemPremium from "../CustomListItemPremium";
import CustomListItem from "../CustomListItem";
import { baseStyles } from "../baseStyles";
import { Page } from "../../../components/pages/page";
import { connect } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/store";
import page from "../../../redux/reducer/page";

interface StateProps {
  page: Page;
}

interface DispatchProps {
  changePage: (p: Page) => void;
}

const PremiumButton = (props: StateProps & DispatchProps) => {
  const classes = baseStyles();
  const { page } = props;

  useEffect(() => {}, [page]);

  return (
    <List
      component={NavLink}
      className={classes.root}
      to={`/premium`}
      style={{ textDecoration: "none" }}
    >
      <CustomListItem button selected={props.page === "premium"}>
        <CustomListItemPremium primary="Premium" />
      </CustomListItem>
    </List>
  );
};

export default connect<StateProps, DispatchProps, {}, RootState>(
  (state) => ({
    page: state.page,
  }),
  (dispatch: AppDispatch) => ({
    changePage: (p: Page) => dispatch(page.actions.changePage(p)),
  })
)(PremiumButton);

// Import reducers (old style)
import page from "./reducer/page";
import { combineReducers } from "@reduxjs/toolkit";
import team from "./reducer/team";
import register from "./reducer/register";
import sideMenuBar from "./reducer/sideMenuBar";
import tournaments from "./reducer/tournaments";
import teamPages from "./reducer/teamPages";
import setLineup from "./reducer/submitLineup";
import energy from "./reducer/energy";
import otherTeamId from "./reducer/otherTeamId";

// Import modal reducers (new style)
import {
  gymAfterHoursModal,
  // extraTeamScrimmageModal,
  bulkUpModal,
  cuttingSeasonModal,
  // localFoodBankModal,
  merchandiseStandModal,
  teamDinnerModal,
  nameChangeModal,
  teamNameChangeModal,
  scrimmageSendModal,
  transferLoginModal,
  messageSendModal,
  bidModal,
  listModal,
  fireModal,
  retrieveEnergyModal,
} from "./reducer/modal";

// Import Energy form reducers (old style)
import firePlayer from "./reducer/firePlayer";
import listPlayer from "./reducer/listPlayer";
import bidPlayer from "./reducer/bidPlayer";

// Import reducers (new style)
// alpha
import transactions from "./reducer/transactionsReducer";
import lineup from "./reducer/lineupReducer";
import liveGame from "./reducer/liveGameReducer";
import logout from "./reducer/logoutReducer";
import logIn from "./reducer/logInReducer";
import submitTraining from "./reducer/submitTrainingReducer";
import training from "./reducer/trainingReducer";
import getSettings from "./reducer/getSettingsReducer";
import setSettings from "./reducer/setSettingsReducer";
import demoLogin from "./reducer/demoLoginReducer";
import cancelSubscriptions from "./reducer/setSettingsReducer";
import { api } from "../api/api";

export default combineReducers({
  //old style
  page: page.reducer,
  teamPages: teamPages.reducer,
  register: register.reducer,
  team: team.reducer,
  sideMenuBar: sideMenuBar.reducer,
  tournaments: tournaments.reducer,
  setLineup: setLineup.reducer,
  energy: energy.reducer,
  otherTeamId: otherTeamId.reducer,
  gymAfterHoursModal: gymAfterHoursModal.reducer,
  // extraTeamScrimmageModal: extraTeamScrimmageModal.reducer,
  bulkUpModal: bulkUpModal.reducer,
  cuttingSeasonModal: cuttingSeasonModal.reducer,
  // localFoodBankModal: localFoodBankModal.reducer,
  merchandiseStandModal: merchandiseStandModal.reducer,
  retrieveEnergyModal: retrieveEnergyModal.reducer,
  teamDinnerModal: teamDinnerModal.reducer,
  nameChangeModal: nameChangeModal.reducer,
  teamNameChangeModal: teamNameChangeModal.reducer,
  transferLoginModal: transferLoginModal.reducer,
  scrimmageSendModal: scrimmageSendModal.reducer,
  messageSendModal: messageSendModal.reducer,
  bidModal: bidModal.reducer,
  listModal: listModal.reducer,
  fireModal: fireModal.reducer,
  bidPlayer: bidPlayer.reducer,
  listPlayer: listPlayer.reducer,
  firePlayer: firePlayer.reducer,
  [api.reducerPath]: api.reducer,

  //new style
  // alpha
  lineup,
  liveGame,
  logIn,
  logout,
  submitTraining,
  training,
  transactions,
  getSettings,
  setSettings,
  demoLogin,
  cancelSubscriptions,
});

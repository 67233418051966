import React from "react";
import styled from "styled-components/macro";
import { useResponsive } from "../../util/useResponsive";
import DesktopNavBar from "./DesktopNavBar";
// import MobileNavBar from "./MobileNavBar";
import MobileNavBarTwo from "./MobileNavBarTwo";
import { Page } from "../pages/page";
import { connect } from "react-redux";
import { RootState } from "../../redux/store";
import { navBarHeight } from "../lib/styles/navBarHeight";

interface StateProps {
  page: Page;
}

const NavBar = (props: StateProps) => {
  const { isMobile, isTablet, isTabletLarge } = useResponsive();

  return props.page === "landing" ||
    props.page === "login" ||
    props.page === "register" ||
    props.page === "reset-password" ? (
    <></>
  ) : (
    <div>
      {isMobile || isTablet || isTabletLarge ? (
        <MobileNavBarTwo></MobileNavBarTwo>
      ) : (
        <DesktopNavBar></DesktopNavBar>
      )}
      <NavBarPadding />
    </div>
  );
};

const NavBarPadding = styled.div`
  height: ${navBarHeight};
`;

export default connect<StateProps, {}, {}, RootState>((state) => ({ page: state.page }))(NavBar);

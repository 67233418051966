import React from "react";
import styled from "styled-components/macro";
import { useResponsive } from "../../util/useResponsive";
import { api } from "../../api/api";

export type Importance = "low" | "medium" | "high";

const Announcement = () => {
  const { isMobile } = useResponsive();
  const { data: announcement, error } = api.endpoints.fetchAnnouncement.useQuery();

  if (!announcement || !announcement.text || error) {
    return <></>;
  }

  let importance: Importance;

  if (announcement.priority === 0) {
    importance = "low";
  } else if (announcement.priority === 1) {
    importance = "medium";
  } else {
    importance = "high";
  }

  return (
    <StyledAnnouncements importance={importance} text={announcement.text} isMobile={isMobile}>
      <p>{announcement.text}</p>
    </StyledAnnouncements>
  );
};

export default Announcement;

interface StyleProps {
  importance: Importance;
  text: string;
  isMobile: boolean;
}

const backgroundColor = (props: StyleProps) => {
  return {
    high: "red",
    medium: "yellow",
    low: "green",
  }[props.importance];
};

const height = (props: StyleProps) => {
  return props.isMobile ? 50 + props.text.length / 2 : 75;
};

const StyledAnnouncements = styled.div`
  align-items: center;
  background-color: ${backgroundColor};
  display: flex;
  justify-content: center;
  height: ${height}px;

  p {
    text-align: center;
  }
`;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { firePlayer } from "../../api/transferList";
import { FetchStatus } from "../fetchStatus";
import { MyKnownError } from "../myKnownError";
import { AsyncThunkError } from "../asyncThunkError";

interface State {
  fetchStatus: FetchStatus;
  error: MyKnownError | null;
}

export const firePlayerAction = createAsyncThunk<
  void,
  { id: number },
  AsyncThunkError<MyKnownError>
>("firePlayer", async (props, thunkApi) => {
  try {
    await firePlayer(props.id);
  } catch (err) {
    if (err?.response?.status === 400) {
      return thunkApi.rejectWithValue("400");
    } else {
      throw err;
    }
  }
});

export default createSlice({
  name: "firePlayer",
  initialState: {
    fetchStatus: "notStarted" as FetchStatus,
    error: null,
  } as State,
  reducers: {
    reset: (state) => {
      state = {
        fetchStatus: "notStarted",
        error: null,
      };
      return state;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(firePlayerAction.fulfilled, (state, action) => {
        // submit lineup succeeded
        state.fetchStatus = "success";
        return state;
      })
      // make update while waiting
      .addCase(firePlayerAction.pending, (state, action) => {
        state.fetchStatus = "pending";
        return state;
      })
      // make update when failed
      .addCase(firePlayerAction.rejected, (state, action) => {
        if (action.payload) {
          state.fetchStatus = "failed";
          state.error = action.payload;
        } else {
          state.fetchStatus = "failed";
          state.error = null;
        }
        return state;
      });
  },
});

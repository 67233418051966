import React from "react";
import page from "./page";
import styled from "styled-components/macro";
import { navBarHeight } from "../lib/styles/navBarHeight";

const CookiePolicy = () => {
  return (
    <Iframe
      src="https://app.termly.io/document/cookie-policy/eeacf148-a0e9-47e9-8ca8-b760e5bb9ceb"
      title="Cookie Policy"
    />
  );
};

export default page("cookiePolicy")(CookiePolicy);

const Iframe = styled.iframe`
  width: 100%;
  height: calc(100vh - ${navBarHeight});
  box-sizing: border-box;
  border: 0;
  background: white;
`;

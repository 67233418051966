import axios from "axios";

console.log(process.env.NODE_ENV);

export const api = axios.create({
  baseURL:
    process.env.NODE_ENV === "development"
      ? "http://localhost:3500/"
      : "https://api.courthoops.com/",

  // process.env.REACT_APP_VERCEL_ENV === "development"
  //   ? "https://beta.api.courthoops.com/"
  //   : process.env.REACT_APP_VERCEL_ENV === "production"
  //   ? "https://api.courthoops.com/"
  //   : "http://localhost:3500/",
  withCredentials: true,
});

api.interceptors.response.use(undefined, (error) => {
  switch (error.response.status) {
    case 401:
      //clear session cookie
      window.location.href = window.location.origin + `/login?redirectURI=${window.location.href}`;
      break;
    case 403:
      window.location.pathname = "/forbidden";
      break;
    case 404:
      window.location.pathname = "/not-found";
      break;
    case 500:
      window.location.pathname = "/something-went-wrong";
      break;
  }
  return Promise.reject(error);
});

import React from "react";
import styled from "styled-components/macro";
import premiumCover from "../lib/images/premiumCover.jpeg";
import page from "./page";
import Tile from "../lib/Tile";
import { breakpoint } from "../../util/breakpoint";

import SubscriptionCard from "../lib/premiumPage/SubscriptionCard";
import CoinsCard from "../lib/premiumPage/CoinsCard";
import PremiumBenefits from "../lib/premiumPage/PremiumBenefits";
import CoinsBenefits from "../lib/premiumPage/CoinsBenefits";

const PremiumPage = () => {
  return (
    <Container>
      <div className="image">
        <img src={premiumCover} alt="background" />
        <div className="main">
          <StyledTile
            title="Premium Subscriptions"
            button={false}
            width="100%"
            className="top-tile"
          >
            <div className="line">
              {/* uncomment modal and rootReducer and create modal tsx */}
              <PremiumBenefits
                title={"Subscription Benefits"}
                price={59.99}
                period={"year"}
                savings={17}
              ></PremiumBenefits>
            </div>
            <div className="line">
              {/* uncomment modal and rootReducer and create modal tsx */}
              <SubscriptionCard
                title={"Monthly"}
                price={5.99}
                period={"month"}
                savings={null}
              ></SubscriptionCard>
              <SubscriptionCard
                title={"Seasonal"}
                price={15.99}
                period={"3 months"}
                savings={11}
              ></SubscriptionCard>
              <SubscriptionCard
                title={"Yearly"}
                price={59.99}
                period={"year"}
                savings={17}
              ></SubscriptionCard>
            </div>
          </StyledTile>
          <StyledTile title="CourtHoops Coins" button={false} width="100%" className="top-tile">
            <div className="line">
              {/* uncomment modal and rootReducer and create modal tsx */}
              <CoinsBenefits
                title={"Uses for CourtHoops Coins"}
                price={59.99}
                period={"year"}
                savings={17}
              ></CoinsBenefits>
            </div>
            <div className="line">
              {/* uncomment modal and rootReducer and create modal tsx */}
              <CoinsCard title={"1500"} price={9.99} savings={null}></CoinsCard>
              <CoinsCard title={"4500"} price={24.99} savings={17}></CoinsCard>
              <CoinsCard title={"10000"} price={49.99} savings={25}></CoinsCard>
            </div>
          </StyledTile>
        </div>
      </div>
    </Container>
  );
};

export default page("premium")(PremiumPage);

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.darkGrayBackground};
  flex-direction: column;
  overflow: hidden;
  background: url("../lib/images/premiumCover.jpeg");

  .main {
    // transform: translateY(-880px);
    transform: translateY(-980px);
    width: 90%;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
  }

  .top-tile {
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    display: flex;
  }

  .image {
    width: 100%;
    height: 1750px;
    overflow: hidden;

    @media ${breakpoint.mobile} {
      height: 3700px;
    }

    @media ${breakpoint.tablet} {
      height: 3200px;
    }
  }

  img {
    min-width: 100%;
    opacity: 0.1;
  }

  .background-img {
    min-width: 100%;
    opacity: 0.2;
    overflow: hidden;
  }

  .line {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-left: 0;
    margin-right: 0;

    @media ${breakpoint.mobileTablet} {
      flex-direction: column;
    }
  }

  .local-button {
    height: 52px;
    align-self: flex-end;
    margin: 0 0 30px 0;
    border-radius: 3px;
    background-color: ${(props) => props.theme.orangeTheme};
    width: 100%;

    font-family: LemonMilk;
    font-size: 12px;
    letter-spacing: 0.6px;
    color: ${(props) => props.theme.whiteFont};
    padding: 0;
    border: 0;
    outline: 0;
    cursor: pointer;

    @media ${breakpoint.mobileTablet} {
      margin: 0;
    }
  }

  .energy {
    margin: 0;
    display: inline;
    font-family: LemonMilklight;
  }
`;

const StyledTile = styled(Tile)`
  max-width: 1350px;
  height: 100%;
  width: 90%;
  transform: translateY(-30px);
`;

import React from "react";
import { ListItemText } from "@material-ui/core";
import styled from "styled-components";

const CustomListItemPremium: React.FC<React.ComponentProps<typeof ListItemText>> = ({
  children,
  ...other
}) => {
  return (
    <StyledListItemPremium classes={{ primary: "primary" }} {...other}>
      {children}
    </StyledListItemPremium>
  );
};

const StyledListItemPremium = styled(ListItemText)`
  & .primary {
    font-size: 12px;
    font-family: "LemonMilk";
    letter-spacing: 1.5px;
    text-align: center;

    background: linear-gradient(
      45deg,
      #ff2400,
      #e81d1d,
      #e8b71d,
      #e3e81d,
      #1de840,
      #1ddde8,
      #2b1de8,
      #dd00f3,
      #dd00f3
    );
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    animation: rainbow 6s ease infinite;
    background-size: 400% 400%;
  }

  @keyframes rainbow {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 25%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
`;

export default CustomListItemPremium;

export function checkGoat(teamId: number | string): boolean {
  if (
    teamId === 468 ||
    teamId === 503 ||
    teamId === 510 ||
    teamId === 508 ||
    teamId === 1249 ||
    teamId === 498 ||
    teamId === 1286
  )
    return true;
  else if (
    teamId === "LV Knights" ||
    teamId === "ExplosiveBubbleWrap" ||
    teamId === "Still Team ID 1" ||
    teamId === "Texas Elite" ||
    teamId === "Kapolei Tiger Sharks" ||
    teamId === "Utopia" ||
    teamId === "Savlje BC"
  )
    return true;
  else return false;
}

import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface State {
  isOpen: boolean;
  playerId: number;
}

export const modal = (name: string) =>
  createSlice({
    name,
    initialState: {
      isOpen: false,
      playerId: 0,
    } as State,
    reducers: {
      open: (state) => {
        state.isOpen = true;
      },
      close: (state) => {
        state.isOpen = false;
      },
      toggle: (state) => {
        state.isOpen = !state.isOpen;
      },
      setPlayerId: {
        reducer: (state: State, action: PayloadAction<number>) => {
          state.playerId = action.payload;
        },
        prepare: (value?: number) => ({ payload: value || 0 }),
      },
    },
  });

export const gymAfterHoursModal = modal("gymAfterHoursModal");
// export const extraTeamScrimmageModal = modal("extraTeamScrimmageModal");
export const bulkUpModal = modal("bulkUpModal");
export const cuttingSeasonModal = modal("cuttingSeasonModal");
// export const localFoodBankModal = modal("localFoodBankModal");
export const merchandiseStandModal = modal("merchandiseStandModal");
export const retrieveEnergyModal = modal("retrieveEnergyModal");
export const teamDinnerModal = modal("teamDinnerModal");
export const nameChangeModal = modal("nameChangeModal");
export const teamNameChangeModal = modal("teamNameChangeModal");
export const transferLoginModal = modal("transferLoginModal");
export const scrimmageSendModal = modal("scrimmageSendModal");
export const messageSendModal = modal("messageSendModal");
export const fireModal = modal("fireModal");
export const listModal = modal("listModal");
export const bidModal = modal("bidModal");

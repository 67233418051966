import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LogInResponse } from "../../api/logIn";
import { MyKnownError } from "../myKnownError";
import { defaultState } from "../util/defaultState";

const submitLogInSlice = createSlice({
  name: "submitlogIn",
  initialState: defaultState<LogInResponse>(),
  reducers: {
    submitLogInLoading: (state) => {
      state.fetchStatus = "pending";
      return state;
    },
    submitLogInSuccess: (state, action: PayloadAction<LogInResponse>) => {
      state.fetchStatus = "success";
      state.data = action.payload;
      return state;
    },
    submitLogInFailed: (state, action: PayloadAction<MyKnownError>) => {
      state.fetchStatus = "failed";
      state.error = action.payload;
      return state;
    },
    submitLogInReset: (state) => {
      // state = defaultState();
      state.fetchStatus = "notStarted";
      state.error = undefined;
      state.data = undefined;
      return state;
    },
  },
});

export const {
  submitLogInLoading,
  submitLogInSuccess,
  submitLogInFailed,
  submitLogInReset,
} = submitLogInSlice.actions;
export default submitLogInSlice.reducer;
